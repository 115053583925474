import { useAuthServices } from "@/composables/useAuthServices";
import { REQ_RES_CODES } from "@/shared/http/enums/request-response-codes.enum";
import { REFRESH_SESSION_URL } from "@/store/auth/consts/refresh-session-url.const";
import store from "@/store/index";
import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { jwtDecode } from "jwt-decode";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SMART_TALENT_CORE_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config: InternalAxiosRequestConfig) {
    config.headers.set("Access-Control-Allow-Origin", "*");
    const {
      auth: {
        value: { token, refreshToken },
      },
    } = useAuthServices();
    if (config.url !== REFRESH_SESSION_URL && token) {
      try {
        const decodedToken = jwtDecode(token);

        const currentTime = Math.floor(Date.now() / 1000);
        const expirationTime = decodedToken.exp;
        const timeLeft = expirationTime - currentTime;
        if (timeLeft <= 3580 && timeLeft > 0) {
          await useAuthServices().refreshSession({ refreshToken });
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (token && !config.headers.getAuthorization()) {
      config.headers.set("authorization", `Bearer ${token}`);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response: AxiosResponse) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (
      error.code === 401 ||
      error.response.data.messageCode === REQ_RES_CODES.ACCESS_EXPIRED
    ) {
      store.dispatch("auth/launchExpiredSession");
    }
    return Promise.reject({ ...error, response: error.response.data });
  }
);

export default axiosInstance;
