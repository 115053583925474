import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row mt-3" }
const _hoisted_2 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_3 = { class: "m-0 fs-5" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col-auto p-0" }
const _hoisted_6 = { class: "col-auto pl-0" }
const _hoisted_7 = { class: "col-12 d-flex justify-content-center" }

import { merge } from "lodash";
import {
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { CreateOrUpdateRawMaterialQuantityTemplate } from "./templates/forms/CreateOrUpdateRawMaterialQuantity.template";
type RawMaterialQuantity = {
  rawMaterial: { id: number; label?: string };
  quantity: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateRawMaterialQuantity',
  props: {
  rawMaterials: {
    type: Object as PropType<RawMaterialQuantity[]>,
    required: false,
  },
},
  setup(__props, { expose: __expose }) {

const { t } = useI18n();

const props = __props;
const rawMaterials: Ref<RawMaterialQuantity[]> = toRef(props, "rawMaterials");
let currentRawMaterialIndex = -1;
const modal = ref();
const modalTitle = ref();
const rawMaterialForm = ref();
const rawMaterialFormTemplateRef = ref();
const addRawMaterialButtonTemplate = ref(
  addButton(async () => {
    currentRawMaterialIndex = rawMaterials.value.length;
    await handleAddOrEditRawMaterial(currentRawMaterialIndex);
  })
);

async function handleSubmitRawMaterial() {
  const { values } = rawMaterialForm.value;
  rawMaterials.value[currentRawMaterialIndex] = merge(
    {},
    rawMaterials.value[currentRawMaterialIndex] ?? {},
    values
  );
  modal.value.closeModal();
}

async function handleAddOrEditRawMaterial(index: number) {
  const isCreating = rawMaterials.value.length <= index;
  rawMaterialForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("technicalSheets.form.rawMaterial.add")
    : t("technicalSheets.form.rawMaterial.update");
  rawMaterialFormTemplateRef.value = CreateOrUpdateRawMaterialQuantityTemplate(
    handleSubmitRawMaterial,
    isCreating
  );
  if (!isCreating) {
    await nextTick();
    rawMaterialForm.value.setValues(rawMaterials.value[index]);
  }
  modal.value.openModal();
  currentRawMaterialIndex = index;
}

function resetForms() {
  rawMaterials.value = [];
  rawMaterialForm.value?.resetForm();
  modal.value.closeModal();
}

__expose({ resetForms });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-1" }, [
        _createElementVNode("hr")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("b", _hoisted_3, _toDisplayString(_unref(t)("technicalSheets.entity.rawMaterial")), 1)
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col pl-1" }, [
        _createElementVNode("hr")
      ], -1))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rawMaterials.value, (rawMaterial, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "row mt-3",
        key: `rawMaterial${index}`
      }, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(rawMaterial.rawMaterial.label), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(ButtonField, {
            "button-field": 
          _unref(editButton)(() => {
            handleAddOrEditRawMaterial(index);
          })
        
          }, null, 8, ["button-field"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(ButtonField, {
            "button-field": 
          _unref(deleteButton)(() => {
            rawMaterials.value.splice(index, 1);
          })
        
          }, null, 8, ["button-field"])
        ])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(ButtonField, { buttonField: addRawMaterialButtonTemplate.value }, null, 8, ["buttonField"])
    ]),
    _createVNode(GeneralModal, {
      ref_key: "modal",
      ref: modal,
      modalId: "translateModal",
      modalName: modalTitle.value,
      "data-bs-backdrop": "false",
      class: "p-4"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "rawMaterialForm",
          ref: rawMaterialForm,
          form: rawMaterialFormTemplateRef.value,
          "form-name": "translateForm",
          key: modalTitle.value
        }, null, 8, ["form"]))
      ]),
      _: 1
    }, 8, ["modalName"])
  ], 64))
}
}

})