import axiosInstance from "@/middlewares/Routing.middleware";
import { Position } from "../models/Position.model";

export async function GetAllPositions(): Promise<Position[]> {
  return (await axiosInstance.get(`positions/all`)).data;
}

export async function CreatePosition(payload: Position): Promise<Position> {
  return (await axiosInstance.post(`positions`, payload)).data;
}

export async function UpdatePosition(payload: Position): Promise<Position> {
  const { id, ...positionProps } = payload;
  return (await axiosInstance.put(`positions/${id}`, positionProps)).data;
}

export async function DeletePosition(id: number): Promise<void> {
  await axiosInstance.delete(`positions/${id}`);
}
