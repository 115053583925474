<template>
  <span>{{ formatedText }}</span>
</template>

<script lang="ts" setup>
import { computed, defineProps } from "vue";
import {
  formatNumber,
  formatNumberCurrency,
} from "@/shared/globals/helpers/Number.helper";
import { formatDate } from "@/shared/globals/helpers/Date.helper";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  formatType: {
    type: String,
    required: true,
  },
});

const formatedText = computed(() => {
  if (props.formatType === "number") {
    return formatNumber(+props.value);
  } else if (props.formatType === "currency") {
    return formatNumberCurrency(+props.value);
  } else if (props.formatType === "date") {
    return formatDate(new Date(props.value));
  } else if (props.formatType === "percentage") {
    return `${props.value}%`;
  }
  return props.value;
});
</script>

<style lang="scss"></style>
