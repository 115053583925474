import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "dropdown-menu dropdown-menu-end",
  "aria-labelledby": "dropdownMenuLink"
}
const _hoisted_2 = { class: "mx-2" }

import { PropType, useTemplateRef } from "vue";
import { Dropdown } from "bootstrap";
import { DropDownElementSubmenu } from "@/shared/globals/tooltips/types/DropDownElementsSubmenu";
import NotificationItem from "./NotificationItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsDropdownMenu',
  setup(__props) {

const dropdownName = crypto.randomUUID();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "nav-item dropdown",
    ref: _unref(dropdownName)
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("a", {
      href: "#",
      role: "button",
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false"
    }, [
      _createElementVNode("i", { class: "bi bi-bell fs-3" })
    ], -1)),
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(NotificationItem)
      ])
    ])
  ], 512))
}
}

})