import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-12" }

import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useTechnicalSheetsServices } from "@/composables/useTechnicalSheetsService";
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { TechnicalSheet } from "@/store/technical-sheets/models/TechnicalSheet.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { merge } from "lodash";
import {
  computed,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import CreateOrUpdatePreManufacturedProductQuantity from "./CreateOrUpdatePreManufacturedProductQuantity.vue";
import CreateOrUpdateRawMaterialQuantity from "./CreateOrUpdateRawMaterialQuantity.vue";
import { CreateOrUpdateTechnicalSheetTemplate } from "./templates/forms/CreateOrUpdateTechnicalSheet.template";

type RawMaterialQuantity = {
  rawMaterial: { id: number; label?: string };
  quantity: number;
};

type PreManufacturedProduct = {
  preManufacturedProduct: { id: number; label?: string };
  quantity: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateTechnicalSheet',
  props: {
  initialValues: { type: Object as PropType<TechnicalSheet>, required: false },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createTechnicalSheet, updateTechnicalSheet } =
  useTechnicalSheetsServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const props = __props;

const initialValuesRef: Ref<TechnicalSheet> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const rawMaterials: Ref<RawMaterialQuantity[]> = ref([]);
const preManufacturedProducts: Ref<PreManufacturedProduct[]> = ref([]);

const emit = __emit;

const currentTechnicalSheet: Ref<Partial<TechnicalSheet> | null> = computed(
  () => {
    if (initialValuesRef.value) {
      const technicalSheet = formatValues(initialValuesRef.value);

      return technicalSheet ?? null;
    }
    return null;
  }
);

function callBackCreate(technicalSheet: TechnicalSheet) {
  emit("handleSubmit", { technicalSheet, isCreating: true });
}

function callBackEdit(technicalSheet: TechnicalSheet) {
  emit("handleSubmit", {
    technicalSheet,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { values } = form.value;
  const { technicalSheet } = merge({}, values, rawMaterials.value);
  const technicalSheetFormatted = {
    ...technicalSheet,
  };
  technicalSheetFormatted.rawMaterials = rawMaterials.value.map(
    (rawMaterial) => ({
      id: rawMaterial.rawMaterial.id,
      quantity: rawMaterial.quantity,
    })
  );
  if (!initialValuesRef.value) {
    await createTechnicalSheet(technicalSheetFormatted, callBackCreate);
  } else {
    await updateTechnicalSheet(technicalSheetFormatted, callBackEdit);
  }
}

function formatValues(values: TechnicalSheet) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    rawMaterial: values.rawMaterials,
    preManufactured: values.preManufacturedProducts,
    customFields: customFieldsFiltered,
  };
}

function resetForms() {
  form.value?.resetForm();
}

onMounted(async () => {
  await Promise.all([getAllCatalogs()]);
  watch(
    [currentTechnicalSheet, catalogs, customFields],
    async () => {
      if (customFields.value && catalogs.value) {
        formTemplateRef.value = CreateOrUpdateTechnicalSheetTemplate(
          customFields.value,
          handleSubmit,
          !currentTechnicalSheet.value
        );
        if (currentTechnicalSheet.value) {
          await nextTick();
          form.value?.setValues({
            technicalSheet: currentTechnicalSheet.value,
          });
          rawMaterials.value =
            initialValuesRef.value?.rawMaterials?.map((rawMaterial) => {
              return {
                rawMaterial: {
                  id: rawMaterial.rawMaterial.id,
                  label: translateFromKey(rawMaterial.rawMaterial, "name"),
                },
                quantity: rawMaterial.quantity,
              };
            }) ?? [];
        }
      }
    },
    { deep: true, immediate: true }
  );
});

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (formTemplateRef.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(GeneralForm, {
              ref_key: "form",
              ref: form,
              form: formTemplateRef.value,
              key: JSON.stringify(props.initialValues),
              "form-name": "technicalSheetForm"
            }, {
              default: _withCtx(() => [
                _createVNode(CreateOrUpdateRawMaterialQuantity, { rawMaterials: rawMaterials.value }, null, 8, ["rawMaterials"]),
                _createVNode(CreateOrUpdatePreManufacturedProductQuantity, { "pre-manufactured-products": preManufacturedProducts.value }, null, 8, ["pre-manufactured-products"])
              ]),
              _: 1
            }, 8, ["form"]))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})