import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";

export const confirmButton = (action): ButtonTemplate => {
  return {
    backgroundColor: "#dc3545",
    text: { value: "global.buttons.confirm", needsTranslate: true },
    customClass: "btn w-100",
    action,
    metadata: {
      requireSpinner: true,
    },
  };
};
