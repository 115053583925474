import { useLanguageServices } from "@/composables/useLanguageServices";
import { Language } from "@/shared/locales/types/Language.type";
import { get } from "lodash";

interface ObjMetadata {
  language: {
    name?: Language;
    description?: Language;
  };
}

interface GenericObject {
  metadata: ObjMetadata;
  [key: string]: any;
}

export function translateFromKey(obj: GenericObject, key: string) {
  const { lang } = useLanguageServices();
  return get(obj, `metadata.language.${key}.${lang.value}`, obj[key]);
}
