<template>
  <div class="dropdown">
    <button
      class="btn dropdown-toggle textPale"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="bi bi-three-dots"></i>
      <span class="caret"></span>
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <li
        v-for="(option, index) in props.menuData.options"
        :key="`optionMenu-${index}-${name}`"
      >
        <a @click="option.action" class="dropdown-item" href="#">{{
          option.label.needsTranslate
            ? $t(option.label.value)
            : option.label.value
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { defineProps, PropType } from "vue";

const props = defineProps({
  menuData: {
    type: Object as PropType<Menu>,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});
</script>

<style scoped lang="scss">
.dropdown-toggle {
  &::after {
    display: none !important;
  }
}
</style>
