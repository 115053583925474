<template>
  <div class="container-fluid">
    <div class="row" v-if="formTemplateRef">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="orderForm"
        >
          <div class="row mt-3">
            <div class="col-1"><hr /></div>
            <div class="col-auto p-0 dividerText d-flex align-items-center">
              <b class="m-0 fs-5"> {{ t("orderDetail.title") }} </b>
            </div>
            <div class="col pl-1"><hr /></div>
          </div>
          <div
            class="row mt-3"
            v-for="(detail, index) in details"
            :key="`detail${index}`"
          >
            <div class="col">
              {{ detail?.detail?.product?.label }}
            </div>
            <div class="col-auto p-0">
              <ButtonField
                :button-field="
                  editButton(() => {
                    handleAddOrEditDetail(index);
                  })
                "
              ></ButtonField>
            </div>
            <div class="col-auto pl-0">
              <ButtonField
                :button-field="
                  deleteButton(() => {
                    details.splice(index, 1);
                  })
                "
              ></ButtonField>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <ButtonField :buttonField="addButtonTemplate"></ButtonField>
          </div>
        </GeneralForm>
      </div>
      <div class="col-12">
        <GeneralModal
          ref="modal"
          modalId="translateModal"
          :modalName="modalTitle"
          data-bs-backdrop="false"
          class="p-4"
        >
          <GeneralForm
            ref="detailForm"
            :form="detailFormTemplateRef"
            form-name="translateForm"
            :key="modalTitle"
          ></GeneralForm>
        </GeneralModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useOrdersServices } from "@/composables/useOrdersService";
import { DateHelper } from "@/shared/dates/helpers/Date.helper";
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { Order } from "@/store/orders/models/Order.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { merge } from "lodash";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { CreateOrUpdateDetailTemplate } from "./templates/forms/CreateOrUpdateDetail.template";
import { CreateOrUpdateOrderTemplate } from "./templates/forms/CreateOrUpdateOrderTemplate.template";

const { customFields } = useCustomFieldsServices();
const { createOrder, updateOrder } = useOrdersServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const { t } = useI18n();

const props = defineProps({
  initialValues: { type: Object as PropType<Order>, required: false },
});

let currentDetailIndex = -1;

const initialValuesRef: Ref<Order> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle = ref();
const detailForm = ref();
const detailFormTemplateRef = ref();
const details: Ref<
  Array<{ detail: { product: { id: number; label?: string } } }>
> = ref([]);
const addButtonTemplate = ref(
  addButton(async () => {
    currentDetailIndex = details.value.length;
    await handleAddOrEditDetail(currentDetailIndex);
  })
);
const emit = defineEmits(["handleSubmit"]);

const currentOrder: Ref<Partial<Order> | null> = computed(() => {
  if (initialValuesRef.value) {
    const order = formatValues(initialValuesRef.value);

    return order ?? null;
  }
  return null;
});

async function handleSubmitDetail() {
  const { values } = detailForm.value;
  details.value[currentDetailIndex] = merge(
    {},
    details.value[currentDetailIndex] ?? {},
    values
  );
  modal.value.closeModal();
}

async function handleAddOrEditDetail(detailIndex: number) {
  const isCreating = details.value.length <= detailIndex;
  detailForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("orderDetail.form.createTitle")
    : t("orderDetail.form.editTitle");
  detailFormTemplateRef.value = CreateOrUpdateDetailTemplate(
    handleSubmitDetail,
    isCreating
  );
  if (!isCreating) {
    await nextTick();
    detailForm.value.setValues(details.value[detailIndex]);
  }
  modal.value.openModal();
  currentDetailIndex = detailIndex;
}

function callBackCreate(order: Order) {
  emit("handleSubmit", { order, isCreating: true });
}

function callBackEdit(order: Order) {
  emit("handleSubmit", {
    order,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { values } = form.value;
  const { order } = merge({}, values, details.value);
  const detail = details.value.map((detail) => ({
    ...detail.detail,
    product: { id: detail.detail.product.id },
  }));
  if (!initialValuesRef.value) {
    await createOrder(
      {
        ...order,
        status: { id: order.status.id },
        customer: { id: order.customer.id },
        details: detail,
      },
      callBackCreate
    );
  } else {
    await updateOrder({ ...order, details: detail }, callBackEdit);
  }
}

function formatValues(values: Order) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    orderDate: DateHelper.of().format(values.orderDate, "yyyy-MM-dd"),
    customer: values.customer,
    status: values.status,
    metadata: values.metadata,
    customFields: customFieldsFiltered,
  };
}

function resetForms() {
  form.value?.resetForm();
  detailForm.value?.resetForm();
  details.value = [];
  modal.value.closeModal();
}

onMounted(async () => {
  await Promise.all([getAllCatalogs()]);
  watch(
    [currentOrder, catalogs, customFields],
    async () => {
      if (customFields.value && catalogs.value) {
        formTemplateRef.value = CreateOrUpdateOrderTemplate(
          customFields.value,
          handleSubmit,
          !currentOrder.value,
          catalogs.value
        );
        if (currentOrder.value) {
          await nextTick();
          form.value?.setValues({
            order: currentOrder.value,
          });
          details.value =
            initialValuesRef.value?.details?.map((detail) => {
              return {
                detail: {
                  ...detail.data,
                  product: detail?.product
                    ? {
                        id: detail?.product.id,
                        label: translateFromKey(detail?.product, "name"),
                      }
                    : {},
                },
              };
            }) ?? [];
        }
      }
    },
    { deep: true, immediate: true }
  );
});

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
