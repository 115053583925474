<template>
  <div
    v-if="props.options"
    class="p-0 dropdownContainer h-100 d-flex align-self-start"
  >
    <button
      class="btn dropdown-toggle textPale"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="bi bi-three-dots"></i>
    </button>
    <ul class="dropdown-menu">
      <li
        v-for="option of props.options"
        :key="option.name"
        @click="option.callBack"
      >
        <a class="dropdown-item" href="#">{{ option.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { CardOption } from "@/shared/globals/cards/types/CardOption.type";
import { defineProps, PropType } from "vue";

const props = defineProps({
  options: { type: Array as PropType<CardOption[]>, required: true },
});
</script>

<style scoped lang="scss">
.dropdown-toggle {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: start;
  padding: 0;
  font-size: 1.2em;
  i {
    height: 16px;
  }
  &::after {
    display: none !important;
  }
}

.dropdown-item:hover {
  background-color: #ebedf2;
}

.dropdownContainer {
  width: 0;
  position: relative;
  left: -1em;
}
</style>
