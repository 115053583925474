import { translateButton } from "@/components/globals/buttons/templates/TranslateButton.template";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import {
  minCharsYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateProductTemplate = (
  customFields: CustomFieldInterface[],
  action,
  isCreating: boolean,
  handleTranslateButton: (
    title: LabelType,
    path: string,
    type: "text" | "textarea"
  ) => void
): Form => {
  const { lang } = useLanguageServices();

  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "product.name",
            inputName: "productName",
            label: {
              value: "products.entity.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(5)];
            },
            buttons: [
              translateButton(() =>
                handleTranslateButton(
                  { value: "products.entity.name", needsTranslate: true },
                  "product.metadata.language.name",
                  "text"
                )
              ),
            ],
          },
          {
            path: "product.description",
            inputName: "productDescription",
            label: {
              value: "products.entity.description",
              needsTranslate: true,
            },
            type: "textarea",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
            buttons: [
              translateButton(() =>
                handleTranslateButton(
                  {
                    value: "products.entity.description",
                    needsTranslate: true,
                  },
                  "product.metadata.language.description",
                  "text"
                )
              ),
            ],
          },
          {
            path: "product.measure",
            inputName: "productMeasure",
            label: {
              value: "products.entity.measure",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "left",
            },
            selectOptions: {
              dataSource: "/measures/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "product.productFamily",
            inputName: "productFamilyProduct",
            label: {
              value: "products.entity.productFamily",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "left",
            },
            selectOptions: {
              dataSource: "/product-families/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "products.form.add" : "products.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "product", "product"),
    });
  }

  return form;
};
