import { DeletionProfileInterface } from "@/components/profiles/interfaces/deletion-profile.interface";
import axiosInstance from "@/middlewares/Routing.middleware";
import { Profile } from "../models/Profile.model";

export async function GetProfiles(): Promise<Profile[]> {
  return (await axiosInstance.get("/profiles")).data;
}

export async function GetAllProfiles(): Promise<Partial<Profile>[]> {
  return (await axiosInstance.get("/profiles/all")).data;
}

export async function CreateProfile(payload: Profile): Promise<Profile> {
  return (await axiosInstance.post(`profiles`, payload)).data;
}

export async function UpdateProfile(payload: Profile): Promise<Profile> {
  return (await axiosInstance.put(`profiles`, payload)).data;
}

export async function DeleteProfile(
  payload: DeletionProfileInterface
): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`profiles?newId=${newId}&oldId=${oldId}`);
}
