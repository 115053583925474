<template>
  <div class="row">
    <div class="col-auto">
      <i class="bi bi-plus-lg icon-style"></i>
    </div>
    <div class="col text-start">
      <p class="m-0 p-0">{{ props.name }}</p>
      <p class="m-0 p-0 date">
        <TextFormatter
          :value="String(props.date)"
          formatType="date"
        ></TextFormatter>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, PropType } from "vue";
import TextFormatter from "../globals/UiTools/TextFormatter.vue";
const props = defineProps({
  name: { type: String, required: true },
  date: { type: Date, required: true },
});
</script>

<style>
.icon-style {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.5rem;
  background-color: #8361cb;
  color: white;
  border-radius: 50%;
}

.date {
  color: #b99ca8;
}
</style>
