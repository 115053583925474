import axiosInstance from "@/middlewares/Routing.middleware";
import { TechnicalSheet } from "../models/TechnicalSheet.model";

export async function CreateTechnicalSheet(
  payload: TechnicalSheet
): Promise<TechnicalSheet> {
  return (await axiosInstance.post(`technicalSheets`, payload)).data;
}

export async function UpdateTechnicalSheet(
  payload: TechnicalSheet
): Promise<TechnicalSheet> {
  const { id, ...technicalSheetProps } = payload;
  return (await axiosInstance.put(`technicalSheets/${id}`, technicalSheetProps))
    .data;
}
