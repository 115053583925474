import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useSuppliersServices } from "@/composables/useSuppliersService";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { Supplier } from "@/store/suppliers/models/Supplier.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import {
  computed,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateSupplierTemplate } from "./templates/forms/CreateOrUpdateSupplierTemplate.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateSupplier',
  props: {
  initialValues: { type: Object as PropType<Supplier>, required: false },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createSupplier, updateSupplier } = useSuppliersServices();

const props = __props;

const initialValuesRef: Ref<Supplier> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();

const emit = __emit;

const currentSupplier = computed(() => {
  if (initialValuesRef.value) {
    const supplier = formatValues(initialValuesRef.value);
    return supplier ?? null;
  }
  return null;
});

function callBackCreate(supplier: Supplier) {
  emit("handleSubmit", { supplier, isCreating: true });
}

function callBackEdit(supplier: Supplier) {
  emit("handleSubmit", {
    supplier,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { supplier } = form.value.values;
  if (!initialValuesRef.value) {
    await createSupplier(supplier, callBackCreate);
  } else {
    await updateSupplier(supplier, callBackEdit);
  }
}

function formatValues(values: Supplier) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    name: values.name,
    supplierType: values.supplierType,
    businessType: values.businessType,
    state: values.state,
    city: values.city,
    customFields: customFieldsFiltered,
  };
}

watch(
  [customFields, currentSupplier],
  async () => {
    formTemplateRef.value = CreateOrUpdateSupplierTemplate(
      customFields.value,
      handleSubmit,
      !currentSupplier.value
    );
    if (currentSupplier.value) {
      await nextTick();
      form.value?.setValues({ supplier: currentSupplier.value });
    }
  },
  { deep: true, immediate: true }
);

__expose({
  resetForms: () => form.value?.resetAllFields(),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues),
          "form-name": "supplierForm"
        }, null, 8, ["form"]))
      ])
    ])
  ]))
}
}

})