import { REQ_RES_CODES } from "@/shared/http/enums/request-response-codes.enum";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import {
  CreateCustomField,
  DeleteCustomField,
  UpdateCustomField,
} from "@/store/customFields/services/CustomField.service";
import { GetCustomFields } from "@/store/modules/services/Module.service";
import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "./useToastService";

export function useCustomFieldsServices() {
  const { t } = useI18n();
  const store = useStore();
  const route = useRoute();
  const moduleName: string = route.meta.moduleName as string;

  const customFields: Ref<CustomFieldInterface[]> = computed(() => {
    return store?.state?.modules?.[moduleName]?.customFields ?? [];
  });

  const createCustomField = async ({
    customField,
    callBack,
  }: {
    customField: CustomFieldInterface;
    callBack: () => void;
  }): Promise<void> => {
    await CreateCustomField(moduleName, customField)
      .then(({ data }) => {
        store.commit("modules/addCustomField", {
          moduleName,
          customField: { ...data, ...customField },
        });
        callBack();
        useToast().successToast(t("customFields.sucess.customFieldCreated"));
      })
      .catch(({ response }) => {
        if (response.messageCode === REQ_RES_CODES.ENTITY_ALREADY_EXIST) {
          useToast().errorToast(t("customFields.errors.fieldAlreadyExists"));
        } else {
          useToast().errorToast(t("global.unknownError"));
        }
      });
  };

  const updateCustomField = async ({
    customField,
    callBack,
  }: {
    customField: CustomFieldInterface;
    callBack: () => void;
  }): Promise<void> => {
    await UpdateCustomField(moduleName, customField)
      .then(() => {
        store.commit("modules/updateCustomField", {
          moduleName,
          customField,
        });
        callBack();
        useToast().successToast(t("customFields.sucess.customFieldUpdated"));
      })
      .catch(({ response }) => {
        if (response?.messageCode === REQ_RES_CODES.ENTITY_ALREADY_EXIST) {
          useToast().errorToast("customFields.errors.fieldAlreadyExists");
        } else {
          useToast().errorToast(t("global.unknownError"));
        }
      });
  };

  const deleteCustomField = async (id: string): Promise<void> => {
    await DeleteCustomField(id)
      .then(() => {
        store.commit("modules/deleteCustomField", { id, moduleName });
        useToast().successToast(t("customFields.sucess.customFieldDeleted"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const getModuleCustomFields = async (): Promise<void> => {
    const moduleCustomFields = await GetCustomFields(moduleName);
    store.commit("modules/setCustomFields", moduleCustomFields);
  };

  return {
    createCustomField,
    updateCustomField,
    deleteCustomField,
    getModuleCustomFields,
    customFields,
  };
}
