import { Form } from "@/shared/globals/forms/interfaces/Form.interface";

export const validateEmailStep2 = (action, validations): Form => {
  return {
    sections: [
      {
        fields: [
          {
            path: "email",
            inputName: "validateEmail",
            label: {
              value: "auth.fields.email",
              needsTranslate: true,
            },
            type: "email",
            methodsYup: () => {
              const { emailValidation } = validations;
              return emailValidation;
            },
          },
          {
            path: "confirmationCode",
            inputName: "confirmationCode",
            label: {
              value: "auth.fields.confirmationCode",
              needsTranslate: true,
            },
            type: "text",
            methodsYup: () => {
              const { cofirmationCodeValidation } = validations;
              return cofirmationCodeValidation;
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: "auth.carouselTabs.validateEmail.submit2",
        needsTranslate: true,
      },
      action,
    },
  };
};
