import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { TooltipPosition } from "@/shared/globals/inputs/types/TooltipPosition.type";
import { t } from "@/shared/locales/services/i18n.services";
import _ from "lodash";
import { formatNumberCurrency } from "../../helpers/Number.helper";
import { TableHeader } from "../interfaces/TableHeader.interface";
import { Row } from "../types/Row.type";

// eslint-disable-next-line
export function mapFromHeader(
  headers: TableHeader[],
  data: any[],
  lang: string
): Row[] {
  return data.map((tableRows: Row) => {
    let defaultTableRows = tableRows;
    if (tableRows?.customFields) {
      const { customFields, ...tempTableRows } = tableRows;
      defaultTableRows = {
        ...tempTableRows,
        ...(customFields as Row),
      };
    }
    const row: Row = {};
    headers.forEach((header) => {
      switch (header.columnType) {
        case "tag": {
          let tagsOrtag = _.get(defaultTableRows, header.mainKey, []);
          if (!Array.isArray(tagsOrtag)) {
            tagsOrtag = [tagsOrtag];
          }
          const tags = tagsOrtag.map((tag) => {
            let newValue;
            if (Array.isArray(header.mappedKey)) {
              for (const key of header.mappedKey) {
                const value = _.get(tag, key, false);
                if (value) {
                  newValue = value;
                  break;
                }
              }
            } else {
              newValue = _.get(defaultTableRows, header.mappedKey, false);
            }
            return {
              value: newValue,
              color: "white",
              bgColor: "#7451c2",
            };
          });
          row[header.key] = tags;
          break;
        }
        case "currency":
          row[header.key] = +_.get(defaultTableRows, header.mappedKey, 0);
          break;
        case "date":
          row[header.key] = new Date(_.get(defaultTableRows, header.mappedKey));
          break;
        case "number": {
          row[header.key] = _.get(defaultTableRows, header.mappedKey, "-");
          if (
            !(
              row[header.key] === null ||
              row[header.key] === undefined ||
              row[header.key] === ""
            ) &&
            !isNaN(+row[header.key])
          ) {
            if (
              header?.metadata?.customFieldMetadata.fieldSettings.isCurrency
            ) {
              row[header.key] = formatNumberCurrency(+row[header.key]);
            }
          } else {
            row[header.key] = "-";
          }
          break;
        }
        case "status": {
          let newValue;
          if (Array.isArray(header.mappedKey)) {
            for (const key of header.mappedKey) {
              const value = _.get(defaultTableRows, key, false);
              if (value) {
                newValue = value;
                break;
              }
            }
          } else {
            newValue = _.get(defaultTableRows, header.mappedKey, false);
          }
          const status = _.get(defaultTableRows, header.mainKey, false);
          let style = {};
          if (status.name === "active") {
            style = { color: "#fff", bgColor: "#28a745" };
          } else if (status.name === "inactive") {
            style = { color: "#fff", bgColor: "#6c757d" };
          }
          row[header.key] = { value: newValue, ...style };
          break;
        }
        case "action":
          break;
        case "options": {
          const value = _.get(defaultTableRows, header.mappedKey, false);
          row[header.key] = "-";
          if (Array.isArray(value)) {
            const options =
              header?.metadata?.options?.filter((option) => {
                return value.find((v) => {
                  return v.id == option.value;
                });
              }) ?? [];
            if (options.length > 0) {
              row[header.key] = options.map((option) => {
                return option.name.needsTranslate
                  ? t(option.name.value, option.name.params)
                  : option.name.value;
              });
            }
          } else {
            const option = header?.metadata.options.find((option) => {
              return option.value == value;
            });
            if (option) {
              row[header.key] = option.name.needsTranslate
                ? t(option.name.value, option.name.params)
                : option.name.value;
            }
          }
          break;
        }
        case "select": {
          const { id } = _.get(defaultTableRows, header.mappedKey, false);
          row[header.key] = row[header.key] = [];
          if (id) {
            const option = header.selectOptions.initialOptions.find(
              (opt) => opt.id === id
            );
            if (option) {
              row[header.key] = [
                {
                  value: option.label,
                  color: "white",
                  bgColor: "#7451c2",
                },
              ];
            }
          }
          break;
        }
        case "multiple-select": {
          const values = _.get(defaultTableRows, header.mappedKey, []);
          row[header.key] = values
            .map(({ id }) => {
              const option = header.selectOptions.initialOptions.find(
                (opt) => opt.id === id
              );
              if (option) {
                return {
                  value: option.label,
                  color: "white",
                  bgColor: "#7451c2",
                };
              }
              return null;
            })
            .filter((opt) => opt);
          break;
        }
        default: {
          if (Array.isArray(header.mappedKey)) {
            for (const key of header.mappedKey) {
              const value = _.get(defaultTableRows, key, false);
              if (value) {
                row[header.key] = value;
                break;
              }
            }
          } else {
            row[header.key] = _.get(defaultTableRows, header.mappedKey, false);
          }
          row[header.key] = row[header.key] ? row[header.key] : "-";
          break;
        }
      }
    });
    return row;
  });
}

export function generateFilterField(header: TableHeader): Field {
  const tooltipPosition: TooltipPosition = "auto";
  return {
    path: `filters.${header.key}`,
    inputName: header.key,
    type: header?.filterType ?? "text",
    ...(header.selectOptions ? { selectOptions: header.selectOptions } : {}),
    metadata: {
      tooltipPosition,
    },
  };
}
