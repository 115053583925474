import { Module } from "vuex";
import { RawMaterial } from "./models/RawMaterial.model";

interface State {
  rawMaterials?: Partial<RawMaterial>[];
}

export const rawMaterialsStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    rawMaterials: [],
  },
  getters: {},
  mutations: {
    setRawMaterials(state, rawMaterials: Partial<RawMaterial>[]) {
      state.rawMaterials = rawMaterials;
    },
    addRawMaterial(state, rawMaterial: Partial<RawMaterial>) {
      state.rawMaterials = [...state.rawMaterials, rawMaterial];
    },
    updateRawMaterial(state, rawMaterial: Partial<RawMaterial>) {
      const index = state.rawMaterials.findIndex(
        (p) => p.id === rawMaterial.id
      );
      if (index !== -1) {
        state.rawMaterials[index] = {
          ...state.rawMaterials[index],
          ...rawMaterial,
        };
      } else {
        state.rawMaterials.push({ ...rawMaterial });
      }
    },
    deleteRawMaterial(state, rawMaterial: Partial<RawMaterial>) {
      state.rawMaterials = state.rawMaterials.filter(
        (p) => p.id !== rawMaterial.id
      );
    },
  },
  actions: {},
  modules: {},
};
