import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row fixedRow" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row flex-grow-1" }
const _hoisted_4 = { class: "col h-100" }
const _hoisted_5 = { class: "py-3 px-4 h-100" }

import HeaderViews from "@/components/navigationMenu/HeaderViews.vue";
import { useAuthServices } from "@/composables/useAuthServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { onBeforeMount, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

// class="flex-grow-1"
const { setDefaultLang } = useLanguageServices();
const { getUser } = useAuthServices();
const darkMode = ref(false);

function setDarkMode(event) {
  darkMode.value = event._value;
}

onBeforeMount(async () => {
  await getUser();
  setDefaultLang();
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`container-fluid ${
      darkMode.value == true ? 'darkMode' : ''
    } d-flex flex-column`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(HeaderViews)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_view, { class: "h-100" })
        ])
      ])
    ])
  ], 2))
}
}

})