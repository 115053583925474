<template>
  <div class="d-flex align-items-center justify-content-center">
    <h1>
      ¡Bienvenido <b>{{ user.name }}</b> a QP!
    </h1>
  </div>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";

const {
  auth: {
    value: { user },
  },
} = useAuthServices();
</script>

<style></style>
