import { TechnicalSheet } from "@/store/technical-sheets/models/TechnicalSheet.model";
import {
  CreateTechnicalSheet,
  UpdateTechnicalSheet,
} from "@/store/technical-sheets/services/TechnicalSheet.service";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastService";

export function useTechnicalSheetsServices() {
  const store = useStore();
  const { t } = useI18n();

  const createTechnicalSheet = async (
    payload: TechnicalSheet,
    callBackCreate: (technicalSheet: TechnicalSheet) => void
  ): Promise<void> => {
    await CreateTechnicalSheet(payload)
      .then((technicalSheet: TechnicalSheet) => {
        store.commit("technicalSheets/addTechnicalSheet", technicalSheet);
        callBackCreate(technicalSheet);
        useToast().successToast(t("technicalSheets.sucess.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const updateTechnicalSheet = async (
    payload: TechnicalSheet,
    callBackUpdate: (technicalSheet: TechnicalSheet) => void
  ): Promise<void> => {
    await UpdateTechnicalSheet(payload)
      .then((technicalSheet: TechnicalSheet) => {
        store.commit("technicalSheets/updateTechnicalSheet", payload);
        callBackUpdate(technicalSheet);
        useToast().successToast(t("technicalSheets.sucess.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  return {
    createTechnicalSheet,
    updateTechnicalSheet,
  };
}
