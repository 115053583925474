import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-auto pl-0 pr-1 my-auto" }
const _hoisted_3 = ["src", "width"]
const _hoisted_4 = { class: "col logo p-0 my-auto" }
const _hoisted_5 = { class: "m-0" }

import { computed } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'LogoPicture',
  props: {
  name: {
    type: String,
    required: false,
  },
  image: {
    type: String,
    required: false,
  },
  width: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;

//QUICK PALLET

const logoName = computed(() => {
  return props.name || "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (__props.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: __props.image,
            alt: "logoPic",
            width: props.width
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", _hoisted_5, _toDisplayString(logoName.value), 1)
    ])
  ]))
}
}

})