import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";

export const deleteButton = (action): ButtonTemplate => {
  return {
    backgroundColor: "#dc3545",
    customClass: "btn ml-1",
    graphic: {
      icon: {
        icon: "bi bi-trash",
        color: "white",
        backgroundColor: "#dc3545",
      },
    },
    action,
  };
};
