import { translateButton } from "@/components/globals/buttons/templates/TranslateButton.template";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import {
  minCharsYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateMeasureTemplate = (
  customFields: CustomFieldInterface[],
  action,
  isCreating: boolean,
  handleTranslateButton: (
    title: LabelType,
    path: string,
    type: "text" | "textarea"
  ) => void
): Form => {
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "measure.name",
            inputName: "measureName",
            label: {
              value: "measures.entity.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(5)];
            },
            buttons: [
              translateButton(() =>
                handleTranslateButton(
                  { value: "measures.entity.name", needsTranslate: true },
                  "measure.metadata.language.name",
                  "text"
                )
              ),
            ],
          },
          {
            path: "measure.description",
            inputName: "measureDescription",
            label: {
              value: "measures.entity.description",
              needsTranslate: true,
            },
            type: "textarea",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
            buttons: [
              translateButton(() =>
                handleTranslateButton(
                  {
                    value: "measures.entity.description",
                    needsTranslate: true,
                  },
                  "measure.metadata.language.description",
                  "textarea"
                )
              ),
            ],
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "measures.form.add" : "measures.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "measure", "measure"),
    });
  }

  return form;
};
