import { DeleteDivisionInterface } from "@/components/divisions/interfaces/delete-division.interface";
import { REQ_RES_CODES } from "@/shared/http/enums/request-response-codes.enum";
import { Division } from "@/store/divisions/models/Division.model";
import {
  CreateDivision,
  DeleteDivision,
  UpdateDivision,
} from "@/store/divisions/services/Division.service";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastService";

export function useDivisionsServices() {
  const store = useStore();
  const { t } = useI18n();

  const createDivision = async (
    payload: Division,
    callBackCreate: (division: Division) => void
  ): Promise<void> => {
    await CreateDivision(payload)
      .then((division: Division) => {
        store.commit("divisions/addDivision", division);
        callBackCreate(division);
        useToast().successToast(t("divisions.sucess.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const updateDivision = async (
    payload: Division,
    callBackUpdate: (division: Division) => void
  ): Promise<void> => {
    await UpdateDivision(payload)
      .then((division: Division) => {
        store.commit("divisions/updateDivision", payload);
        callBackUpdate(division);
        useToast().successToast(t("divisions.sucess.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const deleteDivision = async (
    payload: DeleteDivisionInterface,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteDivision(payload)
      .then(() => {
        callBackDelete();
        store.commit("divisions/deleteDivision", payload);
        useToast().successToast(t("divisions.sucess.deleted"));
      })
      .catch(({ response }) => {
        if (response?.messageCode === REQ_RES_CODES.SAME_ENTITY) {
          useToast().errorToast(t("divisions.errors.sameEntity"));
        } else {
          useToast().errorToast(t("global.unknownError"));
        }
      });
  };

  return {
    createDivision,
    updateDivision,
    deleteDivision,
  };
}
