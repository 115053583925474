import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row h-100" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "text-start" }
const _hoisted_5 = { class: "fs-4 fw-medium" }
const _hoisted_6 = { class: "fs-6 fw-bold" }
const _hoisted_7 = { class: "col-auto" }
const _hoisted_8 = { class: "mt-1" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col" }

import ProgressBar from "@/components/dashboard/ProgressBar.vue";
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import { CardOption } from "@/shared/globals/cards/types/CardOption.type";
import TextFormatter from "../globals/UiTools/TextFormatter.vue";
import OptionCard from "../globals/cards/OptionCard.vue";

const salesAmount = "45141";
const progress = 75;

export default /*@__PURE__*/_defineComponent({
  __name: 'SalesDetailsCard',
  setup(__props) {

const options: CardOption[] = [
  {
    name: "This Week",
    callBack: () => {
      return;
    },
  },
  {
    name: "Last Week",
    callBack: () => {
      return;
    },
  },
  {
    name: "Last Month",
    callBack: () => {
      return;
    },
  },
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GeneralCard, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("dashboard.soldsDetails")), 1),
              _createElementVNode("p", null, [
                _createVNode(TextFormatter, {
                  value: salesAmount,
                  formatType: "currency",
                  class: "fs-3 profit"
                }),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("dashboard.thisWeek")), 1),
                _cache[0] || (_cache[0] = _createElementVNode("i", { class: "bi bi-graph-up-arrow pl-1 icon" }, null, -1))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(OptionCard, { options: options })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(ProgressBar, { progress: progress })
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})