<template>
  <div
    :class="`container-fluid ${
      darkMode == true ? 'darkMode' : ''
    } d-flex flex-column`"
  >
    <div class="row fixedRow">
      <div class="col">
        <HeaderViews></HeaderViews>
      </div>
    </div>
    <div class="row flex-grow-1">
      <div class="col h-100">
        <div class="py-3 px-4 h-100">
          <router-view class="h-100"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// class="flex-grow-1"
import HeaderViews from "@/components/navigationMenu/HeaderViews.vue";
import { useAuthServices } from "@/composables/useAuthServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { onBeforeMount, ref } from "vue";

const { setDefaultLang } = useLanguageServices();
const { getUser } = useAuthServices();
const darkMode = ref(false);

function setDarkMode(event) {
  darkMode.value = event._value;
}

onBeforeMount(async () => {
  await getUser();
  setDefaultLang();
});
</script>

<style lang="scss" scoped>
.fixedRow {
  position: sticky;
  top: 0;
  z-index: 1000;
}
</style>
