import { useLanguageServices } from "@/composables/useLanguageServices";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { CatalogClassesKeys } from "@/store/catalogs/const/CatalogClasses.const";
import { CatalogClass } from "@/store/catalogs/models/CatalogClass.model";
import { customFieldToHeader } from "@/store/customFields/helpers/CustomFieldToHeader.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import { Order } from "@/store/orders/models/Order.model";

export const OrdersHeader = (
  runAction: (
    actionType: "edit" | "delete",
    order: Order,
    index: number
  ) => void,
  customFields: CustomFieldInterface[],
  catalogs: CatalogClass[]
): TableHeader[] => {
  const customFieldsHeaders: TableHeader[] = customFieldToHeader(customFields);
  const catalog = catalogs.find((catalog) => {
    return catalog.key === CatalogClassesKeys.ORDERS_STATUS_CLASS;
  });
  const { lang } = useLanguageServices();
  return [
    {
      sortable: true,
      value: { value: "orders.entity.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "380px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "orders.entity.orderDate", needsTranslate: true },
      key: "orderDate",
      mappedKey: "orderDate",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    {
      sortable: true,
      value: { value: "orders.entity.customer", needsTranslate: true },
      key: "customer.name",
      mappedKey: "customer.name",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "orders.entity.customerType", needsTranslate: true },
      key: "customer.customerType",
      mappedKey: "customer.customerType",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "orders.entity.status", needsTranslate: true },
      key: "status",
      mainKey: "status",
      mappedKey: [`catalogMetadata.name.language.[${lang.value}]`, "name"],
      columnType: "tag",
      width: "190px",
      filterType: "select",
      selectOptions: {
        idKey: "id",
        labelKey: [`catalogMetadata.name.language.[${lang.value}]`, "name"],
        isMultiple: false,
        initialOptions: catalog.catalogs,
      },
    },
    {
      sortable: true,
      value: { value: "orders.entity.createdAt", needsTranslate: true },
      key: "createdAt",
      mappedKey: "createdAt",
      columnType: "date",
      width: "150px",
      filterType: "date",
    },
    {
      sortable: true,
      value: { value: "orders.entity.updatedAt", needsTranslate: true },
      key: "updatedAt",
      mappedKey: "updatedAt",
      columnType: "date",
      width: "190px",
      filterType: "date",
    },
    ...customFieldsHeaders,
    {
      sortable: true,
      value: { value: "orders.tables.actions", needsTranslate: true },
      columnType: "action",
      options: (order: Order, index: number): Menu => {
        const options = [
          {
            label: {
              value: "orders.tables.actionList.edit",
              needsTranslate: true,
            },
            id: 1,
            action: () => runAction("edit", order, index),
          },
          {
            label: {
              value: "orders.tables.actionList.delete",
              needsTranslate: true,
            },
            id: 2,
            action: () => runAction("delete", order, index),
          },
        ];
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
