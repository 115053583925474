import axiosInstance from "@/middlewares/Routing.middleware";
import { Workplace } from "../models/Workplace.model";

export async function CreateWorkplace(payload: Workplace): Promise<Workplace> {
  return (await axiosInstance.post(`workplaces`, payload)).data;
}

export async function UpdateWorkplace(payload: Workplace): Promise<Workplace> {
  const { id, ...workplaceProps } = payload;
  return (await axiosInstance.put(`workplaces/${id}`, workplaceProps)).data;
}

export async function DeleteWorkplace(id: number): Promise<void> {
  await axiosInstance.delete(`workplaces/${id}`);
}
