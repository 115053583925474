<template>
  <div class="option h-100 d-flex py-2 w-100">
    <div v-if="header.submenu" class="d-flex flex-start flex-column w-100">
      <a
        data-bs-toggle="collapse"
        :href="`#${header.name}`"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
        :class="[...optionStyles, { active: isActive }]"
      >
        {{ $t(props.header.i18nName) }}
      </a>
      <div
        class="accordion-collapse collapse"
        :id="`${header.name}`"
        data-bs-parent="#accordionMenu"
        ref="accordionRef"
      >
        <div class="card card-body ml-4 p-1">
          <ul class="list-unstyled m-0">
            <li
              v-for="(element, index) of props.header.submenu"
              :key="element.i18nName + index"
              class="item rounded"
            >
              <a
                @click="navigateTo((element.action as RouterActionMenu).route)"
                class="m-0 text-start d-block rounded py-1"
                href="#"
              >
                {{ $t(element.i18nName) }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else>
      <router-link
        @click="props.closeNavbar"
        :to="{ name: props.header.action.route }"
        :class="optionStyles"
      >
        {{ $t(props.header.i18nName) }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { RouterActionMenu } from "@/shared/dashboard/sidebar/types/ActionsSidebarElements.type";
import { SidebarElement } from "@/shared/dashboard/sidebar/types/SidebarElements.type";
import { Collapse } from "bootstrap";
import { computed, defineProps, PropType, useTemplateRef } from "vue";
import { useRoute, useRouter } from "vue-router";

const props = defineProps({
  header: { type: Object as PropType<SidebarElement>, required: true },
  closeNavbar: { type: Function as PropType<() => void>, required: true },
});

const route = useRoute();
const router = useRouter();

const isActive = computed(() => {
  return props.header.submenu.some((element) => {
    return (element.action as RouterActionMenu).route === route.name;
  });
});

const acordionRef = useTemplateRef<Element>("accordionRef" as never);

function navigateTo(name: string): void {
  router.push({ name });
  const acordionTemp = new Collapse(acordionRef.value);
  acordionTemp.hide();
  props.closeNavbar();
}

const optionStyles = [
  "text-nowrap",
  "text-start",
  "link-item",
  "px-4",
  "py-1",
  "custom-item-style",
];
</script>

<style lang="scss" scoped>
$heightNav: 51px;

.custom-item-style {
  font-size: 14px;
  font-weight: 500;
  color: #d4e6ed;
}

a {
  text-decoration: none;
  color: inherit;
}

.dropdown-menu > * > a {
  color: #8e9db8;
  font-size: 14px;
  font-weight: 400;
}

.dropdown-menu > * > a:hover {
  background-color: #ebedf2;
  color: #4375ef;
}

.active,
.router-link-active {
  color: #3fcbd8;
}

.option {
  height: $heightNav;
}

.option:hover {
  .link-item {
    color: #3fcbd8;
    text-decoration: underline;
  }
}

.item {
  padding: 2px 10px;
}

.item:active {
  background-color: #ebedf2;
  transition: background-color 0.1s ease;
}
</style>
