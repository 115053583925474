import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";

export const buttonTranslate: ButtonTemplate = {
  backgroundColor: "#7451c2",
  customClass: "btn ml-1",
  graphic: {
    icon: {
      icon: "bi bi-translate",
      color: "white",
      backgroundColor: "#7451c2",
    },
  },
  action() {
    return;
  },
  metadata: {
    isAppend: false,
    positionInField: "end",
  },
};

export const buttonDelete: ButtonTemplate = {
  backgroundColor: "#dc3545",
  customClass: "btn ml-1",
  graphic: {
    icon: {
      icon: "bi bi-trash",
      color: "white",
      backgroundColor: "#dc3545",
    },
  },
  action() {
    return;
  },
  metadata: {
    isAppend: false,
    positionInField: "end",
  },
};

export const fieldCreate = (validations): Field => {
  return {
    path: "customField.metadata.options.",
    inputName: "customFieldOption.",
    type: "text",
    placeholder: {
      value: `customFields.addOption`,
      needsTranslate: false,
    },
    metadata: {
      tooltipPosition: "top",
    },
    label: {
      value: "customFields.values",
      needsTranslate: true,
    },
    buttons: [
      buttonTranslate,
      { ...buttonDelete, customClass: `${buttonDelete.customClass} disabled` },
    ],
    methodsYup: () => {
      const { yupRules } = validations;
      const { stringYup, requiredYup } = yupRules;
      const validation = [stringYup, requiredYup];
      return validation;
    },
  };
};

export const createOrUpdateMultipleSelectSettings = (
  action,
  validations
): Form => {
  return {
    sections: [
      {
        fields: [
          {
            path: "customField.metadata.fieldSettings.selectionLimit",
            inputName: "customFieldSelectionLimit",
            label: {
              value: "customFields.selectionLimit",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              const { yupRules } = validations;
              const { stringYup, requiredYup } = yupRules;
              const validation = [stringYup, requiredYup];
              return validation;
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: { value: "customFields.save", needsTranslate: true },
      action,
    },
  };
};
