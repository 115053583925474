<template>
  <div class="containter-fluid">
    <div class="row">
      <div class="col">
        <h3 class="text-start fs-4 fw-normal my-0">
          {{ $t("dashboard.title") }}
        </h3>
      </div>
    </div>
    <div class="row mt-3 g-3">
      <div class="col-lg-3">
        <card-label
          title="dashboard.statistics"
          name="userLabelCard"
          :values="statisticsValues"
        ></card-label>
      </div>
      <div class="col-lg-5">
        <sales-details-card></sales-details-card>
      </div>
      <div class="col-lg-4">
        <balance-card
          :totalBalance="totalBalance"
          :growth="growth"
        ></balance-card>
      </div>
    </div>
    <div class="row mt-3 g-3">
      <div class="col-lg-9 pe-2">
        <solds-card></solds-card>
      </div>
      <div class="col-lg-3">
        <transactions-card></transactions-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BalanceCard from "@/components/dashboard/BalanceCard.vue";
import SalesDetailsCard from "@/components/dashboard/SalesDetailsCard.vue";
import SoldsCard from "@/components/dashboard/SoldsCard.vue";
import TransactionsCard from "@/components/dashboard/TransactionsCard.vue";
import CardLabel from "@/components/globals/cards/CardLabel.vue";
import { CardValue } from "@/shared/globals/cards/types/CardValue.type";

//const { user } = useUsersServices();

const statisticsValues: CardValue[] = [
  {
    topLabel: "dashboard.customers",
    icon: { icon: "bi bi-bar-chart-line" },
    value: 423,
    type: "number",
    primaryColor: "#c99c60",
    secondaryColor: "#fbf2e4",
  },
  {
    topLabel: "dashboard.sales",
    icon: { icon: "bi bi-currency-dollar" },
    value: 700929,
    type: "number",
    primaryColor: "#159a53",
    secondaryColor: "#d9f2ec",
  },
];

const totalBalance = "4001741.42";
const growth = "+13.6";
</script>

<style lang="scss">
.legends {
  padding-top: 20px;
  overflow: hidden;
}

.legend {
  display: block;
  width: 8px;
  height: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 50%;
}

.legend--this {
  background-color: #5555ff;
}

.legend--prev {
  background-color: #ff55b8;
}

.axis {
  position: absolute;
  color: #fff;
  z-index: 1;
  text-transform: uppercase;
  display: flex;
  width: 100%;
  bottom: 0;
}

.axis .tick {
  flex: 1;
  position: relative;
  overflow: hidden;
  opacity: 0.2;
  font-size: 11px;
  text-align: center;
  line-height: 40px;
  padding-top: 150px;
}

.axis .tick:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.2);
}

.axis .tick .value {
  transform: translateY(-40px);
  transition: 0.3s transform;
  position: absolute;
  top: 20px;
  color: #444;
  border-radius: 2px;
  width: 100%;
  line-height: 20px;
}

.axis .tick:hover .value.value--this {
  transform: translateY(0);
  display: block;
}

.value.value--this {
  color: #5555ff;
  font-weight: bold;
}
</style>
