import { Supplier } from "@/store/suppliers/models/Supplier.model";
import {
  CreateSupplier,
  DeleteSupplier,
  GetAllSuppliers,
  UpdateSupplier,
} from "@/store/suppliers/services/Supplier.service";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastService";
import { DeletionMeasureInterface } from "@/components/measures/interfaces/deletion-measure.interface";

export function useSuppliersServices() {
  const store = useStore();
  const { t } = useI18n();

  const suppliers = computed(() => {
    return store.state.suppliers.suppliers;
  });

  const getAllSuppliers = async (): Promise<void> => {
    if (store.state.suppliers.suppliers.length === 0) {
      await GetAllSuppliers()
        .then((suppliers: Partial<Supplier>[]) => {
          store.commit("suppliers/setSuppliers", suppliers);
        })
        .catch(({ response }) => {
          return;
        });
    }
  };

  const createSupplier = async (
    payload: Supplier,
    callBackCreate: (supplier: Supplier) => void
  ): Promise<void> => {
    await CreateSupplier(payload)
      .then(async (supplier: Supplier) => {
        await getAllSuppliers();
        store.commit("suppliers/addSupplier", supplier);
        callBackCreate(supplier);
        useToast().successToast(t("suppliers.sucess.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const updateSupplier = async (
    payload: Supplier,
    callBackUpdate: (supplier: Supplier) => void
  ): Promise<void> => {
    await UpdateSupplier(payload)
      .then(async () => {
        await getAllSuppliers();
        store.commit("suppliers/updateSupplier", payload);
        callBackUpdate(payload);
        useToast().successToast(t("suppliers.sucess.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const deleteSupplier = async (
    payload: DeletionMeasureInterface,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteSupplier(payload)
      .then(async () => {
        await getAllSuppliers();
        callBackDelete();
        store.commit("suppliers/deleteSupplier", { id: payload.oldId });
        useToast().successToast(t("suppliers.sucess.deleted"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };
  return {
    suppliers,
    getAllSuppliers,
    createSupplier,
    updateSupplier,
    deleteSupplier,
  };
}
