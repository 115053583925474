import { Module } from "vuex";
import { ProductFamily } from "./models/ProductFamily.model";

interface State {
  productFamilies?: Partial<ProductFamily>[];
}

export const productFamiliesStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    productFamilies: [],
  },
  getters: {},
  mutations: {
    setProductFamilies(state, productFamilies: Partial<ProductFamily>[]) {
      state.productFamilies = productFamilies;
    },
    addProductFamily(state, productFamily: Partial<ProductFamily>) {
      state.productFamilies = [...state.productFamilies, productFamily];
    },
    updateProductFamily(state, productFamily: Partial<ProductFamily>) {
      const index = state.productFamilies.findIndex(
        (p) => p.id === productFamily.id
      );
      if (index !== -1) {
        state.productFamilies[index] = {
          ...state.productFamilies[index],
          ...productFamily,
        };
      } else {
        state.productFamilies.push({ ...productFamily });
      }
    },
    deleteProductFamily(state, productFamily: Partial<ProductFamily>) {
      state.productFamilies = state.productFamilies.filter(
        (p) => p.id !== productFamily.id
      );
    },
  },
  actions: {},
  modules: {},
};
