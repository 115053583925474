import { Form } from "@/shared/globals/forms/interfaces/Form.interface";

export const changePassword = (action, validations): Form => {
  return {
    sections: [
      {
        fields: [
          {
            path: "oldPassword",
            inputName: "resetPasswordNewPass",
            label: {
              value: "auth.fields.oldPassword",
              needsTranslate: true,
            },
            type: "password",
            methodsYup: () => {
              const { passwordValidation } = validations;
              return passwordValidation;
            },
          },
          {
            path: "newPassword",
            inputName: "resetPasswordNewPass",
            label: {
              value: "auth.fields.newPassword",
              needsTranslate: true,
            },
            type: "password",
            methodsYup: () => {
              const { passwordValidation } = validations;
              return passwordValidation;
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "#7451c2",
      text: {
        value: "auth.carouselTabs.forgotPassword.submit2",
        needsTranslate: true,
      },
      action,
    },
  };
};
