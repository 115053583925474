import { Form } from "@/shared/globals/forms/interfaces/Form.interface";

export const forgotPasswordStep1 = (action, validations): Form => {
  return {
    sections: [
      {
        fields: [
          {
            path: "email",
            inputName: "emailForgot",
            label: {
              value: "auth.fields.email",
              needsTranslate: true,
            },
            type: "email",
            methodsYup: () => {
              const { emailValidation } = validations;
              return emailValidation;
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: "auth.carouselTabs.forgotPassword.submit1",
        needsTranslate: true,
      },
      action,
    },
  };
};
