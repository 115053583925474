import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}

import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { PropType } from "vue";
import ProcessText from "../UiTools/ProcessText.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonMaster',
  props: {
  text: { type: Object as PropType<LabelType>, required: true },
  isLoading: { type: Boolean, required: false },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    disabled: __props.isLoading,
    type: "submit",
    class: "btn w-100",
    id: "btnLogin"
  }, [
    (__props.isLoading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : (_openBlock(), _createBlock(ProcessText, {
          key: 1,
          value: __props.text
        }, null, 8, ["value"]))
  ], 8, _hoisted_1))
}
}

})