import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
} from "date-fns";
import { DateUnit } from "../types/DateUnits.type";

type DifferenceOperatorsMap = {
  [key in DateUnit]: (dateLeft: Date, dateRight: Date) => number;
};

export const DIFFERENCE_OPERATORS: DifferenceOperatorsMap = {
  year: differenceInYears,
  month: differenceInMonths,
  week: differenceInWeeks,
  day: differenceInDays,
  hour: differenceInHours,
  minute: differenceInMinutes,
  second: differenceInSeconds,
};
