import { Module } from "vuex";
import { Order } from "./models/Order.model";

interface State {
  orders?: Partial<Order>[];
}

export const ordersStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    orders: [],
  },
  getters: {},
  mutations: {
    setOrders(state, orders: Partial<Order>[]) {
      state.orders = orders;
    },
    addOrder(state, order: Partial<Order>) {
      const orders = [...state.orders, { ...order }];
      state.orders = orders;
    },
    updateOrder(state, order: Partial<Order>) {
      const index = state.orders.findIndex((p) => p.id === order.id);
      if (index !== -1) {
        state.orders[index] = { ...state.orders[index], ...order };
      } else {
        state.orders.push({ ...order });
      }
    },
    deleteOrder(state, order: Partial<Order>) {
      state.orders = state.orders.filter((p) => p.id !== order.id);
    },
  },
  actions: {},
  modules: {},
};
