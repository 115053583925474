import { Module } from "vuex";
import { Customer } from "./models/Customer.model";

interface State {
  customers?: Partial<Customer>[];
}

export const customersStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    customers: [],
  },
  getters: {},
  mutations: {
    setCustomers(state, customers: Partial<Customer>[]) {
      state.customers = customers;
    },
    addCustomer(state, customer: Partial<Customer>) {
      state.customers = [...state.customers, customer];
    },
    updateCustomer(state, customer: Partial<Customer>) {
      const index = state.customers.findIndex((p) => p.id === customer.id);
      if (index !== -1) {
        state.customers[index] = { ...state.customers[index], ...customer };
      } else {
        state.customers.push({ ...customer });
      }
    },
    deleteCustomer(state, customer: Partial<Customer>) {
      state.customers = state.customers.filter((p) => p.id !== customer.id);
    },
  },
  actions: {},
  modules: {},
};
