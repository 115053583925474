import axiosInstance from "@/middlewares/Routing.middleware";
import { CustomFieldInterface } from "../interfaces/CustomField.interface";

export async function CreateCustomField(
  moduleName: string,
  payload: CustomFieldInterface
) {
  return await axiosInstance.post(`custom-fields`, {
    ...payload,
    module: { name: moduleName },
  });
}

export async function UpdateCustomField(
  moduleName: string,
  payload: CustomFieldInterface
) {
  return await axiosInstance.put(`custom-fields/${payload.id}`, {
    ...payload,
    module: { name: moduleName },
  });
}

export async function DeleteCustomField(id: string) {
  return await axiosInstance.delete(`custom-fields/${id}`);
}
