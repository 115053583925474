import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col text-start" }
const _hoisted_3 = { class: "m-0 p-0" }
const _hoisted_4 = { class: "m-0 p-0 date" }

import { PropType } from "vue";
import TextFormatter from "../globals/UiTools/TextFormatter.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionItem',
  props: {
  name: { type: String, required: true },
  date: { type: Date, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-auto" }, [
      _createElementVNode("i", { class: "bi bi-plus-lg icon-style" })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(props.name), 1),
      _createElementVNode("p", _hoisted_4, [
        _createVNode(TextFormatter, {
          value: String(props.date),
          formatType: "date"
        }, null, 8, ["value"])
      ])
    ])
  ]))
}
}

})