import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";

export const CreateOrUpdateDetailTemplate = (
  action: () => void,
  isCreating: boolean
): Form => {
  const { lang } = useLanguageServices();
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "detail.product",
            inputName: "detailProductId",
            label: {
              value: "orderDetail.entity.product",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "top",
            },
            selectOptions: {
              dataSource: "/products/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "detail.quantity",
            inputName: "detailQuantity",
            label: {
              value: "orderDetail.entity.quantity",
              needsTranslate: true,
            },
            type: "number",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "detail.total",
            inputName: "detailTotal",
            label: {
              value: "orderDetail.entity.total",
              needsTranslate: true,
            },
            type: "number",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "detail.discount",
            inputName: "detailDiscount",
            label: {
              value: "orderDetail.entity.discount",
              needsTranslate: true,
            },
            type: "number",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      text: {
        value: isCreating ? "orderDetail.form.add" : "orderDetail.form.update",
        needsTranslate: true,
      },
      action,
    },
  };
  return form;
};
