import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container d-flex flex-column justify-content-center" }
const _hoisted_2 = { class: "row mb-2" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col" }

import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { Modal } from "bootstrap";
import { PropType, ref } from "vue";
import ButtonField from "../buttons/ButtonField.vue";
import { cancelButton } from "../buttons/templates/CancelButton.template";
import { confirmButton } from "../buttons/templates/ConfirmButton.template";
import ProcessText from "../UiTools/ProcessText.vue";
import GeneralModal from "./GeneralModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmModal',
  props: {
  title: { type: String, required: true },
  message: { type: Object as PropType<LabelType>, required: false },
  cancelAction: {
    type: Function as PropType<() => Promise<void>>,
    required: false,
  },
  confirmAction: {
    type: Function as PropType<() => Promise<void>>,
    required: false,
  },
},
  setup(__props, { expose: __expose }) {

const props = __props;

const modalRef = ref();

async function handleCancel() {
  if (props.cancelAction) {
    await props.cancelAction();
  }
  await closeConfirmationModal();
}

async function handleConfirm() {
  if (props.confirmAction) {
    await props.confirmAction();
  }
  await closeConfirmationModal();
}

async function closeConfirmationModal() {
  if (modalRef.value?.$el) {
    const modal = Modal.getOrCreateInstance(modalRef.value?.$el);
    modal.hide();
  }
}

async function openConfirmationModal() {
  const modal = Modal.getOrCreateInstance(modalRef.value?.$el);
  modal.show();
}

__expose({
  openConfirmationModal,
  closeConfirmationModal,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GeneralModal, {
    modalId: `confirmModa${__props.title}`,
    ref_key: "modalRef",
    ref: modalRef,
    modalName: `${__props.title}`,
    "data-bs-backdrop": "false",
    class: "p-4"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(ProcessText, { value: __props.message }, null, 8, ["value"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(ButtonField, {
                "button-field": _unref(cancelButton)(handleCancel)
              }, null, 8, ["button-field"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(ButtonField, {
                "button-field": _unref(confirmButton)(handleConfirm),
                "require-loading": true
              }, null, 8, ["button-field"])
            ])
          ])
        ])
      ])
    ]),
    _: 3
  }, 8, ["modalId", "modalName"]))
}
}

})