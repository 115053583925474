<template>
  <div class="d-flex justify-content-between align-items-center w-100">
    <div class="flex-grow-1 me-2 progressContainer">
      <div class="progress">
        <div
          class="progress-bar barColor"
          role="progressbar"
          :style="`width: ${progressText}`"
          :aria-valuenow="`${progress}`"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
    <div class="porcentageText">{{ progressText }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
const props = defineProps({
  progress: { type: Number, required: true },
});
const progressText = computed(() => {
  return props.progress + "%";
});
</script>

<style scoped lang="scss">
.progressContainer {
  border-radius: 20px;
  border: solid #e9ecef 4px;
}

.barColor {
  background-image: linear-gradient(
    to right,
    #4661ec,
    #5c4b8b,
    #7e4f8d,
    #9a5f9d
  );
  background-size: 100% 100%;
  border-radius: 5px;
}

.porcentageText {
  color: red;
  font-weight: 400;
}
</style>
