<template>
  <div class="dropdown container-fluid">
    <button
      class="btn dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img width="20px" :src="currentLangData.image" alt="currentLang" />
    </button>
    <ul class="dropdown-menu">
      <li
        class="dropdown-item"
        v-for="lang in langValues"
        :key="`lang${lang.key}`"
        @click="selectLang(lang.key)"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-auto pr-0">
              <img width="15px" :src="lang.image" alt="langOption" />
            </div>
            <div class="col">
              <span href="#">{{ lang.name }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useLanguageServices } from "@/composables/useLanguageServices";

const { selectLang, langValues, currentLangData } = useLanguageServices();
</script>

<style lang="scss" scoped>
.changeLangIcon {
  width: max-content;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-menu {
  width: 11em;
}
</style>
