import axiosInstance from "@/middlewares/Routing.middleware";
import { Order } from "../models/Order.model";

export async function GetAllOrders(): Promise<Order[]> {
  return (await axiosInstance.get(`orders/all`)).data;
}

export async function CreateOrder(payload: Order): Promise<Order> {
  return (await axiosInstance.post(`orders`, payload)).data;
}

export async function UpdateOrder(payload: Order): Promise<Order> {
  const { id, ...orderProps } = payload;
  return (await axiosInstance.put(`orders/${id}`, orderProps)).data;
}

export async function DeleteOrder(id: number): Promise<void> {
  await axiosInstance.delete(`orders/${id}`);
}
