import axiosInstance from "@/middlewares/Routing.middleware";
import { CatalogClassInterface } from "../interfaces/CatalogClass.interface";
import { Catalog } from "../interfaces/Catalogs.interface";

export async function getCatalogs(): Promise<CatalogClassInterface[]> {
  return (await axiosInstance.get("/catalogs")).data;
}

export async function getCatalogClasses(): Promise<
  Partial<CatalogClassInterface>[]
> {
  return (await axiosInstance.get("/catalogs/classes")).data;
}

export async function createCatalog(catalog: Catalog): Promise<Catalog> {
  return (await axiosInstance.post("/catalogs", catalog)).data;
}

export async function getCatalogByClassName(
  className: string
): Promise<Catalog> {
  return (await axiosInstance.get("/catalogs", { params: { className } })).data;
}
