import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "option h-100 d-flex py-2 w-100" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-start flex-column w-100"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "card card-body ml-4 p-1" }
const _hoisted_6 = { class: "list-unstyled m-0" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 1 }

import { RouterActionMenu } from "@/shared/dashboard/sidebar/types/ActionsSidebarElements.type";
import { SidebarElement } from "@/shared/dashboard/sidebar/types/SidebarElements.type";
import { Collapse } from "bootstrap";
import { computed, PropType, useTemplateRef } from "vue";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderOptionSmall',
  props: {
  header: { type: Object as PropType<SidebarElement>, required: true },
  closeNavbar: { type: Function as PropType<() => void>, required: true },
},
  setup(__props) {

const props = __props;

const route = useRoute();
const router = useRouter();

const isActive = computed(() => {
  return props.header.submenu.some((element) => {
    return (element.action as RouterActionMenu).route === route.name;
  });
});

const acordionRef = useTemplateRef<Element>("accordionRef" as never);

function navigateTo(name: string): void {
  router.push({ name });
  const acordionTemp = new Collapse(acordionRef.value);
  acordionTemp.hide();
  props.closeNavbar();
}

const optionStyles = [
  "text-nowrap",
  "text-start",
  "link-item",
  "px-4",
  "py-1",
  "custom-item-style",
];

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.header.submenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("a", {
            "data-bs-toggle": "collapse",
            href: `#${__props.header.name}`,
            role: "button",
            "aria-expanded": "false",
            "aria-controls": "collapseExample",
            class: _normalizeClass([...optionStyles, { active: isActive.value }])
          }, _toDisplayString(_ctx.$t(props.header.i18nName)), 11, _hoisted_3),
          _createElementVNode("div", {
            class: "accordion-collapse collapse",
            id: `${__props.header.name}`,
            "data-bs-parent": "#accordionMenu",
            ref: "accordionRef"
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("ul", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.header.submenu, (element, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: element.i18nName + index,
                    class: "item rounded"
                  }, [
                    _createElementVNode("a", {
                      onClick: ($event: any) => (navigateTo((element.action as RouterActionMenu).route)),
                      class: "m-0 text-start d-block rounded py-1",
                      href: "#"
                    }, _toDisplayString(_ctx.$t(element.i18nName)), 9, _hoisted_7)
                  ]))
                }), 128))
              ])
            ])
          ], 8, _hoisted_4)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_router_link, {
            onClick: props.closeNavbar,
            to: { name: props.header.action.route },
            class: _normalizeClass(optionStyles)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(props.header.i18nName)), 1)
            ]),
            _: 1
          }, 8, ["onClick", "to"])
        ]))
  ]))
}
}

})