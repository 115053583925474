import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "modal-body" }

import { Modal } from "bootstrap";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralModal',
  props: {
  modalId: { type: String, required: true },
  modalName: { type: String, required: true },
},
  emits: ["modalClosed"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const modalRef = ref();

function modalClosed() {
  emit("modalClosed");
}

function closeModal() {
  modalRef?.value && Modal.getOrCreateInstance(modalRef.value)?.hide();
}

function openModal() {
  modalRef?.value && Modal.getOrCreateInstance(modalRef.value)?.show();
}

__expose({
  openModal,
  closeModal,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: props.modalId,
    tabindex: "-1",
    "aria-labelledby": `${props.modalId}Label`,
    "aria-hidden": "true",
    ref_key: "modalRef",
    ref: modalRef
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", {
            class: "modal-title",
            id: `${props.modalId}Label`
          }, _toDisplayString(props.modalName), 9, _hoisted_5),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close",
            onClick: modalClosed
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ], 8, _hoisted_1))
}
}

})