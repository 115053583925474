import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";

export const eyeClosedButton = (action): ButtonTemplate => ({
  backgroundColor: "#6c757d",
  customClass: "btn ml-1",
  graphic: {
    icon: {
      icon: "bi bi-eye-slash",
      color: "white",
      backgroundColor: "#6c757d",
    },
  },
  action,
  metadata: {
    isAppend: false,
    positionInField: "end",
  },
});
