import axiosInstance from "@/middlewares/Routing.middleware";
import { Product } from "../models/Product.model";

export async function GetAllProducts(): Promise<Product[]> {
  return (await axiosInstance.get(`products/all`)).data;
}

export async function CreateProduct(payload: Product): Promise<Product> {
  return (await axiosInstance.post(`products`, payload)).data;
}

export async function UpdateProduct(payload: Product): Promise<Product> {
  const { id, ...productProps } = payload;
  return (await axiosInstance.put(`products/${id}`, productProps)).data;
}

export async function DeleteProduct(id: number): Promise<void> {
  await axiosInstance.delete(`products/${id}`);
}
