import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = { class: "offcanvas-header" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "offcanvas-body" }

import { Offcanvas } from "bootstrap";
import {
  computed,
  onMounted,
  Ref,
  ref,
} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralOffcanvas',
  props: {
  canvasId: { type: String, required: true },
  canvasName: { type: String, required: true },
},
  emits: ["closed"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const canvas: Ref<Element> = ref(undefined);
const offCanvasRef = ref();
const childRef = ref();
const isOpen = ref(false);
const width = ref(30);

function closeOffcanvas() {
  emit("closed");
  isOpen.value = false;
}

onMounted(() => {
  canvas.value = document.getElementById(props.canvasId);
  canvas.value.addEventListener("hidden.bs.offcanvas", (event) => {
    emit("closed");
  });
});

function openOffCanvas() {
  if (offCanvasRef.value) {
    Offcanvas.getOrCreateInstance(offCanvasRef.value).show();
    isOpen.value = true;
  }
}

function closeOffCanvas() {
  if (offCanvasRef.value) {
    Offcanvas.getOrCreateInstance(offCanvasRef.value).hide();
    isOpen.value = false;
  }
}

const childWidth = computed(() => {
  const tempWidth =
    childRef.value?.firstElementChild?.__vueParentComponent?.exposed?.width
      ?.value;
  if (childIsOpen.value && tempWidth) {
    return tempWidth;
  }
  return 0;
});

const childIsOpen = computed(() => {
  return childRef.value?.firstElementChild?.__vueParentComponent?.exposed
    ?.isOpen?.value;
});

__expose({ isOpen, width, openOffCanvas, closeOffCanvas });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "offcanvas offcanvas-end",
        style: _normalizeStyle(`width: ${width.value}em !important; transform: translateX(-${
        childWidth.value / 2
      }em);`),
        tabindex: "-1",
        id: __props.canvasId,
        "aria-labelledby": `${__props.canvasId}Label`,
        "data-bs-backdrop": true,
        ref_key: "offCanvasRef",
        ref: offCanvasRef
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", {
            class: "offcanvas-title",
            id: `${__props.canvasId}Label`
          }, _toDisplayString(__props.canvasName), 9, _hoisted_3),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "offcanvas",
            "aria-label": "Close",
            onClick: closeOffcanvas
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "content"),
          _createElementVNode("div", {
            class: _normalizeClass(`${
            isOpen.value && childIsOpen.value ? 'offcanvas-backdrop fade show' : ''
          }`)
          }, null, 2)
        ])
      ], 12, _hoisted_1),
      _createElementVNode("div", {
        class: "offcanvas-child",
        ref_key: "childRef",
        ref: childRef
      }, [
        _renderSlot(_ctx.$slots, "offcanvasChild")
      ], 512)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(`${isOpen.value && childIsOpen.value ? 'offcanvas-backdrop fade show' : ''}`)
    }, null, 2)
  ], 64))
}
}

})