import { Module } from "vuex";
import { Measure } from "./models/Measure.model";

interface State {
  measures?: Partial<Measure>[];
}

export const measuresStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    measures: [],
  },
  getters: {},
  mutations: {
    setMeasures(state, measures: Partial<Measure>[]) {
      state.measures = measures;
    },
    addMeasure(state, measure: Partial<Measure>) {
      const measures = [...state.measures, { ...measure }];
      state.measures = measures;
    },
    updateMeasure(state, measure: Partial<Measure>) {
      const index = state.measures.findIndex((p) => p.id === measure.id);
      if (index !== -1) {
        state.measures[index] = { ...state.measures[index], ...measure };
      } else {
        state.measures.push({ ...measure });
      }
    },
    deleteMeasure(state, measure: Partial<Measure>) {
      state.measures = state.measures.filter((p) => p.id !== measure.id);
    },
  },
  actions: {},
  modules: {},
};
