<template>
  <div
    class="container h-100 d-flex align-items-center"
    style="max-width: 540px"
  >
    <div class="row h-100 w-100">
      <div class="col-12 m-auto">
        <div class="customShadow p-3 rounded text-start">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped lang="scss">
.customShadow {
  border: 1px solid #e0e6ed;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}
</style>
