import { useLanguageServices } from "@/composables/useLanguageServices";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { UserInterface } from "@/store/auth/interfaces/User.interface";
import { Permission } from "@/store/permissions/models/Permission.model";

export const ProfileHeader = (
  optionEdit,
  optionDelete,
  permissions: Permission[]
): TableHeader[] => {
  const { lang } = useLanguageServices();
  return [
    {
      sortable: true,
      value: { value: "profiles.entity.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "20px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "profiles.entity.name", needsTranslate: true },
      key: "name",
      mappedKey: [`metadata.language.name.${lang.value}`, "name"],
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "profiles.entity.description", needsTranslate: true },
      key: "description",
      mappedKey: [`metadata.language.description.${lang.value}`, "description"],
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "profiles.entity.createdAt", needsTranslate: true },
      key: "createdAt",
      mappedKey: "createdAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    {
      sortable: true,
      value: { value: "profiles.entity.updatedAt", needsTranslate: true },
      key: "updatedAt",
      mappedKey: "updatedAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    {
      sortable: true,
      value: { value: "profiles.entity.permissions", needsTranslate: true },
      key: "permissions.id",
      mainKey: "permissions",
      mappedKey: [`translates.name.[${lang.value}]`, "name"],
      columnType: "tag",
      filterType: "select",
      width: "190px",
      selectOptions: {
        idKey: "id",
        labelKey: [`translates.name.[${lang.value}]`, "name"],
        isMultiple: false,
        initialOptions: permissions,
      },
    },
    {
      sortable: true,
      value: { value: "profiles.table.actions", needsTranslate: true },
      columnType: "action",
      options: (user: UserInterface, index: number): Menu => {
        let options = [
          {
            label: {
              value: "profiles.table.actionList.edit",
              needsTranslate: true,
            },
            id: 1,
            action: () => optionEdit(user, index),
          },
        ];
        if (user?.account?.id) {
          options = [
            ...options,
            {
              label: {
                value: "profiles.table.actionList.delete",
                needsTranslate: true,
              },
              id: 2,
              action: () => optionDelete(user, index),
            },
          ];
        }
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
