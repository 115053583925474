import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useTablesServices } from "@/composables/useTablesServices";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { get } from "lodash";
import {
  computed,
  nextTick,
  onMounted,
  PropType,
  ref,
  Ref,
  toRef,
  watch,
} from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

type OptionType = { id: string; label: string };

const offset = 6;

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectMaster',
  props: {
  dataSource: { type: String, required: false },
  initialOptions: {
    type: Object as PropType<any[]>,
    required: false,
  },
  idKey: { type: String, required: true },
  labelKey: { type: Object as PropType<string | string[]>, required: true },
  isMultiple: { type: Boolean, required: true },
  setValue: { type: Function, required: true },
  value: {
    type: Object as PropType<any>,
    required: false,
  },
  disabled: { type: Boolean, required: false },
},
  setup(__props) {

const isSelectable = (option): boolean => {
  if (!option) {
    return false;
  }
  if (Array.isArray(localValue.value)) {
    return !localValue.value.find((opt) => opt.id === option.id);
  }
  return localValue.value?.id !== option.id;
};

const props = __props;

const observer = ref();
const load = ref();
const elements = ref([]);
const headers: Ref<TableHeader[]> = ref([
  {
    sortable: true,
    value: { value: "", needsTranslate: false },
    key: "id",
    mappedKey: props.idKey,
    columnType: "number",
    width: "0px",
    filterType: "text",
  },
  {
    sortable: true,
    value: { value: "", needsTranslate: false },
    key: "label",
    mappedKey: props.labelKey,
    columnType: "text",
    width: "0px",
    filterType: "text",
  },
]);

const orderBy = ref("id");
const orderType: Ref<"ASC" | "DESC"> = ref("DESC");
const localValue: Ref<
  { id: any; label: any } | Array<{ id: any; label: any }>
> = ref();
const valueRef = toRef(props, "value");

const options: Ref<OptionType[]> = computed(() => {
  return elements.value;
});

const {
  getTableData,
  currentPage,
  totalPages,
  pageSize,
  mappedData,
  filters,
  applyFilters,
} = useTablesServices(props.dataSource, headers, orderBy, orderType);

async function onClose() {
  observer.value?.disconnect();
}

async function onSearch(query: string, loading) {
  loading(true);
  if (props.dataSource) {
    currentPage.value = 1;
    filters.value = {};
    if (query.length > 2) {
      applyFilters({ value: `${query}`, path: "name" });
      await getElementList();
      elements.value = mappedData.value;
    } else if (query.length === 0) {
      await getElementList();
      elements.value = mappedData.value;
    }
  } else {
    if (query.length > 0) {
      elements.value = elements.value.filter((element) => {
        return element.label.toLowerCase().startsWith(query.toLowerCase());
      });
    } else {
      elements.value = props.initialOptions?.map((element) => {
        return formatElement(element);
      });
    }
  }
  loading(false);
}

function hasNextPage() {
  return currentPage.value <= totalPages.value;
}

async function infiniteScroll(scrollProps) {
  const [{ isIntersecting, target }] = scrollProps;
  if (isIntersecting) {
    const ul = target.offsetParent;
    const scrollTop = target.offsetParent.scrollTop;
    await getElementList();
    await nextTick();
    ul.scrollTop = scrollTop;
  }
}

async function getElementList(forceLoad = false) {
  if (hasNextPage() || forceLoad) {
    await getTableData();
    if (currentPage.value === 1) {
      elements.value = [];
    }
    elements.value = [...elements.value, ...mappedData.value];
    currentPage.value++;
  }
}

watch(
  valueRef,
  () => {
    if (valueRef.value === "") {
      localValue.value = "" as unknown as { id: any; label: any };
    }
    if (JSON.stringify(localValue.value) === JSON.stringify(valueRef.value)) {
      return;
    }
    if (Array.isArray(valueRef.value)) {
      localValue.value = valueRef.value.map((value) => {
        return formatElement(value);
      });
    } else {
      localValue.value = formatElement(valueRef.value);
    }
  },
  { deep: true, immediate: true }
);

watch(
  localValue,
  async () => {
    if ((localValue.value as unknown) === "") {
      await getElementList();
      return;
    }
    if (JSON.stringify(localValue.value) === JSON.stringify(valueRef.value)) {
      return;
    }
    if (Array.isArray(localValue.value)) {
      props.setValue(localValue.value.length > 0 ? localValue.value : "");
    } else {
      props.setValue(localValue.value?.id ? localValue.value : "");
    }
  },
  { deep: true }
);

watch(
  [load],
  async () => {
    if (load.value && observer.value) {
      await nextTick();
      observer.value?.observe(load.value);
    }
  },
  { immediate: true, deep: true }
);

function formatElement(element) {
  let newElement;
  if (Array.isArray(props.labelKey)) {
    for (const key of props.labelKey) {
      const value = {
        id: get(element, props.idKey, false),
        label: get(element, key, false),
      };
      if (value.id && value.label) {
        newElement = value;
        break;
      }
    }
  } else {
    newElement = {
      id: get(element, props.idKey, false),
      label: get(element, props.labelKey, false),
    };
  }
  return newElement ? newElement : "";
}

onMounted(async () => {
  await getElementList(true);

  if (props.dataSource) {
    pageSize.value = offset;
    observer.value = new IntersectionObserver(infiniteScroll);
  } else {
    elements.value = props.initialOptions?.map((element) => {
      return formatElement(element);
    });
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(vSelect), {
    options: options.value,
    filterable: false,
    multiple: props.isMultiple,
    onClose: onClose,
    onSearch: onSearch,
    class: "w-100",
    modelValue: localValue.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localValue).value = $event)),
    selectable: isSelectable,
    placeholder: __props.value?.label ?? '',
    disabled: __props.disabled
  }, {
    "list-footer": _withCtx(() => [
      _withDirectives(_createElementVNode("li", {
        ref_key: "load",
        ref: load,
        class: "loader"
      }, null, 512), [
        [_vShow, hasNextPage]
      ])
    ]),
    "no-options": _withCtx(() => [
      _createElementVNode("span", { ref: "noOptions" }, _toDisplayString(_ctx.$t("global.inputs.noOptions")), 513)
    ]),
    _: 1
  }, 8, ["options", "multiple", "modelValue", "placeholder", "disabled"]))
}
}

})