import { locales } from "@/shared/locales/consts/locales.const";
import {
  getLang,
  selectLang,
  setDefaultLang,
} from "@/shared/locales/services/i18n.services";
import { LangType } from "@/shared/locales/types/Lang.type";
import { computed, ref, Ref, watch } from "vue";

export function useLanguageServices() {
  const lang: Ref<LangType> = ref(getLang());

  const langValues = ref([...locales]);

  const currentLangData = computed(() => {
    return locales.find(
      (langValue) => (langValue.key as LangType) === lang.value
    );
  });

  watch(getLang, async (newLang) => {
    lang.value = newLang;
  });

  return { lang, langValues, selectLang, setDefaultLang, currentLangData };
}
