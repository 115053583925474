import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  isGoogleMapsIframeYup,
  isGreaterYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateWorkplacesFlowTemplate = (
  customFields: CustomFieldInterface[],
  action,
  isCreating: boolean
): Form => {
  const { lang } = useLanguageServices();

  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "workplacesFlow.workplace ",
            inputName: "workplacesFlowWorkplace",
            label: {
              value: "workplacesFlows.entity.workplace",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              dataSource: "/workplaces/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
            },
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, isGoogleMapsIframeYup];
            },
          },
          {
            path: "workplacesFlow.description",
            inputName: "workplacesFlowFescription",
            label: {
              value: "workplacesFlows.entity.description",
              needsTranslate: true,
            },
            type: "textarea",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "workplacesFlow.minutes ",
            inputName: "workplacesFlowMinutes",
            label: {
              value: "workplacesFlows.entity.minutes",
              needsTranslate: true,
            },
            type: "number",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, isGreaterYup(0)];
            },
          },
          {
            path: "workplacesFlow.sequency ",
            inputName: "workplacesFlowSequency",
            label: {
              value: "workplacesFlows.entity.sequency",
              needsTranslate: true,
            },
            type: "number",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating
          ? "workplacesFlows.form.add"
          : "workplacesFlows.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(
        customFields,
        "workplacesFlow",
        "workplacesFlow"
      ),
    });
  }

  return form;
};
