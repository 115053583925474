import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createBlock as _createBlock, normalizeClass as _normalizeClass, isRef as _isRef, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic } from "vue"

const _hoisted_1 = { class: "row g-1" }
const _hoisted_2 = { class: "col-12 text-start" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row g-0" }
const _hoisted_6 = {
  key: 0,
  class: "col-auto my-auto text-start"
}
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]
const _hoisted_9 = ["name", "id", "placeholder", "aria-describedby", "disabled"]
const _hoisted_10 = ["type", "name", "id", "placeholder", "aria-describedby", "disabled", "step"]
const _hoisted_11 = ["id"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 1,
  class: "col-auto my-auto text-start"
}
const _hoisted_15 = ["id"]

import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import { SelectOptions } from "@/shared/globals/forms/interfaces/SelectOptions.interface";
import { FieldType } from "@/shared/globals/forms/types/FieldType.type";
import { IconType } from "@/shared/globals/forms/types/IconType.type";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { MetadataType } from "@/shared/globals/forms/types/MetadataType.type";
import { Dropdown } from "bootstrap";
import { merge } from "lodash";
import { useField } from "vee-validate";
import {
  computed,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ProcessText from "../UiTools/ProcessText.vue";
import ButtonField from "../buttons/ButtonField.vue";
import { eyeClosedButton } from "../buttons/templates/EyeClosedButton.template";
import { eyeOpenedButton } from "../buttons/templates/EyeOpenedButton.template";
import SelectMaster from "./SelectMaster.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputMaster',
  props: {
  inputName: {
    type: String,
    required: true,
  },
  path: {
    type: String,
    required: true,
  },
  label: {
    type: Object as PropType<LabelType>,
    required: false,
  },
  type: {
    type: String as PropType<FieldType>,
    required: true,
    default: "text" as FieldType,
  },
  icon: {
    type: Object as PropType<IconType>,
    required: false,
  },
  placeholder: {
    type: Object as PropType<LabelType>,
    required: false,
  },
  selectOptions: {
    type: Object as PropType<SelectOptions>,
    required: false,
  },
  buttons: {
    type: Array as PropType<Array<ButtonTemplate>>,
    required: false,
  },
  metadata: {
    type: Object as PropType<MetadataType>,
    required: false,
  },
  errorBag: {
    type: Object,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  reachableValue: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledDefaultOption: {
    type: Boolean,
    required: false,
  },
},
  emits: ["confirmValue"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;
const { t } = useI18n();
const dropdown: Ref<Dropdown> = ref(undefined);
const passwordType = ref("password");

const { value, meta, errors, setValue, ...propsField } = useField<string>(
  props.path
);
const eyeButtonTemplate = computed(() => {
  if (passwordType.value === "password") {
    return eyeOpenedButton(() => {
      passwordType.value = "text";
    });
  }
  return eyeClosedButton(() => {
    passwordType.value = "password";
  });
});

const inputType = computed(() => {
  if (props.type === "decimal") {
    return "number";
  }
  if (props.type === "password") {
    return passwordType.value;
  }
  return props.type;
});

function showDropdown(): void {
  if (
    props.errorBag &&
    props.errorBag[props.path] &&
    props.errorBag[props.path].length > 0
  ) {
    dropdown.value.show();
  }
}

function hideDropdown(): void {
  if (props.errorBag && props.errorBag[props.path]) {
    dropdown.value.hide();
  }
}

function emitValue() {
  if (props.reachableValue) {
    emit("confirmValue", value.value);
  }
}
const getInputClass = computed(() => {
  if (props.type == "checkbox") {
    return "form-check-input";
  }
  return "form-control";
});
const getPlaceholder = (): string => {
  if (!props.placeholder) return "";
  if (props.placeholder.needsTranslate) {
    return t(props.placeholder.value, props.placeholder.params);
  }
  return props.placeholder.value;
};

const mergedMetadata = computed(() => {
  const defaultMetadata = {
    tooltipPosition: "right",
    iconPosition: "end",
    labelPosition: "top",
    fieldSettings: {
      color: "blue",
    },
  };
  return merge({}, defaultMetadata, props.metadata);
});

const formButtons = computed(() => {
  return {
    startAppendButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "start" && button.metadata.isAppend
    ),
    endAppendButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "end" && button.metadata.isAppend
    ),
    startButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "start" && !button.metadata.isAppend
    ),
    endButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "end" && !button.metadata.isAppend
    ),
  };
});

const isValidClassComputed = computed(() => {
  if (
    props.errorBag &&
    ((errors.value.length > 0 && meta.validated) || value.value)
  ) {
    const isValid = props.errorBag[props.path]
      ? props.errorBag[props.path].every((e) => e.valid)
      : true;
    if (isValid) {
      return "is-valid";
    }
    return "is-invalid";
  }
  return "";
});

const windowWidth = ref();
window.addEventListener("resize", () => {
  windowWidth.value = window.innerWidth;
});

watch(value, () => {
  if (["select", "multiple-select", "date"].includes(props.type)) {
    emitValue();
  }
});

onMounted(() => {
  watch(
    windowWidth,
    () => {
      let tooltipPosition = mergedMetadata.value.tooltipPosition;
      if (
        windowWidth.value < 992 &&
        (tooltipPosition === "left" || tooltipPosition === "right")
      ) {
        tooltipPosition = "bottom";
      }
      dropdown.value = new Dropdown(`#${props.inputName}InputDropdown`, {
        display: "dynamic",
        popperConfig: function (defaultBsPopperConfig) {
          defaultBsPopperConfig.placement = tooltipPosition;
          return defaultBsPopperConfig;
        },
      });
    },
    { immediate: true }
  );
});

__expose({ value, meta, errors, setValue, ...propsField });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "container p-0",
    key: __props.path
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (mergedMetadata.value?.labelPosition === 'top' && props.label)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: props.inputName,
              class: "fw-medium fs-6"
            }, [
              _createVNode(ProcessText, {
                value: props.label
              }, null, 8, ["value"])
            ], 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (mergedMetadata.value?.labelPosition === 'left' && props.label)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", {
                  class: "fw-medium fs-6 pr-1",
                  id: `${props.inputName}GroupPrepend`
                }, [
                  _createVNode(ProcessText, {
                    value: props.label
                  }, null, 8, ["value"])
                ], 8, _hoisted_7)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.startButtons, (startButton, startButtonIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-auto",
              key: `startButton${props.inputName}-${startButtonIndex}`
            }, [
              (
                startButton.conditions ? startButton.conditions(_unref(value)) : true
              )
                ? (_openBlock(), _createBlock(ButtonField, {
                    key: 0,
                    "button-field": startButton
                  }, null, 8, ["button-field"]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          _createElementVNode("div", {
            class: _normalizeClass(`${props.type == 'checkbox' ? 'col-auto my-auto' : 'col'}`)
          }, [
            _createElementVNode("div", {
              id: `${props.inputName}InputDropdown`
            }, [
              _createElementVNode("div", {
                class: "d-flex justify-content-center align-items-center w-100 input-group",
                onMouseover: showDropdown,
                onMouseleave: hideDropdown
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.startAppendButtons, (startButton, startButtonIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `startAppendButton-${props.inputName}-${startButtonIndex}`
                  }, [
                    (
                      startButton.conditions
                        ? startButton.conditions(_unref(value))
                        : true
                    )
                      ? (_openBlock(), _createBlock(ButtonField, {
                          key: 0,
                          "button-field": startButton
                        }, null, 8, ["button-field"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128)),
                (
                    ['select', 'multiple-select'].includes(props.type) &&
                    props.selectOptions
                  )
                  ? (_openBlock(), _createBlock(SelectMaster, {
                      key: 0,
                      "data-source": props.selectOptions.dataSource,
                      "id-key": props.selectOptions.idKey,
                      "label-key": props.selectOptions.labelKey,
                      class: _normalizeClass(`${isValidClassComputed.value}`),
                      disabled: __props.disabled,
                      "is-multiple": props.selectOptions.isMultiple,
                      "initial-options": props.selectOptions.initialOptions,
                      "set-value": _unref(setValue),
                      value: _unref(value)
                    }, null, 8, ["data-source", "id-key", "label-key", "class", "disabled", "is-multiple", "initial-options", "set-value", "value"]))
                  : (props.type == 'textarea')
                    ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                        key: 1,
                        name: props.inputName,
                        class: _normalizeClass(`${getInputClass.value} ${isValidClassComputed.value} m-0`),
                        id: props.inputName,
                        placeholder: getPlaceholder(),
                        "aria-describedby": `${props.inputName}GroupPrepend ${props.inputName}Feedback`,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                        disabled: __props.disabled,
                        onKeyup: _withKeys(emitValue, ["enter"])
                      }, null, 42, _hoisted_9)), [
                        [_vModelText, _unref(value)]
                      ])
                    : _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 2,
                        type: inputType.value,
                        name: props.inputName,
                        class: _normalizeClass(`${getInputClass.value} ${isValidClassComputed.value} m-0`),
                        id: props.inputName,
                        placeholder: getPlaceholder(),
                        "aria-describedby": `${props.inputName}GroupPrepend ${props.inputName}Feedback`,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                        disabled: __props.disabled,
                        step: `${props.type === 'decimal' ? '.01' : ''}`,
                        onKeyup: _withKeys(emitValue, ["enter"])
                      }, null, 42, _hoisted_10)), [
                        [_vModelDynamic, _unref(value)]
                      ]),
                (__props.type === 'password')
                  ? (_openBlock(), _createBlock(ButtonField, {
                      key: 3,
                      "button-field": eyeButtonTemplate.value
                    }, null, 8, ["button-field"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.endAppendButtons, (endButton, endButtonIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `endAppendButton-${props.inputName}-${endButtonIndex}`
                  }, [
                    (
                      endButton.conditions ? endButton.conditions(_unref(value)) : true
                    )
                      ? (_openBlock(), _createBlock(ButtonField, {
                          key: 0,
                          "button-field": endButton
                        }, null, 8, ["button-field"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ], 32)
            ], 8, _hoisted_8),
            _createElementVNode("ul", {
              ref: props.inputName,
              class: "dropdown-menu",
              id: `${props.inputName}DropdownMenu`
            }, [
              (__props.errorBag && __props.errorBag[__props.path])
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.errorBag[__props.path], (error, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: `${error}+${index}+${props.label}`,
                        class: _normalizeClass(`mx-3 ${
                    error.valid ? 'text-success' : 'text-danger'
                  } text-nowrap`)
                      }, [
                        _createElementVNode("span", {
                          innerHTML: error.error
                        }, null, 8, _hoisted_13)
                      ], 2))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_11)
          ], 2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.endButtons, (endButton, endButtonIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-auto",
              key: `endButton${props.inputName}-${endButtonIndex}`
            }, [
              (endButton.conditions ? endButton.conditions(_unref(value)) : true)
                ? (_openBlock(), _createBlock(ButtonField, {
                    key: 0,
                    "button-field": endButton
                  }, null, 8, ["button-field"]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          (mergedMetadata.value?.labelPosition === 'right' && props.label)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("span", {
                  class: "fw-medium fs-6 pl-1",
                  id: `${props.inputName}GroupPrepend`
                }, [
                  _createVNode(ProcessText, {
                    value: props.label
                  }, null, 8, ["value"])
                ], 8, _hoisted_15)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})