<template>
  <GeneralCard class="containter-fluid m-0 p-0">
    <template v-slot:content>
      <div class="container-fluid p-0 m-0">
        <h6 class="text-start fw-medium fs-5">{{ $t(title) }}</h6>
        <div
          v-for="value of values"
          :key="value.topLabel"
          class="row d-flex justify-content-center align-items-center mb-3 mx-1"
        >
          <div
            :class="`col-auto iconCol textBlue bgBlueSoul ${
              props.graphicContainerCustomClass
                ? props.graphicContainerCustomClass
                : ''
            }`"
            :style="`background-color:${value.secondaryColor}`"
          >
            <i
              v-if="value.icon"
              :class="`${value.icon.icon!} ${value.icon.customClass}`"
              :style="`color:${value.primaryColor}`"
            ></i>
            <img
              v-else-if="props.image"
              :src="props.image.image"
              alt="cardPic"
              :class="`cardPic ${props.image.customClass}`"
            />
          </div>
          <div class="col">
            <div class="container-fluid">
              <div class="row">
                <div class="col labelCol textPale">
                  {{ $t(value.topLabel) }}
                </div>
              </div>
              <div class="row">
                <div
                  class="col-9 valueCol fs-1em"
                  :style="`color:${value.primaryColor}`"
                >
                  <TextFormatter
                    :value="String(value.value)"
                    :formatType="value.type"
                  ></TextFormatter>
                </div>
                <div class="col-auto"><slot name="extraValueSlot"></slot></div>
              </div>
            </div>
          </div>
          <OptionCard
            class="col-auto"
            v-if="value.options"
            :options="value.options"
          >
          </OptionCard>
        </div>
      </div>
    </template>
  </GeneralCard>
</template>

<script setup lang="ts">
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import { CardValue } from "@/shared/globals/cards/types/CardValue.type";
import { DefaultIcon } from "@/shared/globals/graphics/types/DefaultIcon.type";
import { DefaultImage } from "@/shared/globals/graphics/types/DefaultImage.type";
import TextFormatter from "../UiTools/TextFormatter.vue";
import OptionCard from "./OptionCard.vue";
import { defineProps, PropType } from "vue";
const props = defineProps({
  title: { type: String, required: true },
  image: { type: Object as PropType<DefaultImage>, required: false }, // ?
  values: { type: Array as PropType<CardValue[]>, required: true },
  graphicContainerCustomClass: { type: String, required: false },
  name: { type: String, required: true },
});
</script>

<style scoped lang="scss">
.iconCol {
  border-radius: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: fit-content;
  i {
    height: 24px;
    width: 24px;
    justify-content: center;
    display: flex;
    align-self: center;
    -webkit-text-stroke: 0.2px;
    font-size: 1.5em;
  }
}

.labelCol {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: start;
}

.valueCol {
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
}
</style>
