import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid p-0 header" }
const _hoisted_2 = { class: "navbar navbar-expand-lg p-0" }
const _hoisted_3 = { class: "container-fluid p-0" }
const _hoisted_4 = { class: "w-100" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col d-flex justify-content-between px-4 py-2" }
const _hoisted_7 = { class: "d-flex justify-content-start align-items-center" }
const _hoisted_8 = { class: "col d-flex justify-content-end align-items-center" }
const _hoisted_9 = { class: "d-flex" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col navigation" }
const _hoisted_12 = { class: "optionFullScreen" }
const _hoisted_13 = { class: "d-flex" }
const _hoisted_14 = {
  class: "optionSmallScreen",
  id: "accordionMenu"
}
const _hoisted_15 = { class: "col" }

import { useAuthServices } from "@/composables/useAuthServices";
import { SidebarElements } from "@/shared/dashboard/sidebar/const/ElementsMenu";
import { DropDownElementSubmenu } from "@/shared/globals/tooltips/types/DropDownElementsSubmenu";
import { Collapse } from "bootstrap";
import { onBeforeMount, ref, useTemplateRef, watch } from "vue";
import { useRouter } from "vue-router";
import HeaderOption from "./HeaderOption.vue";
import HeaderOptionSmall from "./HeaderOptionSmall.vue";
import LogoPicture from "./LogoPicture.vue";
import NotificationsDropdownMenu from "./NotificationsDropdownMenu.vue";
import UserDropdownMenu from "./UserDropdownMenu.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderViews',
  setup(__props) {

const {
  auth: {
    value: { user },
  },
  logout,
} = useAuthServices();
const router = useRouter();
const navbarRef = useTemplateRef<Element>("navbarRef" as never);
const headers = ref([]);

onBeforeMount(() => {
  watch(
    user,
    () => {
      headers.value = SidebarElements(user);
    },
    { deep: true, immediate: true }
  );
});

const closeNavbar = () => {
  const navbarTemp = new Collapse(navbarRef.value);
  navbarTemp.hide();
};

const callBack = () => {
  router.push({ name: "Main" });
};

const userDropDown: {
  userInfo: {
    fullName: string;
    profile: string;
    image?: string;
    width?: string;
  };
  links: DropDownElementSubmenu[];
} = {
  userInfo: {
    fullName: "Octavio Alcalde",
    profile: "Administrador",
    image: null,
    width: "2.5rem",
  },
  links: [
    {
      icon: { icon: "bi bi-person" },
      name: "Profile",
      callBack,
    },
    {
      icon: { icon: "bi bi-inbox" },
      name: "Inbox",
      callBack,
    },
    {
      icon: { icon: "bi bi-lock" },
      name: "Lock Screen",
      callBack,
    },
    {
      icon: { icon: "bi bi-box-arrow-right" },
      name: "Log Out",
      callBack: logout,
    },
  ],
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[0] || (_cache[0] = _createElementVNode("button", {
                  class: "navbar-toggler mr-2",
                  type: "button",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": "#navbarSupportedContent",
                  "aria-controls": "navbarSupportedContent",
                  "aria-expanded": "false",
                  "aria-label": "Toggle navigation"
                }, [
                  _createElementVNode("span", { class: "navbar-toggler-icon" })
                ], -1)),
                _createVNode(LogoPicture, { name: "QUICK PALLET" })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(NotificationsDropdownMenu, { class: "pr-2" }),
                  _createVNode(UserDropdownMenu, {
                    userInfo: userDropDown.userInfo,
                    links: userDropDown.links
                  }, null, 8, ["userInfo", "links"])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", {
                class: "collapse navbar-collapse",
                id: "navbarSupportedContent",
                ref_key: "navbarRef",
                ref: navbarRef
              }, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headers.value, (header) => {
                      return (_openBlock(), _createBlock(HeaderOption, {
                        key: header.name,
                        header: header
                      }, null, 8, ["header"]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headers.value, (header) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "row accordion",
                      key: header.name
                    }, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(HeaderOptionSmall, {
                          header: header,
                          closeNavbar: closeNavbar
                        }, null, 8, ["header"])
                      ])
                    ]))
                  }), 128))
                ])
              ], 512)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})