import axiosInstance from "@/middlewares/Routing.middleware";
import qs from "qs";
import { GetTableDataDto } from "../dtos/GetTableData.dto";

export async function GetTableData(url: string, payload: GetTableDataDto) {
  const options = {
    params: payload,
  };
  if (payload.filters) {
    options["paramsSerializer"] = (params) => {
      return qs.stringify(params, { arrayFormat: "repeat", encode: false });
    };
  }
  return await axiosInstance.get(url, options);
}
