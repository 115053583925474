import { Lang } from "../interfaces/lang.interface";

export const locales: Lang[] = [
  {
    name: "Español Mx",
    key: "es",
    image: "images/icon/langs/es.jpg",
  },
  {
    name: "English",
    key: "en",
    image: "images/icon/langs/en.jpg",
  },
];
