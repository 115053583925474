import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { customFieldToHeader } from "@/store/customFields/helpers/CustomFieldToHeader.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import { Division } from "@/store/divisions/models/Division.model";

export const DivisionsHeader = (
  runAction: (
    actionType: "edit" | "delete",
    division: Division,
    index: number
  ) => void,
  customFields: CustomFieldInterface[]
): TableHeader[] => {
  const customFieldsHeaders: TableHeader[] = customFieldToHeader(customFields);
  return [
    {
      sortable: true,
      value: { value: "divisions.entity.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "20px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "divisions.entity.name", needsTranslate: true },
      key: "name",
      mappedKey: "name",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "divisions.entity.createdAt", needsTranslate: true },
      key: "createdAt",
      mappedKey: "createdAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    {
      sortable: true,
      value: { value: "divisions.entity.updatedAt", needsTranslate: true },
      key: "updatedAt",
      mappedKey: "updatedAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    ...customFieldsHeaders,
    {
      sortable: true,
      value: { value: "divisions.table.actions", needsTranslate: true },
      columnType: "action",
      options: (division: Division, index: number): Menu => {
        const options = [
          {
            label: {
              value: "divisions.table.actionList.edit",
              needsTranslate: true,
            },
            id: 1,
            action: () => runAction("edit", division, index),
          },
          {
            label: {
              value: "divisions.table.actionList.delete",
              needsTranslate: true,
            },
            id: 2,
            action: () => runAction("delete", division, index),
          },
        ];
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
