<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="positionForm"
        />
      </div>
      <div class="col-12">
        <GeneralModal
          ref="modal"
          modalId="translateModal"
          :modalName="modalTitle"
          data-bs-backdrop="false"
          class="p-4"
        >
          <GeneralForm
            ref="translateForm"
            :form="formTranslateRef"
            form-name="translateForm"
            :key="modalTitle"
          ></GeneralForm>
        </GeneralModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { usePositionsServices } from "@/composables/usePositionsService";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { Position } from "@/store/positions/models/Position.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { get, merge, set } from "lodash";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { TranslateTemplate } from "../globals/templates/Translate.template";
import { CreateOrUpdatePositionTemplate } from "./templates/forms/CreateOrUpdatePositionTemplate.template";

const { customFields } = useCustomFieldsServices();
const { createPosition, updatePosition } = usePositionsServices();
const { t } = useI18n();

const props = defineProps({
  initialValues: { type: Object as PropType<Position>, required: false },
});

const initialValuesRef: Ref<Position> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle: Ref<string> = ref();
const translateForm = ref();
const formTranslateRef = ref();
const translates = ref({});

const emit = defineEmits(["handleSubmit"]);

const currentPosition = computed(() => {
  if (initialValuesRef.value) {
    const position = formatValues(initialValuesRef.value);
    return position ?? null;
  }
  return null;
});

async function handleTranslateSubmit() {
  const translateFormData = translateForm.value.values;
  translates.value = merge({}, translates.value, translateFormData);
  modal.value.closeModal();
}

async function handleTranslateButton(
  title: LabelType,
  path: string,
  type: "text" | "textarea"
) {
  formTranslateRef.value = TranslateTemplate(handleTranslateSubmit, path, type);
  modalTitle.value = title.needsTranslate ? t(title.value) : title.value;
  modal.value.openModal();

  const translate = merge(
    {},
    get(
      {
        position: { ...currentPosition.value },
      },
      path
    ),
    get(translates?.value, path)
  );
  if (translate) {
    const position = set({}, path, translate);
    await nextTick();
    translateForm.value?.setValues(position);
  }
}

function callBackCreate(position: Position) {
  emit("handleSubmit", { position, isCreating: true });
}

function callBackEdit(position: Position) {
  emit("handleSubmit", {
    position,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { values } = form.value;
  const { position } = merge({}, values, translates.value);
  if (!initialValuesRef.value) {
    await createPosition(position, callBackCreate);
  } else {
    await updatePosition(position, callBackEdit);
  }
}

function formatValues(values: Position) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    name: values.name,
    description: values.description,
    baseSalary: Number(values.baseSalary),
    customFields: customFieldsFiltered,
    metadata: values.metadata,
  };
}

function resetForms() {
  form.value?.resetForm();
  translateForm.value?.resetForm();
  translates.value = {};
  modal.value.closeModal();
}

watch(
  currentPosition,
  async () => {
    formTemplateRef.value = CreateOrUpdatePositionTemplate(
      customFields.value,
      handleSubmit,
      !currentPosition.value,
      handleTranslateButton
    );
    if (currentPosition.value) {
      await nextTick();
      form.value?.setValues({ position: currentPosition.value });
    }
  },
  { deep: true, immediate: true }
);

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
