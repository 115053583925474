<template>
  <div class="row mt-3">
    <div class="col-1"><hr /></div>
    <div class="col-auto p-0 dividerText d-flex align-items-center">
      <b class="m-0 fs-5">
        {{ t("technicalSheets.entity.preManufacturedProduct") }}
      </b>
    </div>
    <div class="col pl-1"><hr /></div>
  </div>
  <div
    class="row mt-3"
    v-for="(preManufacturedProduct, index) in preManufacturedProducts"
    :key="`preManufacturedProduct${index}`"
  >
    <div class="col">
      {{ preManufacturedProduct.preManufacturedProduct.label }}
    </div>
    <div class="col-auto p-0">
      <ButtonField
        :button-field="
          editButton(() => {
            handleAddOrEditPreManufacturedProduct(index);
          })
        "
      ></ButtonField>
    </div>
    <div class="col-auto pl-0">
      <ButtonField
        :button-field="
          deleteButton(() => {
            preManufacturedProducts.splice(index, 1);
          })
        "
      ></ButtonField>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center">
    <ButtonField
      :buttonField="addPreManufacturedProductButtonTemplate"
    ></ButtonField>
  </div>
  <GeneralModal
    ref="modal"
    modalId="translateModal"
    :modalName="modalTitle"
    data-bs-backdrop="false"
    class="p-4"
  >
    <GeneralForm
      ref="preManufacturedProductForm"
      :form="preManufacturedProductFormTemplateRef"
      form-name="translateForm"
      :key="modalTitle"
    ></GeneralForm>
  </GeneralModal>
</template>

<script lang="ts" setup>
import { merge } from "lodash";
import {
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { CreateOrUpdatePreManufacturedQuantityTemplate } from "./templates/forms/CreateOrUpdatePreManufacturedProductQuantity.template";
const { t } = useI18n();

type PreManufacturedProduct = {
  preManufacturedProduct: { id: number; label?: string };
  quantity: number;
};

const props = defineProps({
  preManufacturedProducts: {
    type: Object as PropType<PreManufacturedProduct[]>,
    required: false,
  },
});
const preManufacturedProducts: Ref<PreManufacturedProduct[]> = toRef(
  props,
  "preManufacturedProducts"
);
let currentPreManufacturedProductIndex = -1;
const modal = ref();
const modalTitle = ref();
const preManufacturedProductForm = ref();
const preManufacturedProductFormTemplateRef = ref();
const addPreManufacturedProductButtonTemplate = ref(
  addButton(async () => {
    currentPreManufacturedProductIndex = preManufacturedProducts.value.length;
    await handleAddOrEditPreManufacturedProduct(
      currentPreManufacturedProductIndex
    );
  })
);

async function handleSubmitPreManufacturedProduct() {
  const { values } = preManufacturedProductForm.value;
  preManufacturedProducts.value[currentPreManufacturedProductIndex] = merge(
    {},
    preManufacturedProducts.value[currentPreManufacturedProductIndex] ?? {},
    values
  );
  modal.value.closeModal();
}

async function handleAddOrEditPreManufacturedProduct(index: number) {
  const isCreating = preManufacturedProducts.value.length <= index;
  preManufacturedProductForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("technicalSheets.form.preManufacturedProduct.add")
    : t("technicalSheets.form.preManufacturedProduct.update");
  preManufacturedProductFormTemplateRef.value =
    CreateOrUpdatePreManufacturedQuantityTemplate(
      handleSubmitPreManufacturedProduct,
      isCreating
    );
  if (!isCreating) {
    await nextTick();
    preManufacturedProductForm.value.setValues(
      preManufacturedProducts.value[index]
    );
  }
  modal.value.openModal();
  currentPreManufacturedProductIndex = index;
}

function resetForms() {
  preManufacturedProducts.value = [];
  preManufacturedProductForm.value?.resetForm();
  modal.value.closeModal();
}

defineExpose({ resetForms });
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
