import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-auto pr-0" }
const _hoisted_4 = { class: "col pr-0 d-flex justify-content-start align-items-center" }
const _hoisted_5 = { class: "container-fluid" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col pr-0 d-flex justify-content-start align-items-center" }
const _hoisted_8 = { class: "m-0" }
const _hoisted_9 = {
  key: 0,
  class: "row"
}
const _hoisted_10 = { class: "col pr-0 d-flex justify-content-start align-items-center emailTag" }

import { UserTag } from "@/shared/globals/tags/types/UserTag.type";
import { computed, PropType } from "vue";
import ProfilePicture from "./ProfilePicture.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileTag',
  props: {
  userData: {
    type: Object as PropType<UserTag>,
    required: true,
  },
  showEmail: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const name = computed(() => {
  return `${props.userData.firstName} ${props.userData.lastName}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(ProfilePicture, {
          image: props.userData.profilePic,
          name: name.value,
          width: "2.5em"
        }, null, 8, ["image", "name"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(name.value), 1)
            ])
          ]),
          (props.showEmail)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(props.userData.email), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})