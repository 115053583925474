<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm ref="form" :form="formTemplateRef" form-name="usersForm" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { UserInterface } from "@/store/auth/interfaces/User.interface";
import { User } from "@/store/auth/models/User.model";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { cloneDeep, merge, shuffle } from "lodash";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { rulesAndChars } from "./consts/rulesAndChars.const";
import { CreateOrUpdateUserTemplate } from "./templates/forms/CreateOrUpdateUser.template";
const { customFields } = useCustomFieldsServices();
const { createUser, updateUser } = useUsersServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const props = defineProps({
  initialValues: { type: Object as PropType<UserInterface>, required: false },
  openPositionOffcanvas: {
    type: Object as PropType<() => void>,
    required: true,
  },
});

const initialValuesRef: Ref<UserInterface> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();

const emit = defineEmits(["handleSubmit"]);

const currentUser = computed(() => {
  if (initialValuesRef.value) {
    const user = formatValues(initialValuesRef.value);
    return user ?? null;
  }
  return null;
});

function callBackCreate(user: User) {
  emit("handleSubmit", { user, isCreating: true });
}

function callBackEdit(user: User) {
  emit("handleSubmit", {
    user,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { user } = form.value.values;
  const userFormatted = cloneDeep(user);
  userFormatted.status = { id: user.status.id };
  userFormatted.profile = { id: user.profile.id };
  userFormatted.positions = user.positions.map((position) => {
    return { id: position.id };
  });
  if (!initialValuesRef.value) {
    await createUser(userFormatted, callBackCreate);
  } else {
    await updateUser(userFormatted, callBackEdit);
  }
}

function formatValues(values: User) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    name: values.name,
    email: values.email,
    profile: values.profile,
    status: values.status,
    customFields: customFieldsFiltered ?? {},
    positions: values.positions,
  };
}

function resetForms() {
  form.value?.resetForm();
}

function getRandomChars({ count, chars }: { count: number; chars: string }) {
  const charsArray = Array.from(chars);
  return Array.from({ length: count }).reduce((acc) => {
    const index = Math.floor(Math.random() * charsArray.length);
    acc = acc + charsArray[index];
    return acc;
  }, "");
}

function generatePassword(): string {
  const password = shuffle(Object.keys(rulesAndChars)).reduce((acc, key) => {
    acc = acc + getRandomChars(rulesAndChars[key]);
    return acc;
  }, "");
  const { values } = form.value;
  const newUser = merge({}, values, { user: { password } });
  form.value?.setValues(newUser);
  return password;
}

onMounted(async () => {
  await getAllCatalogs();
  watch(
    [catalogs, currentUser],
    async () => {
      formTemplateRef.value = CreateOrUpdateUserTemplate(
        customFields.value,
        handleSubmit,
        !currentUser.value,
        catalogs.value,
        props.openPositionOffcanvas
      );
      if (currentUser.value) {
        await nextTick();
        form.value?.setValues({ user: currentUser.value });
      }
    },
    { deep: true, immediate: true }
  );
});

defineExpose({
  resetForms,
});
</script>

<style lang="scss"></style>
