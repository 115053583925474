<template>
  <div
    class="modal fade"
    :id="props.modalId"
    tabindex="-1"
    :aria-labelledby="`${props.modalId}Label`"
    aria-hidden="true"
    ref="modalRef"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`${props.modalId}Label`">
            {{ props.modalName }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="modalClosed"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Modal } from "bootstrap";
import { defineEmits, defineExpose, defineProps, ref } from "vue";

const props = defineProps({
  modalId: { type: String, required: true },
  modalName: { type: String, required: true },
});

const emit = defineEmits(["modalClosed"]);

const modalRef = ref();

function modalClosed() {
  emit("modalClosed");
}

function closeModal() {
  modalRef?.value && Modal.getOrCreateInstance(modalRef.value)?.hide();
}

function openModal() {
  modalRef?.value && Modal.getOrCreateInstance(modalRef.value)?.show();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style lang="scss"></style>
