<template>
  <div class="row">
    <div class="col-12">
      <GeneralForm ref="formMain" :form="formMainTemplate" formName="create">
        <div class="row">
          <div
            class="col-12 d-flex justify-content-center"
            v-if="['select', 'multiple-select'].includes(type)"
          >
            <ButtonField :buttonField="buttonAdd"></ButtonField>
          </div>
        </div>
      </GeneralForm>
    </div>
    <div class="col-12">
      <GeneralModal
        :modalId="`settingsModal${formMain?.formId}`"
        :modalName="$t('customFields.settings')"
        data-bs-backdrop="false"
        class="p-4"
      >
        <GeneralForm
          v-if="type === 'text'"
          :form="formTextSettingsTemplate"
          formName="settings"
          ref="settingsForm"
        >
        </GeneralForm>
        <GeneralForm
          v-if="type === 'number'"
          :form="formNumberSettingsTemplate"
          formName="settings"
          ref="settingsForm"
        >
        </GeneralForm>
        <GeneralForm
          v-if="type === 'date'"
          :form="formDateSettingsTemplate"
          formName="settings"
          ref="settingsForm"
        >
        </GeneralForm>
        <GeneralForm
          v-if="type === 'multiple-select'"
          :form="formSelectSettingsTemplate"
          formName="settings"
          ref="settingsForm"
        >
        </GeneralForm>
      </GeneralModal>
    </div>
    <div class="col-12">
      <GeneralModal
        modalId="translateModal"
        ref="translateModal"
        :modalName="`${$t('customFields.translates')}`"
        data-bs-backdrop="false"
        class="p-4"
      >
        {{ formTranslateTemplate }}
        <hr />
        <GeneralForm
          :form="formTranslateTemplate"
          :formName="`translatesForm${currentIndex}`"
          ref="translateForm"
        >
        </GeneralForm>
      </GeneralModal>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useValidations } from "@/composables/useValidations";
import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import {
  Form,
  FormSection,
} from "@/shared/globals/forms/interfaces/Form.interface";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import { Modal } from "bootstrap";
import { cloneDeep, get, merge } from "lodash";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { addNameTranslate } from "./templates/forms/AddNameTranslate.template";
import { addOptionTranslate } from "./templates/forms/AddOptionTranslate.template";
import { createOrUpdateCustomFieldTemplate } from "./templates/forms/CreateOrUpdateCustomField.template";
import { createOrUpdateDateSettings } from "./templates/forms/CreateOrUpdateDateSettings.template";
import { createOrUpdateNumberSettings } from "./templates/forms/CreateOrUpdateNumberSettings.template";
import {
  buttonDelete,
  buttonTranslate,
  createOrUpdateMultipleSelectSettings,
  fieldCreate,
} from "./templates/forms/CreateOrUpdateSelectSettings.template";
import { createOrUpdateTextSettings } from "./templates/forms/CreateOrUpdateTextSettings.template";

const validations = useValidations();
const { createCustomField, updateCustomField } = useCustomFieldsServices();
const { t } = useI18n();

const props = defineProps({
  initialValues: { type: Object, required: false },
  isCreating: { type: Boolean, required: true },
});
const emit = defineEmits(["customFieldSubmitted"]);

const formMain = ref();
const settingsForm = ref();
const translateForm = ref();
const formMainTemplate = ref(
  createOrUpdateCustomFieldTemplate(
    submitMain,
    formMain,
    validations,
    props.initialValues,
    props.isCreating,
    openSettings,
    openTranslate
  )
);
const formSelectSettingsTemplate = ref(
  createOrUpdateMultipleSelectSettings(submitSettings, validations)
);
const formTextSettingsTemplate = ref(
  createOrUpdateTextSettings(t, settingsForm, submitSettings)
);
const formNumberSettingsTemplate = ref(
  createOrUpdateNumberSettings(t, settingsForm, submitSettings)
);
const formDateSettingsTemplate = ref(
  createOrUpdateDateSettings(submitSettings, validations)
);
const formTranslateTemplate = ref();
const translateModal = ref();
const currentIndex = ref();

// DATA

const settingsFormTrick = computed(() => {
  return settingsForm?.value?.values ?? {};
});

const typeName = computed(() => {
  const type = props.initialValues?.customField?.type;
  if (type === "multiple-select") {
    return "multipleSelect";
  }
  return type;
});

const initialValuesMain = computed(() => {
  if (props.initialValues) {
    return {
      customField: {
        id: props.initialValues?.customField?.id,
        name: props.initialValues?.customField?.name,
        type: {
          value: props.initialValues?.customField?.type,
          name: typeName.value ? t(`customFields.types.${typeName.value}`) : "",
        },
        required: props.initialValues?.customField?.required,
        metadata: {
          options: props.initialValues?.customField?.metadata?.options,
        },
      },
    };
  }
  return null;
});

const initialValuesTranslates = computed(() => {
  const language = props.initialValues?.customField?.metadata?.language;
  if (language?.name || language?.options) {
    return {
      customField: {
        metadata: {
          language: {
            name: props.initialValues?.customField?.metadata?.language?.name,
            options:
              props.initialValues?.customField?.metadata?.language?.options ??
              [],
          },
        },
      },
    };
  }
  return null;
});

const customFieldTemp = computed(() => {
  const customField = merge(
    {},
    formMain.value?.values ?? {},
    translatesValues.value
  );
  return {
    ...customField.customField,
    type: customField.customField?.type?.id ?? "",
  };
});

const type = computed(() => {
  return customFieldTemp.value.type;
});

const translatesValues = ref(initialValuesTranslates.value ?? {});
const mainValues = ref(initialValuesMain.value ?? {});

// OPEN HANDLES

async function openTranslate() {
  formTranslateTemplate.value = addNameTranslate(submitTranslate);
  translateForm.value?.setValues(translatesValues.value);
  currentIndex.value = -1;
  Modal.getOrCreateInstance(translateModal.value.$el).show();
}

function openSettings() {
  const modalElement = document.getElementById(
    `settingsModal${formMain?.value?.formId}`
  );
  let modalInstance = Modal.getInstance(modalElement);
  if (!modalInstance) {
    modalInstance = new Modal(modalElement);
  }
  //settingsForm.value?.setValues(<COMPLETAR!!>);
  modalInstance?.show();
}

// SUBMIT HANDLES

async function submitSettings() {
  const modalElement = document.getElementById(
    `settingsModal${formMain?.value.formId}`
  );
  const modalInstance = Modal.getInstance(modalElement);
  modalInstance?.hide();
}

async function submitTranslate() {
  translatesValues.value = merge(
    {},
    translatesValues.value,
    cloneDeep(translateForm.value.values)
  );
  Modal.getInstance(translateModal.value.$el)?.hide();
}

async function submitMain() {
  mainValues.value = merge({}, mainValues.value, formMain.value.values);
  const customField: CustomFieldInterface = customFieldTemp.value;
  if (props.isCreating) {
    await createCustomField({
      customField,
      callBack: handleCustomFieldSubmit,
    });
  } else {
    await updateCustomField({
      customField,
      callBack: handleCustomFieldSubmit,
    });
  }
}

// OTHERS...

const buttonAdd: ButtonTemplate = addButton(() =>
  addOptionField(
    getSection(formMainTemplate, {
      value: "customFields.optionsValues",
      needsTranslate: true,
    })
  )
);

function handleCustomFieldSubmit() {
  if (props.isCreating) {
    resetForm();
  }
  emit("customFieldSubmitted");
}

function resetForm() {
  mainValues.value = {};
  translatesValues.value = {};
  formMain.value.resetAllFields();
}

function getSection(form: Ref<Form>, sectionName: LabelType): FormSection {
  return form.value.sections.find((e) => {
    return JSON.stringify(e.name) === JSON.stringify(sectionName);
  });
}

function getSectionIndex(form: Ref<Form>, sectionName: LabelType) {
  return form.value.sections.findIndex((e) => {
    return JSON.stringify(e.name) === JSON.stringify(sectionName);
  });
}

function deleteOptionsField(indexOptionField) {
  const inputName = `customFieldOption.${indexOptionField}`;
  const sectionIndex = getSectionIndex(formMainTemplate, {
    value: "customFields.optionsValues",
    needsTranslate: true,
  });
  if (sectionIndex > -1) {
    const fieldIndex = formMainTemplate.value.sections[
      sectionIndex
    ].fields.findIndex((f) => f.inputName === inputName);
    if (fieldIndex > -1) {
      formMainTemplate.value.sections[sectionIndex].fields[
        fieldIndex
      ].conditions = () => false;

      formMainTemplate.value.sections[sectionIndex].fields[
        fieldIndex
      ].conditions = () => false;

      const options = get(
        translatesValues.value,
        "customField.metadata.language.options",
        false
      );
      const name = get(
        translatesValues.value,
        "customField.metadata.language.name",
        false
      );
      if (options) {
        const newOptions = Object.entries(options).map((translate) => {
          const [lang, values] = translate;
          const newValues = Object.entries(values).filter(([key]) => {
            return key !== indexOptionField;
          });
          return [lang, Object.fromEntries(newValues)];
        });
        translatesValues.value = {
          customField: {
            metadata: {
              language: { options: Object.fromEntries(newOptions), name },
            },
          },
        };
      }
    }
  }
}

function addOptionField(
  section: FormSection,
  indexOptionField?: string,
  removable = true,
  propsField?
) {
  let lastIndex;
  if (indexOptionField) {
    lastIndex = indexOptionField.split("_")[1];
  } else {
    const lasField = section.fields.at(-1);
    lastIndex = lasField?.path.split("._")[1] ?? -1;
    lastIndex = Number(lastIndex) + 1;
    indexOptionField = `_${lastIndex}`;
  }

  let optionField: Field = fieldCreate(validations);
  optionField = {
    ...optionField,
    path: `${optionField.path}${indexOptionField}`,
    inputName: `${optionField.inputName}${indexOptionField}`,
    label: null,
    placeholder: {
      value: `${optionField.placeholder.value}`,
      params: { optionIndex: `${lastIndex}` },
      needsTranslate: true,
    },
    ...(propsField ? propsField : {}),
    buttons: [
      {
        ...buttonTranslate,
        action: async () => {
          currentIndex.value = indexOptionField;
          formTranslateTemplate.value = addOptionTranslate(
            submitTranslate,
            validations,
            indexOptionField
          );
          translateForm.value?.setValues(translatesValues.value);
          Modal.getOrCreateInstance(translateModal.value.$el).show();
        },
      },
      {
        ...buttonDelete,
        ...(!removable
          ? { customClass: `${buttonDelete.customClass} disabled` }
          : {}),
        action: () => {
          deleteOptionsField(indexOptionField);
        },
      },
    ],
  };
  section.fields.push(optionField);
}

function clearOptionsField() {
  const section = getSection(formMainTemplate, {
    value: "customFields.optionsValues",
    needsTranslate: true,
  });
  section.fields = [];
}

watch(
  settingsFormTrick,
  async () => {
    formTextSettingsTemplate.value = createOrUpdateTextSettings(
      t,
      settingsForm,
      submitSettings
    );
  },
  { immediate: true, deep: true }
);

onMounted(() => {
  clearOptionsField();

  if (initialValuesMain.value) {
    formMain.value?.setValues(initialValuesMain.value);
    if (
      initialValuesMain.value?.customField?.type?.value &&
      ["select", "multiple-select"].includes(
        initialValuesMain.value?.customField?.type?.value
      )
    ) {
      const options = Object.keys(
        props.initialValues?.customField.metadata?.options
      );
      options.forEach((option, index) => {
        addOptionField(
          getSection(formMainTemplate, {
            value: "customFields.optionsValues",
            needsTranslate: true,
          }),
          option,
          index !== 0
        );
      });
    }
  } else {
    addOptionField(
      getSection(formMainTemplate, {
        value: "customFields.optionsValues",
        needsTranslate: true,
      }),
      null,
      false
    );
  }
});

defineExpose({
  resetForm,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
