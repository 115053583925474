import { translateButton } from "@/components/globals/buttons/templates/TranslateButton.template";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import {
  minCharsYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { Permission } from "@/store/permissions/models/Permission.model";

export const CreateOrUpdateProfileTemplate = (
  action: () => void,
  isCreating: boolean,
  handleTranslateButton: (
    title: LabelType,
    path: string,
    type: "text" | "textarea"
  ) => void,
  permissions: Permission[]
): Form => {
  const { lang } = useLanguageServices();
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "profile.name",
            inputName: "profileName",
            label: {
              value: "profiles.entity.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(5)];
            },
            buttons: [
              {
                ...translateButton(() =>
                  handleTranslateButton(
                    { value: "profiles.entity.name", needsTranslate: true },
                    "profile.metadata.language.name",
                    "text"
                  )
                ),
              },
            ],
          },
          {
            path: "profile.description",
            inputName: "profileDescription",
            label: {
              value: "profiles.entity.description",
              needsTranslate: true,
            },
            type: "textarea",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
            buttons: [
              {
                ...translateButton(() =>
                  handleTranslateButton(
                    {
                      value: "profiles.entity.description",
                      needsTranslate: true,
                    },
                    "profile.metadata.language.description",
                    "text"
                  )
                ),
              },
            ],
          },
          {
            path: "profile.permissions",
            inputName: "profilePermissions",
            label: {
              value: "profiles.entity.permissions",
              needsTranslate: true,
            },
            type: "multiple-select",
            metadata: {
              tooltipPosition: "top",
            },
            selectOptions: {
              idKey: "id",
              labelKey: [`translates.name.[${lang.value}]`, "name"],
              isMultiple: true,
              initialOptions: permissions,
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "profiles.form.add" : "profiles.form.update",
        needsTranslate: true,
      },
      action,
    },
  };
  return form;
};
