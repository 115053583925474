import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import canvasChart from "@canvasjs/vue-charts";
import axiosInstance from "./middlewares/Routing.middleware";
import i18n from "./shared/locales/i18n";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "vue3-toastify/dist/index.css";

document.addEventListener("DOMContentLoaded", async () => {
  const vueApp = createApp(App);
  vueApp.config.globalProperties.$axios = axiosInstance;
  vueApp.use(store).use(router).use(i18n).use(canvasChart).mount("#app");
});
