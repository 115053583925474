import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";

export const createButton = (action): ButtonTemplate => ({
  backgroundColor: "#7451c2",
  customClass: "btn ml-1",
  graphic: {
    icon: {
      icon: "bi bi-plus-circle",
      color: "white",
      backgroundColor: "#7451c2",
    },
  },
  action,
  metadata: {
    isAppend: false,
    positionInField: "end",
  },
});
