<template>
  <div>
    <div
      class="offcanvas offcanvas-end"
      :style="`width: ${width}em !important; transform: translateX(-${
        childWidth / 2
      }em);`"
      tabindex="-1"
      :id="canvasId"
      :aria-labelledby="`${canvasId}Label`"
      :data-bs-backdrop="true"
      ref="offCanvasRef"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" :id="`${canvasId}Label`">
          {{ canvasName }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanvas"
        ></button>
      </div>
      <div class="offcanvas-body">
        <slot name="content"></slot>
        <div
          :class="`${
            isOpen && childIsOpen ? 'offcanvas-backdrop fade show' : ''
          }`"
        ></div>
      </div>
    </div>
    <div class="offcanvas-child" ref="childRef">
      <slot name="offcanvasChild"></slot>
    </div>
  </div>
  <div
    :class="`${isOpen && childIsOpen ? 'offcanvas-backdrop fade show' : ''}`"
  ></div>
</template>

<script setup lang="ts">
import { Offcanvas } from "bootstrap";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  Ref,
  ref,
} from "vue";

const props = defineProps({
  canvasId: { type: String, required: true },
  canvasName: { type: String, required: true },
});

const emit = defineEmits(["closed"]);

const canvas: Ref<Element> = ref(undefined);
const offCanvasRef = ref();
const childRef = ref();
const isOpen = ref(false);
const width = ref(30);

function closeOffcanvas() {
  emit("closed");
  isOpen.value = false;
}

onMounted(() => {
  canvas.value = document.getElementById(props.canvasId);
  canvas.value.addEventListener("hidden.bs.offcanvas", (event) => {
    emit("closed");
  });
});

function openOffCanvas() {
  if (offCanvasRef.value) {
    Offcanvas.getOrCreateInstance(offCanvasRef.value).show();
    isOpen.value = true;
  }
}

function closeOffCanvas() {
  if (offCanvasRef.value) {
    Offcanvas.getOrCreateInstance(offCanvasRef.value).hide();
    isOpen.value = false;
  }
}

const childWidth = computed(() => {
  const tempWidth =
    childRef.value?.firstElementChild?.__vueParentComponent?.exposed?.width
      ?.value;
  if (childIsOpen.value && tempWidth) {
    return tempWidth;
  }
  return 0;
});

const childIsOpen = computed(() => {
  return childRef.value?.firstElementChild?.__vueParentComponent?.exposed
    ?.isOpen?.value;
});

defineExpose({ isOpen, width, openOffCanvas, closeOffCanvas });
</script>

<style lang="scss" scoped>
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
