export const es = {
  language: "ES",
  name: "Español",
  global: {
    buttons: {
      confirm: "Confirmar",
      cancel: "Cancelar",
      save: "Guardar",
    },
    inputs: {
      ruleName: "Reglas",
      select: "Seleccione una opción",
      rules: {
        required: "Debe tener un valor",
        upperCaseLimited: "Debe contener al menos {quantity} letras mayusculas",
        lowerCaseLimited: "Debe contener al menos {quantity} letras minusculas",
        especialCharactersLimited:
          "Debe contener al menos {quantity} caracteres especiales",
        numberCharactersLimited:
          "Debe contener al menos {quantity} caracteres numericos",
        minQuantity: "Debe contener al menos {quantity} caracteres",
        numberCharacters: "Debe estar compuesta de caracteres numericos",
        email: "Debe ser una dirección de correo válida",
        onlyNumber: "Debe ser un número",
        positiveNumber: "Debe ser un número positivo",
        isOptional: "Es opcional",
        isLess: "Debe ser menor o igual que {num}",
        isGreater: "Debe ser mayor o igual que {num}",
        isGoogleMapsIframe: "Debe ser un iframe de Google Maps",
      },
      noOptions: "Lo sentimos, no hay opciones disponibles",
    },
    warnings: {
      delete: "Atención de eliminación",
    },
    tables: {
      results: "Resultados: ",
      resultsPerPage: "Resultados por página",
      emptyData:
        "Reajusta los filtros, no hay datos que correspondan a estos criterios",
      selectNull: "Seleccionar {entity}",
      showingPage: "Mostrando página {currentPage} de {totalPages}",
    },
    unknownError:
      "Ha ocurrido un error inesperado, por favor, contacta a soporte",
  },
  dashboard: {
    employee: "Empleado",
    earnings: "Ingresos",
    title: "Sistema para administración",
    statistics: "Estadísticas",
    customers: "CLIENTES",
    sales: "VENTAS",
    soldsDetails: "DETALLES DE VENTAS",
    thisWeek: "ESTA SEMANA",
    totalBalance: "Balance Total",
    lastTransactions: "ÚLTIMOS MOVIMIENTOS",
  },
  auth: {
    carouselTabs: {
      signUp: {
        primaryTitle: "ACCESO A QUICK PALLET",
        secondaryTitle: "INGRESA TU CORREO Y PASSWORD",
        hrefs: {
          validateEmail: "Deseas validar tu email?",
          recoveryPassword: "Has olvidado tu contraseña?",
        },
        anotherAccessOptions: "O continúa con",
        submit: "ENTRAR",
      },
      forgotPassword: {
        primaryTitle: "Recupera tu contraseña!",
        submit1: "RECUPERAR",
        submit2: "CAMBIAR",
        hrefs: {
          haveACodeYet: "Ya tengo un código!",
          notHaveACodeYet: "No tengo un código!",
          signUp: "Iniciar sesión",
        },
      },
      validateEmail: {
        primaryTitle: "Validá tu correo electrónico",
        hrefs: {
          haveACodeYet: "Ya tengo un código!",
          notHaveACodeYet: "No tengo un código!",
          signUp: "Iniciar sesión",
        },
        submit1: "ENVIAR",
        submit2: "VALIDAR",
      },
      changePassword: {
        primaryTitle: "Cambia tu contraseña",
      },
    },
    fields: {
      email: "Correo",
      password: "Contraseña",
      confirmationCode: "Código de confirmación",
      newPassword: "Nueva contraseña",
      oldPassword: "Contraseña anterior",
    },
    errors: {
      credentialsUnknown:
        "Correo electronico o contraseña incorrectos, intentalo de nuevo.",
      invalidValidationData: "Datos de validación incorrectos",
      incorrectForgotPassData:
        "Este correo no ha sido registrado, intenta con una direccion de correo valida.",
      incorrectValidateEmailData:
        "Este correo no ha sido registrado, intenta con una direccion de correo valida.",
      attemptsLimitExceeded:
        "Lo has intentado muchas veces, intenta de nuevo mas tarde.",
      expiredCode: "El codigo ha expirado, intenta solicitar uno nuevo",
      incorrectValidationData: "Datos incorrectos",
      isValidatedYet: "Este correo ya está validado",
    },
    success: {
      validation: "Validación exitosa",
      changePassword: "Se cambió la contraseña",
      resendCode: "Enviamos un codigo de confirmación a tu correo electronico!",
    },
    info: {
      needChangePassword:
        "Es tu primer acceso, es necesario cambiar la contraseña",
    },
  },
  accounts: {
    errors: {
      gettingAccount:
        "Ha ocurrido un error obteniendo la información de la cuenta, por favor, contacta a soporte.",
    },
  },
  users: {
    title: "Usuarios",
    entity: {
      id: "id",
      name: "Nombre",
      email: "Correo electrónico",
      password: "Contraseña",
      profile: "Perfil",
      createdAt: "Fecha Creación",
      updatedAt: "Fecha de Actualización",
      status: "Estado",
      positions: "Posiciones",
    },
    errors: {
      accessExpired: "Tu sesión ha expirado, vuelve a iniciar sesión.",
      unknownError:
        "Ha ocurrido un error inesperado al obtener el usuario, contacta a soporte",
      userAlreadyExists: "El usuario ya existe",
    },
    sucess: {
      userCreated: "Usuario creado",
      userUpdated: "Usuario actualizado",
      userDeleted: "Usuario borrado",
      statusUpdated: "Estado del usuario actualizado",
    },
    warnings: {
      status: "Atención de cambio de estado",
    },
    form: {
      createTitle: "Crear usuario",
      editTitle: "Editar usuario",
      add: "Agregar usuario",
      update: "Actualizar usuario",
    },
    table: {
      label: "Tabla de Usuarios",
      actions: "Acciones",
      actionList: {
        enable: "Activar",
        disable: "Desactivar",
        edit: "Editar",
        delete: "Eliminar",
      },
    },
    questionDelete:
      "¿Estás seguro de eliminar el usuario <strong>{name}</strong>?",
    questionEnable:
      "¿Estás seguro de activar al usuario <strong>{name}</strong>?",
    questionDisable:
      "¿Estás seguro de desactivar al usuario <strong>{name}</strong>?",
  },
  header: {
    navbar: {
      elements: {
        home: "INICIO",
        usersManagment: {
          name: "GESTIÓN DE USUARIOS",
          dropdowns: {
            users: "USUARIOS",
            profiles: "PERFILES",
            positions: "POSICIONES",
          },
        },
        crm: {
          name: "CRM",
          dropdowns: {
            customers: "CLIENTES",
            suppliers: "PROVEEDORES",
          },
        },
        productsManagment: {
          name: "GESTIÓN DE PRODUCTOS",
          dropdowns: {
            products: "PRODUCTOS",
            rawMaterials: "MATERIAS PRIMAS",
            productFamily: "FAMILIAS DE PROD.",
            measures: "MEDIDAS",
          },
        },
        operations: {
          name: "OPERACIONES",
          dropdowns: {
            orders: "PEDIDOS",
            shipping: "ENVÍOS",
            inventories: "INVENTARIOS",
          },
        },
        divisions: "DIVISIONES",
        workplace: "CENTRO DE TRABAJO",
        reports: "REPORTES",
        settings: "CONFIGURACIÓN",
      },
    },
  },
  catalogs: {
    USERS_STATUS_CLASS: "Estado de los usuarios",
    EMPLOYEES_STATUS_CLASS: "Estado de los empleados",
  },
  customFields: {
    title: "Campos personalizados",
    create: "Crear campo personalizado",
    name: "Nombre del campo",
    required: "Requerido",
    type: "Tipo de campo",
    types: {
      text: "Texto",
      number: "Número",
      select: "Selección única",
      multipleSelect: "Selección múltiple",
      date: "Fecha",
    },
    default: "Valor por defecto",
    regularExpression: "Expresión Regular",
    unitOfMeasurement: "Unidad de Medida",
    min: "Valor mínimo",
    max: "Valor máximo",
    negativeInfinity: "-Infinito",
    positiveInfinity: "+Infinito",
    selectionLimit: "Cantidad de selecciones simultáneas",
    values: "Ingrese valores",
    addField: "Agregar Campo",
    save: "Guardar",
    translates: "Traducciones del valor",
    settings: "Configuraciones",
    optionsValues: "Valores de opción",
    errors: {
      fieldAlreadyExists: "Campo personalizado ya exitente.",
    },
    sucess: {
      customFieldCreated: "Campo personalizado creado",
      customFieldUpdated: "Campo personalizado actualizado",
      customFieldDeleted: "Campo personalizado borrado",
    },
    yupRules: {
      isEmailYup: "email",
      minCharsYup: "carácteres mínimos",
      minSpecialCharsYup: "carácteres especiales mínimos",
      minNumbersYup: "números mínimos",
      minUpperCaseYup: "mayúsculas mínimas",
      minLowerCaseYup: "minúsculas mínimas",
      positiveYup: "positivo",
      integerYup: "entero",
      isGreaterYup: "mayor o igual que",
      isLessYup: "menor o igual que",
      isPositiveNumberYup: "positivo",
    },
    quantity: `Cantidad de {name}`,
    addOption: "Ingrese opción {optionIndex}",
    updateField: "Actualizar campo",
    questionDelete:
      "¿Estás seguro de eliminar el campo personalizado llamado <strong>{name}</strong>?",
    isCurrency: "Es una divisa",
    validations: "Validaciones",
  },
  profiles: {
    title: "Perfiles",
    entity: {
      id: "Id",
      name: "Nombre",
      description: "Descripción",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Edición",
      permissions: "Permisos",
    },
    table: {
      actions: "Acciones",
      actionList: {
        enable: "Activar",
        disable: "Desactivar",
        edit: "Editar",
        delete: "Eliminar",
      },
    },
    form: {
      createTitle: "Crear Perfil",
      editTitle: "Editar Perfil",
      add: "Agregar Perfil",
      update: "Actualizar Perfil",
      selectNewProfile: "Seleccione un nuevo perfil",
      deleteAndReplace: "Reemplazar y eliminar perfil",
    },
    sucess: {
      deletedAndReplaced: "Perfil borrado y reemplazado",
    },
  },
  measures: {
    title: "Medidas",
    entity: {
      id: "Id",
      name: "Nombre",
      description: "Descripción",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Actualización",
    },
    errors: {
      unknownError:
        "Ha ocurrido un error inesperado al obtener la medida, contacta a soporte",
      alreadyExists: "Las medidas ya existen",
    },
    sucess: {
      created: "Medida creada",
      updated: "Medida actualizada",
      deleted: "Medida borrada",
    },
    warnings: {
      status: "Atención de cambio de medidas",
    },
    form: {
      createTitle: "Crear medida",
      editTitle: "Editar medida",
      add: "Agregar medida",
      update: "Actualizar medida",
      deleteAndReplace: "Reemplazar y borrar medida",
      selectNewMeasure: "Seleccione una nueva medida",
    },
    tables: {
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    questionDelete:
      "¿Estás seguro de eliminar las medidas <strong>{name}</strong>?",
  },
  rawMaterials: {
    title: "Materias Primas",
    entity: {
      id: "Id",
      code: "Código",
      name: "Nombre",
      description: "Descripción",
      materialType: "Tipo de Material",
      unitCost: "Costo Unitario",
      quantityAvailable: "Cantidad Disponible",
      minimumStock: "Stock Mínimo",
      warehouseLocation: "Ubicación en Almacén",
      supplier: "Proveedor",
      measure: "Medida",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Actualización",
      status: "Estado",
    },
    errors: {
      unknownError:
        "Ha ocurrido un error inesperado al obtener la materia prima, contacta a soporte",
      alreadyExists: "La materia prima ya existe",
    },
    sucess: {
      created: "Materia prima creada",
      updated: "Materia prima actualizada",
      deleted: "Materia prima borrada",
    },
    warnings: {
      status: "Atención de cambio de materia prima",
    },
    form: {
      createTitle: "Crear materia prima",
      editTitle: "Editar materia prima",
      add: "Agregar materia prima",
      update: "Actualizar materia prima",
    },
    table: {
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    questionDelete:
      "¿Estás seguro de eliminar la materia prima <strong>{name}</strong>?",
  },
  productFamilies: {
    title: "Familia de productos",
    entity: {
      id: "Id",
      name: "Nombre",
      description: "Descripción",
      permissions: "Permisos",
      createdAt: "Fecha de Creación",
    },
    table: {
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    form: {
      createTitle: "Crear familia de producto",
      editTitle: "Editar familia de producto",
      add: "Agregar familia de producto",
      update: "Actualizar familia de producto",
      deleteAndReplace: "Reemplazar y eliminar",
      selectNewProductFamily: "Seleccione una nueva familia de producto",
    },
  },
  products: {
    title: "Productos",
    entity: {
      id: "Id",
      name: "Nombre",
      description: "Descripción",
      createdAt: "Fecha de Creación",
      measure: "Medida",
      productFamily: "Familia de producto",
      permissions: "Permisos",
    },
    table: {
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    form: {
      createTitle: "Crear Producto",
      editTitle: "Editar Producto",
      add: "Agregar Producto",
      update: "Actualizar Producto",
      technicalSheet: "¿Desea crear/modificar ficha técnica?",
      worplaceFlow: "¿Desea crear/modificar el flujo de centro de trabajo?",
    },
    sucess: {
      created: "Producto creado",
      updated: "Producto actualizado",
      deleted: "Producto eliminado",
    },
    questionDelete:
      "¿Estás seguro de eliminar el producto <strong>{name}</strong>?",
  },
  customers: {
    title: "Clientes",
    entity: {
      id: "Id",
      name: "Nombre",
      customerType: "Tipo de Cliente",
      businessType: "Tipo de Negocio",
      state: "Estado",
      city: "Ciudad",
      status: "Estatus",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Actualización",
    },
    table: {
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    form: {
      createTitle: "Crear Cliente",
      editTitle: "Editar Cliente",
      add: "Agregar Cliente",
      update: "Actualizar Cliente",
    },
    sucess: {
      created: "Cliente creado",
      updated: "Cliente actualizado",
      deleted: "Cliente eliminado",
    },
    questionDelete:
      "¿Estás seguro de eliminar el cliente <strong>{name}</strong>?",
  },
  suppliers: {
    title: "Proveedores",
    entity: {
      id: "Id",
      name: "Nombre",
      supplierType: "Tipo de proveedor",
      businessType: "Tipo de negocio",
      supplierState: "Estado",
      city: "Ciudad",
      status: "Estatus",
      createdAt: "Fecha de creación",
      updatedAt: "Fecha de actualización",
    },
    table: {
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    form: {
      createTitle: "Crear Proveedor",
      editTitle: "Editar Proveedor",
      add: "Agregar Proveedor",
      update: "Actualizar Proveedor",
      deleteAndReplace: "Reemplazar y eliminar",
      selectNewSupplier: "Seleccione un nuevo proveedor",
    },
    sucess: {
      created: "Proveedor creado",
      updated: "Proveedor actualizado",
      deleted: "Proveedor eliminado",
    },
  },
  positions: {
    title: "Posiciones",
    entity: {
      id: "Id",
      name: "Nombre",
      description: "Descripción",
      baseSalary: "Salario Base",
      createdAt: "Fecha de creación",
      updatedAt: "Fecha de actualización",
    },
    table: {
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    form: {
      createTitle: "Crear Posición",
      editTitle: "Editar Posición",
      add: "Agregar Posición",
      update: "Actualizar Posición",
    },
    sucess: {
      created: "Posición creada",
      updated: "Posición actualizada",
      deleted: "Posición eliminada",
    },
    questionDelete:
      "¿Estás seguro de eliminar la posición <strong>{name}</strong>?",
  },
  orders: {
    title: "Órdenes",
    entity: {
      id: "Id",
      orderDate: "Fecha de la Orden",
      customer: "Cliente",
      customerType: "Tipo de Cliente",
      status: "Estatus",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Actualización",
    },
    errors: {
      unknownError:
        "Ha ocurrido un error inesperado al obtener la orden, contacta a soporte",
      alreadyExists: "La orden ya existe",
    },
    success: {
      created: "Orden creada",
      updated: "Orden actualizada",
      deleted: "Orden borrada",
    },
    warnings: {
      status: "Atención con el estado de la orden",
    },
    form: {
      createTitle: "Crear orden",
      editTitle: "Editar orden",
      add: "Agregar orden",
      update: "Actualizar orden",
    },
    tables: {
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    questionDelete:
      "¿Estás seguro de eliminar la orden <strong>{name}</strong>?",
  },
  orderDetail: {
    title: "Detalle",
    entity: {
      product: "Producto",
      quantity: "Cantidad",
      total: "Total",
      discount: "Descuento",
    },
    form: {
      createTitle: "Crear detalle de orden",
      editTitle: "Actualizar detalle de orden",
      add: "Agregar detalle de orden",
      update: "Actualizar detalle de orden",
    },
  },
  divisions: {
    title: "Divisiones",
    entity: {
      id: "Id",
      name: "Nombre",
      location: "Localización",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Actualización",
    },
    table: {
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    form: {
      createTitle: "Crear División",
      editTitle: "Editar División",
      add: "Agregar División",
      update: "Actualizar División",
      selectNewDivision: "Seleccione una nueva división",
      deleteAndReplace: "Seleccionar y reemplazar",
    },
    sucess: {
      created: "División creada",
      updated: "División actualizada",
      deleted: "División eliminada",
    },
    errors: {
      sameEntity: "La nueva división no puede ser la misma",
    },
    questionDelete:
      "¿Estás seguro de eliminar la división <strong>{name}</strong>?",
  },
  workplaces: {
    title: "Centros de trabajos",
    entity: {
      id: "Id",
      name: "Nombre",
      description: "Descripción",
      status: "Estatus",
      division: "División",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Actualización",
    },
    table: {
      actions: "Acciones",
      actionList: {
        edit: "Editar",
        delete: "Borrar",
      },
    },
    form: {
      createTitle: "Crear Centro de Trabajo",
      editTitle: "Editar Centro de Trabajo",
      add: "Agregar Centro de Trabajo",
      update: "Actualizar Centro de Trabajo",
    },
    sucess: {
      created: "Centro de Trabajo creado",
      updated: "Centro de Trabajo actualizado",
      deleted: "Centro de Trabajo eliminado",
    },
    questionDelete:
      "¿Estás seguro de eliminar el centro de trabajo <strong>{name}</strong>?",
  },
  technicalSheets: {
    title: "Ficha Técnica",
    entity: {
      description: "Descripción",
      status: "Estatus",
      division: "División",
      createdAt: "Fecha de Creación",
      updatedAt: "Fecha de Actualización",
      rawMaterial: "Materia Prima",
      preManufacturedProduct: "Producto Semi-elaborado",
      quantity: "Cantidad",
    },
    form: {
      createTitle: "Crear Ficha Técnica",
      editTitle: "Editar Ficha Técnica",
      add: "Agregar Ficha Técnica",
      update: "Actualizar Ficha Técnica",
      rawMaterial: {
        add: "Agregar Materia Prima",
        update: "Actualizar Materia Prima",
      },
      preManufacturedProduct: {
        add: "Agregar Producto Semielaborado",
        update: "Actualizar Producto Semielaborado",
      },
    },
    sucess: {
      created: "Ficha Técnica creada",
      updated: "Ficha Técnica actualizada",
    },
  },
  workplacesFlows: {
    title: "Flujo de Centros de trabajos",
    entity: {
      workplace: "Centro de trabajo",
      description: "Descripción",
      minutes: "Minutos",
      sequency: "Número de secuencia",
    },
    form: {
      createTitle: "Crear/Editar Flujo de Centros de Trabajo",
      add: "Agregar Flujo de Centros de Trabajo",
    },
    sucess: {
      created: "Flujo de Centros de Trabajo creado",
      updated: "Flujo de Centros de Trabajo actualizado",
    },
    questionDelete:
      "¿Estás seguro de eliminar el flujo de centros de trabajo <strong>{name}</strong>?",
  },
};
