import { Module } from "vuex";
import { Position } from "./models/Position.model";

interface State {
  positions?: Partial<Position>[];
}

export const positionsStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    positions: [],
  },
  getters: {},
  mutations: {
    setPositions(state, positions: Partial<Position>[]) {
      state.positions = positions;
    },
    addPosition(state, position: Partial<Position>) {
      state.positions = [...state.positions, position];
    },
    updatePosition(state, position: Partial<Position>) {
      state.positions = state.positions.filter((p) => p.id !== position.id);
      state.positions = [...state.positions, position];
    },
    deletePosition(state, position: Partial<Position>) {
      state.positions = state.positions.filter((p) => p.id !== position.id);
    },
  },
  actions: {},
  modules: {},
};
