import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProcessText',
  props: {
  value: {
    type: Object as PropType<LabelType>,
    required: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (__props.value)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        innerHTML: 
      (__props.value.needsTranslate ? _ctx.$t(__props.value.value, __props.value.params) : __props.value.value) || ''
    
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})