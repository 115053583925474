import router from "@/router";
import { Module } from "vuex";
import { Permission } from "./models/Permission.model";

interface State {
  permissions: Permission[];
}

export const permissionsStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    permissions: [],
  },
  getters: {},
  mutations: {
    setPermissions(state, permissions: Permission[]) {
      state.permissions = permissions;
    },
  },
  actions: {
    launchExpiredSession(context) {
      context.commit("setLaunchExpiredSessionToast", true);
      router.push({ name: "Auth" });
    },
  },
  modules: {},
};
