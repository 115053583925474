import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-12" }

import { usePermissionsServices } from "@/composables/usePermissionsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { GetPermissions } from "@/store/permissions/services/Permission.service";
import { Profile } from "@/store/profiles/models/Profile.model";
import { get, merge, set } from "lodash";
import {
  computed,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { TranslateTemplate } from "../globals/templates/Translate.template";
import { CreateOrUpdateProfileTemplate } from "./templates/forms/CreateOrUpdateProfile.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateProfile',
  props: {
  initialValues: { type: Object as PropType<Profile>, required: false },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { permissions } = usePermissionsServices();
const { createProfile, updateProfile } = useProfilesServices();
const { t } = useI18n();

const props = __props;

const initialValuesRef: Ref<Profile> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle: Ref<string> = ref();
const translateForm = ref();
const formTranslateRef = ref();
const translates = ref({});
const emit = __emit;

const currentProfile = computed(() => {
  if (initialValuesRef.value) {
    const profile = formatValues(initialValuesRef.value);
    return profile ?? null;
  }
  return null;
});

async function handleTranslateSubmit() {
  const translateFormData = translateForm.value.values;
  translates.value = merge({}, translates.value, translateFormData);
  modal.value.closeModal();
}

async function handleTranslateButton(
  title: LabelType,
  path: string,
  type: "text" | "textarea"
) {
  formTranslateRef.value = TranslateTemplate(handleTranslateSubmit, path, type);
  modalTitle.value = title.needsTranslate ? t(title.value) : title.value;
  modal.value.openModal();

  const translate = merge(
    {},
    get(
      {
        profile: { ...currentProfile.value },
      },
      path
    ),
    get(translates?.value, path)
  );
  if (translate) {
    const profile = set({}, path, translate);
    await nextTick();
    translateForm.value?.setValues(profile);
  }
}

function callBackCreate(profile: Profile) {
  emit("handleSubmit", { profile, isCreating: true });
}

function callBackEdit(profile: Profile) {
  emit("handleSubmit", {
    profile,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { values } = form.value;
  const { profile } = merge({}, values, translates.value);
  const newProfile = {
    ...profile,
    permissions:
      profile?.permissions?.map((permission) => ({ id: permission.id })) ?? [],
  };
  if (!initialValuesRef.value) {
    await createProfile(newProfile, callBackCreate);
  } else {
    await updateProfile(newProfile, callBackEdit);
  }
}

function formatValues(values: Profile) {
  return {
    id: values.id,
    name: values.name,
    description: values.description,
    permissions: values?.permissions,
    metadata: values.metadata ?? {},
  };
}

function resetForms() {
  form.value?.resetForm();
  translateForm.value?.resetForm();
  translates.value = {};
  modal.value.closeModal();
}

onMounted(async () => {
  await GetPermissions();
  watch(
    [currentProfile, permissions],
    async () => {
      formTemplateRef.value = CreateOrUpdateProfileTemplate(
        handleSubmit,
        !currentProfile.value,
        handleTranslateButton,
        permissions.value
      );
      if (currentProfile.value) {
        await nextTick();
        form.value?.setValues({ profile: currentProfile.value });
      }
    },
    { deep: true, immediate: true }
  );
});

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues),
          "form-name": "profileForm"
        }, null, 8, ["form"]))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(GeneralModal, {
          ref_key: "modal",
          ref: modal,
          modalId: "translateModal",
          modalName: modalTitle.value,
          "data-bs-backdrop": "false",
          class: "p-4"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(GeneralForm, {
              ref_key: "translateForm",
              ref: translateForm,
              form: formTranslateRef.value,
              "form-name": "translateForm",
              key: modalTitle.value
            }, null, 8, ["form"]))
          ]),
          _: 1
        }, 8, ["modalName"])
      ])
    ])
  ]))
}
}

})