<template>
  <div
    class="profilePic"
    :style="{
      width: props.width,
      height: props.width,
    }"
  >
    <img :src="image" alt="profilePic" v-if="image" />
    <div class="profileName" v-else>
      {{ nameText }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
const props = defineProps({
  image: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: false,
  },
  width: {
    type: String,
    required: false,
  },
});
const nameText = computed(() => {
  if (!props.name) {
    return "";
  }
  const words = (props.name as string).toUpperCase().split(" ");
  if (words.length > 1) {
    return Array.from(words[0])[0] + Array.from(words[1])[0];
  } else {
    return Array.from(words[0])[0] + Array.from(words[0])[1];
  }
});
</script>

<style lang="scss" scoped>
.profilePic {
  border-radius: 100%;
  background-color: $accentColor;
  color: white;
  width: 3em;
  height: 3em;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
