<template>
  <div class="container p-0" :key="path">
    <div class="row g-1">
      <div class="col-12 text-start">
        <label
          v-if="mergedMetadata?.labelPosition === 'top' && props.label"
          :for="props.inputName"
          class="fw-medium fs-6"
        >
          <ProcessText :value="props.label"></ProcessText>
        </label>
      </div>
      <div class="col-12">
        <div class="row g-0">
          <div
            v-if="mergedMetadata?.labelPosition === 'left' && props.label"
            class="col-auto my-auto text-start"
          >
            <span
              class="fw-medium fs-6 pr-1"
              :id="`${props.inputName}GroupPrepend`"
            >
              <ProcessText :value="props.label"></ProcessText>
            </span>
          </div>
          <div
            class="col-auto"
            v-for="(startButton, startButtonIndex) in formButtons.startButtons"
            :key="`startButton${props.inputName}-${startButtonIndex}`"
          >
            <ButtonField
              v-if="
                startButton.conditions ? startButton.conditions(value) : true
              "
              :button-field="startButton"
            ></ButtonField>
          </div>
          <div
            :class="`${props.type == 'checkbox' ? 'col-auto my-auto' : 'col'}`"
          >
            <div :id="`${props.inputName}InputDropdown`">
              <div
                class="d-flex justify-content-center align-items-center w-100 input-group"
                @mouseover="showDropdown"
                @mouseleave="hideDropdown"
              >
                <div
                  v-for="(
                    startButton, startButtonIndex
                  ) in formButtons.startAppendButtons"
                  :key="`startAppendButton-${props.inputName}-${startButtonIndex}`"
                >
                  <ButtonField
                    v-if="
                      startButton.conditions
                        ? startButton.conditions(value)
                        : true
                    "
                    :button-field="startButton"
                  ></ButtonField>
                </div>
                <SelectMaster
                  v-if="
                    ['select', 'multiple-select'].includes(props.type) &&
                    props.selectOptions
                  "
                  :data-source="props.selectOptions.dataSource"
                  :id-key="props.selectOptions.idKey"
                  :label-key="props.selectOptions.labelKey"
                  :class="`${isValidClassComputed}`"
                  :disabled="disabled"
                  :is-multiple="props.selectOptions.isMultiple"
                  :initial-options="props.selectOptions.initialOptions"
                  :set-value="setValue"
                  :value="value"
                ></SelectMaster>
                <textarea
                  v-else-if="props.type == 'textarea'"
                  :name="props.inputName"
                  :class="`${getInputClass} ${isValidClassComputed} m-0`"
                  :id="props.inputName"
                  :placeholder="getPlaceholder()"
                  :aria-describedby="`${props.inputName}GroupPrepend ${props.inputName}Feedback`"
                  v-model="value"
                  :disabled="disabled"
                  @keyup.enter="emitValue"
                ></textarea>
                <input
                  v-else
                  :type="inputType"
                  :name="props.inputName"
                  :class="`${getInputClass} ${isValidClassComputed} m-0`"
                  :id="props.inputName"
                  :placeholder="getPlaceholder()"
                  :aria-describedby="`${props.inputName}GroupPrepend ${props.inputName}Feedback`"
                  v-model="value"
                  :disabled="disabled"
                  :step="`${props.type === 'decimal' ? '.01' : ''}`"
                  @keyup.enter="emitValue"
                />
                <ButtonField
                  v-if="type === 'password'"
                  :button-field="eyeButtonTemplate"
                ></ButtonField>
                <div
                  v-for="(
                    endButton, endButtonIndex
                  ) in formButtons.endAppendButtons"
                  :key="`endAppendButton-${props.inputName}-${endButtonIndex}`"
                >
                  <ButtonField
                    v-if="
                      endButton.conditions ? endButton.conditions(value) : true
                    "
                    :button-field="endButton"
                  ></ButtonField>
                </div>
              </div>
            </div>
            <ul
              :ref="props.inputName"
              class="dropdown-menu"
              :id="`${props.inputName}DropdownMenu`"
            >
              <div v-if="errorBag && errorBag[path]">
                <li
                  v-for="(error, index) in errorBag[path]"
                  :key="`${error}+${index}+${props.label}`"
                  :class="`mx-3 ${
                    error.valid ? 'text-success' : 'text-danger'
                  } text-nowrap`"
                >
                  <span v-html="error.error"></span>
                </li>
              </div>
            </ul>
          </div>
          <div
            class="col-auto"
            v-for="(endButton, endButtonIndex) in formButtons.endButtons"
            :key="`endButton${props.inputName}-${endButtonIndex}`"
          >
            <ButtonField
              v-if="endButton.conditions ? endButton.conditions(value) : true"
              :button-field="endButton"
            ></ButtonField>
          </div>
          <div
            v-if="mergedMetadata?.labelPosition === 'right' && props.label"
            class="col-auto my-auto text-start"
          >
            <span
              class="fw-medium fs-6 pl-1"
              :id="`${props.inputName}GroupPrepend`"
            >
              <ProcessText :value="props.label"></ProcessText>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import { SelectOptions } from "@/shared/globals/forms/interfaces/SelectOptions.interface";
import { FieldType } from "@/shared/globals/forms/types/FieldType.type";
import { IconType } from "@/shared/globals/forms/types/IconType.type";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { MetadataType } from "@/shared/globals/forms/types/MetadataType.type";
import { Dropdown } from "bootstrap";
import { merge } from "lodash";
import { useField } from "vee-validate";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ProcessText from "../UiTools/ProcessText.vue";
import ButtonField from "../buttons/ButtonField.vue";
import { eyeClosedButton } from "../buttons/templates/EyeClosedButton.template";
import { eyeOpenedButton } from "../buttons/templates/EyeOpenedButton.template";
import SelectMaster from "./SelectMaster.vue";

const props = defineProps({
  inputName: {
    type: String,
    required: true,
  },
  path: {
    type: String,
    required: true,
  },
  label: {
    type: Object as PropType<LabelType>,
    required: false,
  },
  type: {
    type: String as PropType<FieldType>,
    required: true,
    default: "text" as FieldType,
  },
  icon: {
    type: Object as PropType<IconType>,
    required: false,
  },
  placeholder: {
    type: Object as PropType<LabelType>,
    required: false,
  },
  selectOptions: {
    type: Object as PropType<SelectOptions>,
    required: false,
  },
  buttons: {
    type: Array as PropType<Array<ButtonTemplate>>,
    required: false,
  },
  metadata: {
    type: Object as PropType<MetadataType>,
    required: false,
  },
  errorBag: {
    type: Object,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  reachableValue: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledDefaultOption: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits(["confirmValue"]);
const { t } = useI18n();
const dropdown: Ref<Dropdown> = ref(undefined);
const passwordType = ref("password");

const { value, meta, errors, setValue, ...propsField } = useField<string>(
  props.path
);
const eyeButtonTemplate = computed(() => {
  if (passwordType.value === "password") {
    return eyeOpenedButton(() => {
      passwordType.value = "text";
    });
  }
  return eyeClosedButton(() => {
    passwordType.value = "password";
  });
});

const inputType = computed(() => {
  if (props.type === "decimal") {
    return "number";
  }
  if (props.type === "password") {
    return passwordType.value;
  }
  return props.type;
});

function showDropdown(): void {
  if (
    props.errorBag &&
    props.errorBag[props.path] &&
    props.errorBag[props.path].length > 0
  ) {
    dropdown.value.show();
  }
}

function hideDropdown(): void {
  if (props.errorBag && props.errorBag[props.path]) {
    dropdown.value.hide();
  }
}

function emitValue() {
  if (props.reachableValue) {
    emit("confirmValue", value.value);
  }
}
const getInputClass = computed(() => {
  if (props.type == "checkbox") {
    return "form-check-input";
  }
  return "form-control";
});
const getPlaceholder = (): string => {
  if (!props.placeholder) return "";
  if (props.placeholder.needsTranslate) {
    return t(props.placeholder.value, props.placeholder.params);
  }
  return props.placeholder.value;
};

const mergedMetadata = computed(() => {
  const defaultMetadata = {
    tooltipPosition: "right",
    iconPosition: "end",
    labelPosition: "top",
    fieldSettings: {
      color: "blue",
    },
  };
  return merge({}, defaultMetadata, props.metadata);
});

const formButtons = computed(() => {
  return {
    startAppendButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "start" && button.metadata.isAppend
    ),
    endAppendButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "end" && button.metadata.isAppend
    ),
    startButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "start" && !button.metadata.isAppend
    ),
    endButtons: props.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "end" && !button.metadata.isAppend
    ),
  };
});

const isValidClassComputed = computed(() => {
  if (
    props.errorBag &&
    ((errors.value.length > 0 && meta.validated) || value.value)
  ) {
    const isValid = props.errorBag[props.path]
      ? props.errorBag[props.path].every((e) => e.valid)
      : true;
    if (isValid) {
      return "is-valid";
    }
    return "is-invalid";
  }
  return "";
});

const windowWidth = ref();
window.addEventListener("resize", () => {
  windowWidth.value = window.innerWidth;
});

watch(value, () => {
  if (["select", "multiple-select", "date"].includes(props.type)) {
    emitValue();
  }
});

onMounted(() => {
  watch(
    windowWidth,
    () => {
      let tooltipPosition = mergedMetadata.value.tooltipPosition;
      if (
        windowWidth.value < 992 &&
        (tooltipPosition === "left" || tooltipPosition === "right")
      ) {
        tooltipPosition = "bottom";
      }
      dropdown.value = new Dropdown(`#${props.inputName}InputDropdown`, {
        display: "dynamic",
        popperConfig: function (defaultBsPopperConfig) {
          defaultBsPopperConfig.placement = tooltipPosition;
          return defaultBsPopperConfig;
        },
      });
    },
    { immediate: true }
  );
});

defineExpose({ value, meta, errors, setValue, ...propsField });
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.iconInsideInput {
  position: relative;
  left: -1.5em;
  top: 0.6em;
}

button:active {
  transform: scale(0.95);
}
</style>
