import { createButton } from "@/components/globals/buttons/templates/CreateButton.tempalte";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  isEmailYup,
  isEmpty,
  minCharsYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { CatalogClassesKeys } from "@/store/catalogs/const/CatalogClasses.const";
import { CatalogClass } from "@/store/catalogs/models/CatalogClass.model";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateUserTemplate = (
  customFields: CustomFieldInterface[],
  action,
  isCreating: boolean,
  catalogs: CatalogClass[],
  openPositionOffcanvas: () => void
): Form => {
  const { lang } = useLanguageServices();
  const catalog =
    catalogs.find((catalog) => {
      return catalog.key === CatalogClassesKeys.USERS_STATUS_CLASS;
    }).catalogs ?? [];
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "user.name",
            inputName: "userName",
            label: {
              value: "users.entity.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(8)];
            },
          },
          {
            path: "user.email",
            inputName: "userEmail",
            label: {
              value: "users.entity.email",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, isEmailYup];
            },
          },
          {
            path: "user.profile",
            inputName: "userProfile",
            label: {
              value: "users.entity.profile",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              dataSource: "/profiles/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
            },
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "user.status",
            inputName: "userStatus",
            label: {
              value: "users.entity.status",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              idKey: "id",
              labelKey: [`catalogMetadata.language.[${lang.value}]`, "name"],
              isMultiple: false,
              initialOptions: catalog,
            },
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "user.positions",
            inputName: "userPositions",
            label: {
              value: "users.entity.positions",
              needsTranslate: true,
            },
            type: "multiple-select",
            metadata: {
              tooltipPosition: "left",
            },
            selectOptions: {
              dataSource: "/positions/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: true,
            },
            methodsYup: () => {
              return [stringYup, isEmpty];
            },
            buttons: [createButton(openPositionOffcanvas)],
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "users.form.add" : "users.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "user", "user"),
    });
  }

  return form;
};
