<template>
  <div class="container">
    <div class="row">
      <div class="col-auto pr-0">
        <ProfilePicture
          :image="props.userData.profilePic"
          :name="name"
          width="2.5em"
        />
      </div>
      <div class="col pr-0 d-flex justify-content-start align-items-center">
        <div class="container-fluid">
          <div class="row">
            <div
              class="col pr-0 d-flex justify-content-start align-items-center"
            >
              <p class="m-0">{{ name }}</p>
            </div>
          </div>
          <div class="row" v-if="props.showEmail">
            <div
              class="col pr-0 d-flex justify-content-start align-items-center emailTag"
            >
              {{ props.userData.email }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UserTag } from "@/shared/globals/tags/types/UserTag.type";
import { computed, defineProps, PropType } from "vue";
import ProfilePicture from "./ProfilePicture.vue";

const props = defineProps({
  userData: {
    type: Object as PropType<UserTag>,
    required: true,
  },
  showEmail: {
    type: Boolean,
    default: false,
  },
});

const name = computed(() => {
  return `${props.userData.firstName} ${props.userData.lastName}`;
});
</script>

<style lang="scss" scoped>
.emailTag {
  font-size: 0.8em;
  color: $GrayPale;
}
</style>
