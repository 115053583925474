export const rulesAndChars = {
  upperCase: {
    count: 2,
    chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  },
  lowerCase: {
    count: 2,
    chars: "abcdefghijklmnopqrstuvwxyz",
  },
  numberCount: {
    count: 2,
    chars: "0123456789",
  },
  specialCount: {
    count: 1,
    chars: "@$!%*?&",
  },
  any: {
    count: 1,
    chars:
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$!%*?&",
  },
};
