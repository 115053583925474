import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container-fluid p-0 m-0" }
const _hoisted_2 = { class: "text-start fw-medium fs-5" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "container-fluid" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col labelCol textPale" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-auto" }

import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import { CardValue } from "@/shared/globals/cards/types/CardValue.type";
import { DefaultIcon } from "@/shared/globals/graphics/types/DefaultIcon.type";
import { DefaultImage } from "@/shared/globals/graphics/types/DefaultImage.type";
import TextFormatter from "../UiTools/TextFormatter.vue";
import OptionCard from "./OptionCard.vue";
import { PropType } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'CardLabel',
  props: {
  title: { type: String, required: true },
  image: { type: Object as PropType<DefaultImage>, required: false }, // ?
  values: { type: Array as PropType<CardValue[]>, required: true },
  graphicContainerCustomClass: { type: String, required: false },
  name: { type: String, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GeneralCard, { class: "containter-fluid m-0 p-0" }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t(__props.title)), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.values, (value) => {
          return (_openBlock(), _createElementBlock("div", {
            key: value.topLabel,
            class: "row d-flex justify-content-center align-items-center mb-3 mx-1"
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(`col-auto iconCol textBlue bgBlueSoul ${
              props.graphicContainerCustomClass
                ? props.graphicContainerCustomClass
                : ''
            }`),
              style: _normalizeStyle(`background-color:${value.secondaryColor}`)
            }, [
              (value.icon)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: _normalizeClass(`${value.icon.icon!} ${value.icon.customClass}`),
                    style: _normalizeStyle(`color:${value.primaryColor}`)
                  }, null, 6))
                : (props.image)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: props.image.image,
                      alt: "cardPic",
                      class: _normalizeClass(`cardPic ${props.image.customClass}`)
                    }, null, 10, _hoisted_3))
                  : _createCommentVNode("", true)
            ], 6),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t(value.topLabel)), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", {
                    class: "col-9 valueCol fs-1em",
                    style: _normalizeStyle(`color:${value.primaryColor}`)
                  }, [
                    _createVNode(TextFormatter, {
                      value: String(value.value),
                      formatType: value.type
                    }, null, 8, ["value", "formatType"])
                  ], 4),
                  _createElementVNode("div", _hoisted_9, [
                    _renderSlot(_ctx.$slots, "extraValueSlot")
                  ])
                ])
              ])
            ]),
            (value.options)
              ? (_openBlock(), _createBlock(OptionCard, {
                  key: 0,
                  class: "col-auto",
                  options: value.options
                }, null, 8, ["options"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _: 3
  }))
}
}

})