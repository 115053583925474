import { useYupRules } from "@/composables/useYupRules";

export function useValidations() {
  const yupRules = useYupRules();
  const {
    stringYup,
    requiredYup,
    numberYup,
    minCharsYup,
    isEmailYup,
    minSpecialCharsYup,
    minNumbersYup,
    minUpperCaseYup,
    minLowerCaseYup,
  } = yupRules;

  const emailValidation = [stringYup, requiredYup, isEmailYup];
  const passwordValidation = [
    stringYup,
    requiredYup,
    minUpperCaseYup(2),
    minLowerCaseYup(2),
    minNumbersYup(2),
    minSpecialCharsYup(1),
    minCharsYup(8),
  ];

  const cofirmationCodeValidation = [stringYup, requiredYup, minCharsYup(3)];
  return {
    emailValidation,
    passwordValidation,
    cofirmationCodeValidation,
    yupRules,
  };
}
