<template>
  <div class="nav-item dropdown" :ref="dropdownName">
    <a href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-bell fs-3"></i>
    </a>
    <ul
      class="dropdown-menu dropdown-menu-end"
      aria-labelledby="dropdownMenuLink"
    >
      <div class="mx-2">
        <NotificationItem></NotificationItem>
      </div>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, PropType, useTemplateRef } from "vue";
import { Dropdown } from "bootstrap";
import { DropDownElementSubmenu } from "@/shared/globals/tooltips/types/DropDownElementsSubmenu";
import NotificationItem from "./NotificationItem.vue";

const dropdownName = crypto.randomUUID();
</script>

<style lang="scss" scoped>
$colorSelected: #6b82ef;

a {
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  font-weight: 500;
}

.name {
  font-size: 15px;
  font-weight: 600;
}

.profile {
  color: $colorSelected;
  font-size: 14px;
  font-weight: 500;
}

.item {
  padding: 6px 14px;
  border-radius: 5px;
}

.item:hover {
  background-color: #ebedf2;
  color: $colorSelected;
}

.dropdown-menu {
  padding: 5px 10px;
  left: auto;
  right: 0;
}

i {
  height: 24px;
  width: 24px;
  -webkit-text-stroke: 0.3px;
  font-size: 1.2rem;
}
</style>
