import { CustomFieldInterface } from "../customFields/interfaces/CustomField.interface";
import { Module as ModuleInterface } from "./interfaces/Module.interface";

export const modulesStore = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    setCustomFields(state, module: ModuleInterface) {
      state[module.name] = module;
    },
    addCustomField(
      state,
      {
        moduleName,
        customField,
      }: { moduleName: string; customField: CustomFieldInterface }
    ) {
      state[moduleName].customFields = [
        ...state[moduleName].customFields,
        customField,
      ];
    },
    updateCustomField(
      state,
      {
        moduleName,
        customField,
      }: { moduleName: string; customField: CustomFieldInterface }
    ) {
      const customFieldIndex = state[moduleName].customFields.findIndex(
        (field) => field.id === customField.id
      );
      if (customFieldIndex !== -1) {
        state[moduleName].customFields[customFieldIndex] = customField;
      }
    },
    deleteCustomField(
      state,
      { id, moduleName }: { id: string; moduleName: string }
    ) {
      state[moduleName].customFields = state[moduleName].customFields.filter(
        (customField) => customField.id !== id
      );
    },
  },
  actions: {},
  modules: {},
};
