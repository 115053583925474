<template>
  <AuthCard>
    <template v-slot:content>
      <div class="row justify-content-center">
        <div class="col-auto d-flex align-items-center">
          <h2 class="textBlack">
            {{ $t("auth.carouselTabs.validateEmail.primaryTitle") }}
          </h2>
        </div>
      </div>
      <div class="row justify-content-between mt-2 mb-1">
        <div class="col-auto">
          <i class="bi bi-arrow-left-circle actionableLabel mr-1"></i>
          <span class="actionableLabel" @click="goToLogin">{{
            $t("auth.carouselTabs.validateEmail.hrefs.signUp")
          }}</span>
        </div>
        <div class="col-auto">
          <span
            class="actionableLabel"
            v-if="isFirstStep"
            @click="isFirstStep = false"
            >{{
              $t("auth.carouselTabs.validateEmail.hrefs.haveACodeYet")
            }}</span
          >
          <span v-else class="actionableLabel" @click="isFirstStep = true">{{
            $t("auth.carouselTabs.validateEmail.hrefs.notHaveACodeYet")
          }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col" v-if="isFirstStep">
          <GeneralForm
            ref="formRef"
            :form="formValidateEmailStep1"
            formName="validateEmail"
          ></GeneralForm>
        </div>
        <div class="col" v-else>
          <GeneralForm
            ref="formRef"
            :form="formValidateEmailStep2"
            formName="validateEmail"
          ></GeneralForm>
        </div>
      </div>
    </template>
  </AuthCard>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { useValidations } from "@/composables/useValidations";
import { defineEmits, ref } from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import AuthCard from "./AuthCard.vue";
import { validateEmailStep1 } from "./templates/forms/ValidateEmailStep1.template";
import { validateEmailStep2 } from "./templates/forms/ValidateEmailStep2.template";

const validations = useValidations();
const { validateEmail, resendValidateEmail } = useAuthServices();

const emit = defineEmits(["goToSlide"]);

const isFirstStep = ref<boolean>(true);
const formRef = ref();
const formValidateEmailStep1 = ref(
  validateEmailStep1(submitValidateEmail, validations)
);
const formValidateEmailStep2 = ref(
  validateEmailStep2(submitValidateEmail, validations)
);

function goToLogin(): void {
  formRef.value.resetForm({});
  emit("goToSlide", 1);
}

async function submitValidateEmail(): Promise<void> {
  const values = formRef.value.values;
  if (isFirstStep.value) {
    isFirstStep.value = await resendValidateEmail(values);
  } else {
    await validateEmail(values, goToLogin);
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#btnValidateEmail {
  background-color: $ThirdColor;
  border-color: $ThirdColor;
  color: white;
  &:hover {
    color: $ThirdColor;
    background-color: white;
  }
}
</style>
