import { Order } from "@/store/orders/models/Order.model";
import {
  CreateOrder,
  DeleteOrder,
  UpdateOrder,
} from "@/store/orders/services/Order.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastService";

export function useOrdersServices() {
  const { t } = useI18n();

  const createOrder = async (
    payload: Order,
    callBackCreate: (order: Order) => void
  ): Promise<void> => {
    await CreateOrder(payload)
      .then(async (order: Order) => {
        callBackCreate(order);
        useToast().successToast(t("orders.sucess.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const updateOrder = async (
    payload: Order,
    callBackUpdate: (order: Order) => void
  ): Promise<void> => {
    await UpdateOrder(payload)
      .then(async (order: Order) => {
        callBackUpdate(order);
        useToast().successToast(t("orders.sucess.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const deleteOrder = async (
    payload: Order,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteOrder(payload.id)
      .then(async () => {
        callBackDelete();
        useToast().successToast(t("orders.sucess.deleted"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  return {
    createOrder,
    updateOrder,
    deleteOrder,
  };
}
