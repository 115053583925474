<template>
  <GeneralForm
    v-if="deletionFormTemplate"
    :form="deletionFormTemplate"
    formName="deleteForm"
    ref="form"
  >
  </GeneralForm>
</template>

<script lang="ts" setup>
import {
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { DeleteProfileTemplate } from "./templates/forms/DeleteProfile.template";

const props = defineProps({
  handleSubmit: {
    type: Object as PropType<
      (values: { newId: { id: number; label: string } }) => void
    >,
    required: true,
  },
  currentRow: {
    type: Number,
    required: true,
  },
});

const deletionFormTemplate = ref();
const form = ref();
const currentRowRef: Ref<number> = toRef(props, "currentRow");

watch(
  [currentRowRef],
  async () => {
    deletionFormTemplate.value = DeleteProfileTemplate(async () => {
      await props.handleSubmit(form.value.values);
    }, props.currentRow);
    await nextTick();
    form.value?.setValues({ newId: "" });
  },
  { deep: true, immediate: true }
);

defineExpose({ ...form });
</script>

<style lang="scss" scoped>
::v-deep #btnLogin {
  background-color: red !important;
  border-color: red !important;
  color: white !important;
  &:hover {
    color: red !important;
    background-color: white !important;
  }
}
</style>
