export function formatNumber(number: number) {
  return new Intl.NumberFormat("en-US").format(number);
}

/**
 * TODO: Hay qeu hacer que el tipo de moneda llegue desde back como un catalogo, tambien la zona horaria y el idioma de establecido en la empresa.
 * @param number the number to format
 * @returns formatted number
 */
export function formatNumberCurrency(number: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
}
