import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-content-center" }

import { useAuthServices } from "@/composables/useAuthServices";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainView',
  setup(__props) {

const {
  auth: {
    value: { user },
  },
} = useAuthServices();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _cache[0] || (_cache[0] = _createTextVNode(" ¡Bienvenido ")),
      _createElementVNode("b", null, _toDisplayString(_unref(user).name), 1),
      _cache[1] || (_cache[1] = _createTextVNode(" a QP! "))
    ])
  ]))
}
}

})