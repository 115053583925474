import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  href: "#",
  role: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_2 = {
  class: "dropdown-menu dropdown-menu-end",
  "aria-labelledby": "dropdownMenuLink"
}
const _hoisted_3 = { class: "d-flex mt-3 border-bottom" }
const _hoisted_4 = { class: "flex-grow-1 text-nowrap" }
const _hoisted_5 = { class: "name mb-0" }
const _hoisted_6 = { class: "profile mb-2" }
const _hoisted_7 = ["onClick"]

import { PropType, useTemplateRef } from "vue";
import { Dropdown } from "bootstrap";
import { DropDownElementSubmenu } from "@/shared/globals/tooltips/types/DropDownElementsSubmenu";
import ProfilePicture from "../globals/profile/ProfilePicture.vue";

type UserInfo = {
  fullName: string;
  profile: string;
  image?: string;
  width?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'UserDropdownMenu',
  props: {
  userInfo: {
    type: Object as PropType<UserInfo>,
    required: true,
  },
  links: { type: Object as PropType<DropDownElementSubmenu[]>, required: true },
},
  setup(__props) {

const props = __props;

const dropdownName = crypto.randomUUID();
const dropDown = useTemplateRef<Element>(dropdownName as never);

function navigateTo(element: DropDownElementSubmenu): void {
  if (element.callBack) {
    element.callBack();
  }
  const dropDownTemp = new Dropdown(dropDown.value);
  dropDownTemp.hide();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "nav-item dropdown",
    ref: _unref(dropdownName)
  }, [
    _createElementVNode("a", _hoisted_1, [
      _createVNode(ProfilePicture, {
        name: props.userInfo.fullName,
        image: props.userInfo.image,
        width: props.userInfo.width
      }, null, 8, ["name", "image", "width"])
    ]),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "px-2 fs-5" }, "👋", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(props.userInfo.fullName), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(props.userInfo.profile), 1)
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.links, (element, index) => {
        return (_openBlock(), _createElementBlock("a", {
          key: element.name + index,
          onClick: ($event: any) => (navigateTo(element)),
          class: "m-0 text-start d-block item",
          href: "#"
        }, [
          (element.icon)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: _normalizeClass(`${element.icon.icon!} pr-1`)
              }, null, 2))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t(element.name)), 1)
        ], 8, _hoisted_7))
      }), 128))
    ])
  ], 512))
}
}

})