import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row d-flex align-items-center flex-nowrap w-100" }
const _hoisted_2 = { class: "col-auto p-0" }
const _hoisted_3 = { class: "col text-start text-nowrap" }
const _hoisted_4 = { class: "m-0 p-0" }
const _hoisted_5 = { class: "m-0 p-0" }

import { PropType, useTemplateRef } from "vue";
import { Dropdown } from "bootstrap";
import { DropDownElementSubmenu } from "@/shared/globals/tooltips/types/DropDownElementsSubmenu";
import ProfilePicture from "../globals/profile/ProfilePicture.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationItem',
  props: {},
  setup(__props) {

const props = __props;
const dropdownName = crypto.randomUUID();
const dropDown = useTemplateRef<Element>(dropdownName as never);

const userData = {
  image: null,
  name: "Kara Young",
  width: "2.5rem",
  time: "1 hr ago",
};

function closeDropdown(): void {
  const dropDownTemp = new Dropdown(dropDown.value);
  dropDownTemp.hide();
}

function navigateTo(element: DropDownElementSubmenu): void {
  if (element.callBack) {
    element.callBack();
  }
  closeDropdown();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(ProfilePicture, {
        image: userData.image,
        name: userData.name,
        width: userData.width
      }, null, 8, ["image", "name", "width"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(userData.name), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(userData.time), 1)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-1" }, [
      _createElementVNode("i", { class: "bi bi-x-lg" })
    ], -1))
  ]))
}
}

})