<template>
  <div class="row d-flex align-items-center flex-nowrap w-100">
    <div class="col-auto p-0">
      <ProfilePicture
        :image="userData.image"
        :name="userData.name"
        :width="userData.width"
      ></ProfilePicture>
    </div>
    <div class="col text-start text-nowrap">
      <p class="m-0 p-0">{{ userData.name }}</p>
      <p class="m-0 p-0">{{ userData.time }}</p>
    </div>
    <div class="col-1"><i class="bi bi-x-lg"></i></div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, PropType, useTemplateRef } from "vue";
import { Dropdown } from "bootstrap";
import { DropDownElementSubmenu } from "@/shared/globals/tooltips/types/DropDownElementsSubmenu";
import ProfilePicture from "../globals/profile/ProfilePicture.vue";

const props = defineProps({});
const dropdownName = crypto.randomUUID();
const dropDown = useTemplateRef<Element>(dropdownName as never);

const userData = {
  image: null,
  name: "Kara Young",
  width: "2.5rem",
  time: "1 hr ago",
};

function closeDropdown(): void {
  const dropDownTemp = new Dropdown(dropDown.value);
  dropDownTemp.hide();
}

function navigateTo(element: DropDownElementSubmenu): void {
  if (element.callBack) {
    element.callBack();
  }
  closeDropdown();
}
</script>

<style lang="scss" scoped></style>
