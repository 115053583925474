<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="workplacesFlowForm"
        >
          <div
            v-if="isValid"
            class="my-3"
            v-html="form?.values?.workplacesFlow?.location"
          ></div>
        </GeneralForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useWorkplacesFlowsServices } from "@/composables/useWorkplacesFlowService";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { WorkplacesFlow } from "@/store/workplaces-flow/models/WorkplacesFlow.model";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateWorkplacesFlowTemplate } from "./templates/forms/CreateOrUpdateWorkplaceFlow.template";

const { customFields } = useCustomFieldsServices();
const { createWorkplacesFlow, updateWorkplacesFlow } =
  useWorkplacesFlowsServices();

const props = defineProps({
  initialValues: { type: Object as PropType<WorkplacesFlow>, required: false },
});

const initialValuesRef: Ref<WorkplacesFlow> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const isValid = ref(false);
const emit = defineEmits(["handleSubmit"]);

onMounted(() => {
  watch(
    form?.value?.values,
    async () => {
      isValid.value = (await form?.value?.validateForm()) ?? false;
    },
    { deep: true }
  );
});

const currentWorkplacesFlow = computed(() => {
  if (initialValuesRef.value) {
    const workplacesFlow = formatValues(initialValuesRef.value);
    return workplacesFlow ?? null;
  }
  return null;
});

function callBackCreate(workplacesFlow: WorkplacesFlow) {
  emit("handleSubmit", { workplacesFlow, isCreating: true });
}

function callBackEdit(workplacesFlow: WorkplacesFlow) {
  emit("handleSubmit", {
    workplacesFlow,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { workplacesFlow } = form.value.values;
  if (!initialValuesRef.value) {
    await createWorkplacesFlow(workplacesFlow, callBackCreate);
  } else {
    await updateWorkplacesFlow(workplacesFlow, callBackEdit);
  }
}

function formatValues(values: WorkplacesFlow) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    description: values.description,
    workplace: { id: values.workplace.id, label: values.workplace.name },
    minutes: values.minutes,
    sequency: values.sequency,
    customFields: customFieldsFiltered,
  };
}

watch(
  currentWorkplacesFlow,
  async () => {
    formTemplateRef.value = CreateOrUpdateWorkplacesFlowTemplate(
      customFields.value,
      handleSubmit,
      !currentWorkplacesFlow.value
    );
    if (currentWorkplacesFlow.value) {
      await nextTick();
      form.value?.setValues({ workplacesFlow: currentWorkplacesFlow.value });
    }
  },
  { deep: true, immediate: true }
);

defineExpose({
  resetForms: () => form.value?.resetAllFields(),
});
</script>

<style lang="scss" scoped>
::v-deep iframe {
  width: 100%;
  height: 300px;
}
</style>
