import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "option d-flex align-items-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "dropdown-menu",
  "aria-labelledby": "dropdownMenuLink"
}
const _hoisted_4 = { class: "item" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }

import { RouterActionMenu } from "@/shared/dashboard/sidebar/types/ActionsSidebarElements.type";
import { SidebarElement } from "@/shared/dashboard/sidebar/types/SidebarElements.type";
import { Dropdown } from "bootstrap";
import { computed, PropType, useTemplateRef } from "vue";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderOption',
  props: {
  header: { type: Object as PropType<SidebarElement>, required: true },
},
  setup(__props) {

const props = __props;

const route = useRoute();
const router = useRouter();

const isActive = computed(() => {
  return props.header.submenu.some((element) => {
    return (element.action as RouterActionMenu).route === route.name;
  });
});

const dropDown = useTemplateRef<Element>(props.header.name as never);
function navigateTo(name: string): void {
  router.push({ name });
  const dropDownTemp = new Dropdown(dropDown.value);
  dropDownTemp.hide();
}

const optionStyles = [
  "text-nowrap",
  "link-item",
  "px-4",
  "py-1",
  "custom-item-style",
];

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.header.submenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "nav-item dropdown",
            ref: props.header.name
          }, [
            _createElementVNode("a", {
              href: "#",
              role: "button",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false",
              class: _normalizeClass([...optionStyles, { active: isActive.value }])
            }, _toDisplayString(_ctx.$t(props.header.i18nName)), 3),
            _createElementVNode("ul", _hoisted_3, [
              _createElementVNode("li", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.header.submenu, (element, index) => {
                  return (_openBlock(), _createElementBlock("a", {
                    onClick: ($event: any) => (navigateTo((element.action as RouterActionMenu).route)),
                    key: element.i18nName + index,
                    class: "m-0 text-start d-block rounded",
                    href: "#"
                  }, _toDisplayString(_ctx.$t(element.i18nName)), 9, _hoisted_5))
                }), 128))
              ])
            ])
          ], 512)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_router_link, {
            to: { name: props.header.action.route },
            class: _normalizeClass(optionStyles)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(props.header.i18nName)), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]))
  ]))
}
}

})