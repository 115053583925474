import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "profileName"
}

import { computed } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfilePicture',
  props: {
  image: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: false,
  },
  width: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;
const nameText = computed(() => {
  if (!props.name) {
    return "";
  }
  const words = (props.name as string).toUpperCase().split(" ");
  if (words.length > 1) {
    return Array.from(words[0])[0] + Array.from(words[1])[0];
  } else {
    return Array.from(words[0])[0] + Array.from(words[0])[1];
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "profilePic",
    style: _normalizeStyle({
      width: props.width,
      height: props.width,
    })
  }, [
    (__props.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: __props.image,
          alt: "profilePic"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(nameText.value), 1))
  ], 4))
}
}

})