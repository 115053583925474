import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";
import {
  formatNumber,
  formatNumberCurrency,
} from "@/shared/globals/helpers/Number.helper";
import { formatDate } from "@/shared/globals/helpers/Date.helper";


export default /*@__PURE__*/_defineComponent({
  __name: 'TextFormatter',
  props: {
  value: {
    type: String,
    required: true,
  },
  formatType: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const formatedText = computed(() => {
  if (props.formatType === "number") {
    return formatNumber(+props.value);
  } else if (props.formatType === "currency") {
    return formatNumberCurrency(+props.value);
  } else if (props.formatType === "date") {
    return formatDate(new Date(props.value));
  } else if (props.formatType === "percentage") {
    return `${props.value}%`;
  }
  return props.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, _toDisplayString(formatedText.value), 1))
}
}

})