import {
  isSameDay,
  isSameHour,
  isSameMinute,
  isSameMonth,
  isSameSecond,
  isSameWeek,
  isSameYear,
} from "date-fns";
import { DateUnit } from "../types/DateUnits.type";

type IsSameOperatorsMap = {
  [key in DateUnit]: (from: Date, to: Date) => boolean;
};

export const IS_SAME_OPERATORS: IsSameOperatorsMap = {
  year: isSameYear,
  month: isSameMonth,
  week: isSameWeek,
  day: isSameDay,
  hour: isSameHour,
  minute: isSameMinute,
  second: isSameSecond,
};
