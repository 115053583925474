import { useLanguageServices } from "@/composables/useLanguageServices";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { UserInterface } from "@/store/auth/interfaces/User.interface";
import { CatalogClassesKeys } from "@/store/catalogs/const/CatalogClasses.const";
import { CatalogClass } from "@/store/catalogs/models/CatalogClass.model";
import { customFieldToHeader } from "@/store/customFields/helpers/CustomFieldToHeader.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
export const UsersHeader = (
  optionEdit,
  optionDelete,
  optionDisable,
  optionEnable,
  customFields: CustomFieldInterface[],
  catalogs: CatalogClass[]
): TableHeader[] => {
  const customFieldsHeaders: TableHeader[] = customFieldToHeader(customFields);
  const { lang } = useLanguageServices();
  const catalog =
    catalogs.find((catalog) => {
      return catalog.key === CatalogClassesKeys.USERS_STATUS_CLASS;
    })?.catalogs ?? [];
  return [
    {
      sortable: true,
      value: { value: "users.entity.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "20px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "users.entity.name", needsTranslate: true },
      key: "name",
      mappedKey: "name",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "users.entity.email", needsTranslate: true },
      key: "email",
      mappedKey: "email",
      columnType: "text",
      width: "150px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "users.entity.profile", needsTranslate: true },
      key: "profile",
      mainKey: "profile",
      mappedKey: [`metadata.language.name.${lang.value}`, "name"],
      columnType: "tag",
      width: "150px",
      filterType: "select",
      selectOptions: {
        dataSource: "/profiles/",
        idKey: "id",
        labelKey: [`metadata.language.name.${lang.value}`, "name"],
        isMultiple: false,
      },
    },
    {
      sortable: true,
      value: { value: "users.entity.createdAt", needsTranslate: true },
      key: "createdAt",
      mappedKey: "createdAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    {
      sortable: true,
      value: { value: "users.entity.updatedAt", needsTranslate: true },
      key: "updatedAt",
      mappedKey: "updatedAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    {
      sortable: true,
      value: { value: "users.entity.status", needsTranslate: true },
      key: "status.id",
      mainKey: "status",
      mappedKey: [
        `status.catalogMetadata.language.[${lang.value}]`,
        "status.name",
      ],
      columnType: "status",
      width: "120px",
      filterType: "select",
      selectOptions: {
        idKey: "id",
        labelKey: [`catalogMetadata.language.[${lang.value}]`, "name"],
        isMultiple: false,
        initialOptions: catalog,
      },
    },
    {
      sortable: true,
      value: {
        value: "users.entity.positions",
        needsTranslate: true,
      },
      key: "positions.id",
      mainKey: "positions",
      mappedKey: [`metadata.language.name.${lang.value}`, "name"],
      columnType: "tag",
      width: "190px",
      filterType: "select",
      selectOptions: {
        dataSource: "/positions/",
        idKey: "id",
        labelKey: [`metadata.language.name.${lang.value}`, "name"],
        isMultiple: false,
      },
    },
    ...customFieldsHeaders,
    {
      sortable: true,
      value: { value: "users.table.actions", needsTranslate: true },
      columnType: "action",
      options: (user: UserInterface, index: number): Menu => {
        let options = [
          {
            label: {
              value: "users.table.actionList.edit",
              needsTranslate: true,
            },
            id: 1,
            action: () => optionEdit(user, index),
          },
          {
            label: {
              value: "users.table.actionList.delete",
              needsTranslate: true,
            },
            id: 2,
            action: () => optionDelete(user, index),
          },
        ];
        if (user.status.name === "active") {
          options = [
            ...options,
            {
              label: {
                value: "users.table.actionList.disable",
                needsTranslate: true,
              },
              id: 3,
              action: () => optionDisable(user, index),
            },
          ];
        } else {
          options = [
            ...options,
            {
              label: {
                value: "users.table.actionList.enable",
                needsTranslate: true,
              },
              id: 4,
              action: () => optionEnable(user, index),
            },
          ];
        }
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
