import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  minCharsYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateCustomerTemplate = (
  customFields: CustomFieldInterface[],
  action,
  isCreating: boolean
): Form => {
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "customer.name",
            inputName: "customerName",
            label: {
              value: "customers.entity.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(5)];
            },
          },
          {
            path: "customer.customerType",
            inputName: "customerCustomerType",
            label: {
              value: "customers.entity.customerType",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "customer.businessType",
            inputName: "customerBusinessType",
            label: {
              value: "customers.entity.businessType",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "customer.state",
            inputName: "customerState",
            label: {
              value: "customers.entity.state",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "customer.city",
            inputName: "customerCity",
            label: {
              value: "customers.entity.city",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "customers.form.add" : "customers.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "customer", "customer"),
    });
  }

  return form;
};
