import axiosInstance from "@/middlewares/Routing.middleware";
import { RawMaterial } from "../models/RawMaterial.model";

export async function CreateRawMaterial(
  payload: RawMaterial
): Promise<RawMaterial> {
  return (await axiosInstance.post(`raw-materials`, payload)).data;
}

export async function UpdateRawMaterial(
  payload: RawMaterial
): Promise<RawMaterial> {
  const { id, ...rawMaterialProps } = payload;
  return (await axiosInstance.put(`raw-materials/${id}`, rawMaterialProps))
    .data;
}

export async function DeleteRawMaterial(id: number): Promise<void> {
  await axiosInstance.delete(`raw-materials/${id}`);
}
