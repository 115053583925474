<template>
  <div class="option d-flex align-items-center">
    <div v-if="header.submenu">
      <div class="nav-item dropdown" :ref="props.header.name">
        <a
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          :class="[...optionStyles, { active: isActive }]"
        >
          {{ $t(props.header.i18nName) }}
        </a>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="item">
            <a
              @click="navigateTo((element.action as RouterActionMenu).route)"
              v-for="(element, index) of props.header.submenu"
              :key="element.i18nName + index"
              class="m-0 text-start d-block rounded"
              href="#"
            >
              {{ $t(element.i18nName) }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <router-link
        :to="{ name: props.header.action.route }"
        :class="optionStyles"
      >
        {{ $t(props.header.i18nName) }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { RouterActionMenu } from "@/shared/dashboard/sidebar/types/ActionsSidebarElements.type";
import { SidebarElement } from "@/shared/dashboard/sidebar/types/SidebarElements.type";
import { Dropdown } from "bootstrap";
import { computed, defineProps, PropType, useTemplateRef } from "vue";
import { useRoute, useRouter } from "vue-router";

const props = defineProps({
  header: { type: Object as PropType<SidebarElement>, required: true },
});

const route = useRoute();
const router = useRouter();

const isActive = computed(() => {
  return props.header.submenu.some((element) => {
    return (element.action as RouterActionMenu).route === route.name;
  });
});

const dropDown = useTemplateRef<Element>(props.header.name as never);
function navigateTo(name: string): void {
  router.push({ name });
  const dropDownTemp = new Dropdown(dropDown.value);
  dropDownTemp.hide();
}

const optionStyles = [
  "text-nowrap",
  "link-item",
  "px-4",
  "py-1",
  "custom-item-style",
];
</script>

<style lang="scss" scoped>
$heightNav: 51px;

.custom-item-style {
  border-right: 1px solid #515365;
  font-size: 14px;
  font-weight: 500;
  color: #d4e6ed;
}

a {
  text-decoration: none;
  color: inherit;
}

.dropdown-menu > * > a {
  color: #8e9db8;
  font-size: 14px;
  font-weight: 400;
}

.dropdown-menu > * > a:hover {
  background-color: #ebedf2;
  color: #4375ef;
}

.active,
.router-link-active {
  color: #3fcbd8;
}

.option {
  height: $heightNav;
}

.option:hover {
  .link-item {
    color: #3fcbd8;
    text-decoration: underline;
  }
}

.item {
  padding: 2px 10px;
}
</style>
