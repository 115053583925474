import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = ["innerHTML"]

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useWorkplacesFlowsServices } from "@/composables/useWorkplacesFlowService";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { WorkplacesFlow } from "@/store/workplaces-flow/models/WorkplacesFlow.model";
import {
  computed,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateWorkplacesFlowTemplate } from "./templates/forms/CreateOrUpdateWorkplaceFlow.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateWorkplacesFlow',
  props: {
  initialValues: { type: Object as PropType<WorkplacesFlow>, required: false },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createWorkplacesFlow, updateWorkplacesFlow } =
  useWorkplacesFlowsServices();

const props = __props;

const initialValuesRef: Ref<WorkplacesFlow> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const isValid = ref(false);
const emit = __emit;

onMounted(() => {
  watch(
    form?.value?.values,
    async () => {
      isValid.value = (await form?.value?.validateForm()) ?? false;
    },
    { deep: true }
  );
});

const currentWorkplacesFlow = computed(() => {
  if (initialValuesRef.value) {
    const workplacesFlow = formatValues(initialValuesRef.value);
    return workplacesFlow ?? null;
  }
  return null;
});

function callBackCreate(workplacesFlow: WorkplacesFlow) {
  emit("handleSubmit", { workplacesFlow, isCreating: true });
}

function callBackEdit(workplacesFlow: WorkplacesFlow) {
  emit("handleSubmit", {
    workplacesFlow,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { workplacesFlow } = form.value.values;
  if (!initialValuesRef.value) {
    await createWorkplacesFlow(workplacesFlow, callBackCreate);
  } else {
    await updateWorkplacesFlow(workplacesFlow, callBackEdit);
  }
}

function formatValues(values: WorkplacesFlow) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    description: values.description,
    workplace: { id: values.workplace.id, label: values.workplace.name },
    minutes: values.minutes,
    sequency: values.sequency,
    customFields: customFieldsFiltered,
  };
}

watch(
  currentWorkplacesFlow,
  async () => {
    formTemplateRef.value = CreateOrUpdateWorkplacesFlowTemplate(
      customFields.value,
      handleSubmit,
      !currentWorkplacesFlow.value
    );
    if (currentWorkplacesFlow.value) {
      await nextTick();
      form.value?.setValues({ workplacesFlow: currentWorkplacesFlow.value });
    }
  },
  { deep: true, immediate: true }
);

__expose({
  resetForms: () => form.value?.resetAllFields(),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues),
          "form-name": "workplacesFlowForm"
        }, {
          default: _withCtx(() => [
            (isValid.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "my-3",
                  innerHTML: form.value?.values?.workplacesFlow?.location
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["form"]))
      ])
    ])
  ]))
}
}

})