import axiosInstance from "@/middlewares/Routing.middleware";
import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { User } from "@/store/auth/models/User.model";
import { CreateOrUpdateUserInterface } from "../interfaces/CreateOrUpdateUserInterface.interface";

export async function GetUsers(payload: GetTableDataDto) {
  return await axiosInstance.get("/users/", { params: payload });
}

export async function CreateUser(
  payload: CreateOrUpdateUserInterface
): Promise<User> {
  return (await axiosInstance.post("/users/", payload)).data;
}

export async function UpdateUser(payload: User): Promise<User> {
  const { id, ...user } = payload;
  return (await axiosInstance.put(`/users/${id}`, user)).data;
}

export async function EnableUser(id: string): Promise<User> {
  return (await axiosInstance.put(`/users/${id}/enable`)).data;
}

export async function DisableUser(id: string): Promise<User> {
  return (await axiosInstance.put(`/users/${id}/disable`)).data;
}

export async function DeleteUser(id: string): Promise<void> {
  await axiosInstance.delete(`/users/${id}/delete`);
}
