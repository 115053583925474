import axiosInstance from "@/middlewares/Routing.middleware";
import { Customer } from "../models/Customer.model";

export async function GetAllCustomers(): Promise<Customer[]> {
  return (await axiosInstance.get(`customers/all`)).data;
}

export async function CreateCustomer(payload: Customer): Promise<Customer> {
  return (await axiosInstance.post(`customers`, payload)).data;
}

export async function UpdateCustomer(payload: Customer): Promise<Customer> {
  const { id, ...customerProps } = payload;
  return (await axiosInstance.put(`customers/${id}`, customerProps)).data;
}

export async function DeleteCustomer(id: number): Promise<void> {
  await axiosInstance.delete(`customers/${id}`);
}
