import { Workplace } from "@/store/workplaces/models/Workplace.model";
import {
  CreateWorkplace,
  DeleteWorkplace,
  UpdateWorkplace,
} from "@/store/workplaces/services/Workplace.service";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastService";

export function useWorkplacesServices() {
  const store = useStore();
  const { t } = useI18n();

  const createWorkplace = async (
    payload: Workplace,
    callBackCreate: (workplace: Workplace) => void
  ): Promise<void> => {
    await CreateWorkplace(payload)
      .then((workplace: Workplace) => {
        store.commit("workplaces/addWorkplace", workplace);
        callBackCreate(workplace);
        useToast().successToast(t("workplaces.sucess.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const updateWorkplace = async (
    payload: Workplace,
    callBackUpdate: (workplace: Workplace) => void
  ): Promise<void> => {
    await UpdateWorkplace(payload)
      .then((workplace: Workplace) => {
        store.commit("workplaces/updateWorkplace", payload);
        callBackUpdate(workplace);
        useToast().successToast(t("workplaces.sucess.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const deleteWorkplace = async (
    payload: Workplace,
    callBackDelete: (workplace: Workplace) => void
  ): Promise<void> => {
    await DeleteWorkplace(payload.id)
      .then(() => {
        callBackDelete(payload);
        store.commit("workplaces/deleteWorkplace", payload);
        useToast().successToast(t("workplaces.sucess.deleted"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  return {
    createWorkplace,
    updateWorkplace,
    deleteWorkplace,
  };
}
