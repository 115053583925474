import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center w-100" }
const _hoisted_2 = { class: "flex-grow-1 me-2 progressContainer" }
const _hoisted_3 = { class: "progress" }
const _hoisted_4 = ["aria-valuenow"]
const _hoisted_5 = { class: "porcentageText" }

import { computed } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'ProgressBar',
  props: {
  progress: { type: Number, required: true },
},
  setup(__props) {

const props = __props;
const progressText = computed(() => {
  return props.progress + "%";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "progress-bar barColor",
          role: "progressbar",
          style: _normalizeStyle(`width: ${progressText.value}`),
          "aria-valuenow": `${__props.progress}`,
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }, null, 12, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(progressText.value), 1)
  ]))
}
}

})