import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row mt-3" }
const _hoisted_2 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_3 = { class: "m-0 fs-5" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col-auto p-0" }
const _hoisted_6 = { class: "col-auto pl-0" }
const _hoisted_7 = { class: "col-12 d-flex justify-content-center" }

import { merge } from "lodash";
import {
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { CreateOrUpdatePreManufacturedQuantityTemplate } from "./templates/forms/CreateOrUpdatePreManufacturedProductQuantity.template";
type PreManufacturedProduct = {
  preManufacturedProduct: { id: number; label?: string };
  quantity: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdatePreManufacturedProductQuantity',
  props: {
  preManufacturedProducts: {
    type: Object as PropType<PreManufacturedProduct[]>,
    required: false,
  },
},
  setup(__props, { expose: __expose }) {

const { t } = useI18n();

const props = __props;
const preManufacturedProducts: Ref<PreManufacturedProduct[]> = toRef(
  props,
  "preManufacturedProducts"
);
let currentPreManufacturedProductIndex = -1;
const modal = ref();
const modalTitle = ref();
const preManufacturedProductForm = ref();
const preManufacturedProductFormTemplateRef = ref();
const addPreManufacturedProductButtonTemplate = ref(
  addButton(async () => {
    currentPreManufacturedProductIndex = preManufacturedProducts.value.length;
    await handleAddOrEditPreManufacturedProduct(
      currentPreManufacturedProductIndex
    );
  })
);

async function handleSubmitPreManufacturedProduct() {
  const { values } = preManufacturedProductForm.value;
  preManufacturedProducts.value[currentPreManufacturedProductIndex] = merge(
    {},
    preManufacturedProducts.value[currentPreManufacturedProductIndex] ?? {},
    values
  );
  modal.value.closeModal();
}

async function handleAddOrEditPreManufacturedProduct(index: number) {
  const isCreating = preManufacturedProducts.value.length <= index;
  preManufacturedProductForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("technicalSheets.form.preManufacturedProduct.add")
    : t("technicalSheets.form.preManufacturedProduct.update");
  preManufacturedProductFormTemplateRef.value =
    CreateOrUpdatePreManufacturedQuantityTemplate(
      handleSubmitPreManufacturedProduct,
      isCreating
    );
  if (!isCreating) {
    await nextTick();
    preManufacturedProductForm.value.setValues(
      preManufacturedProducts.value[index]
    );
  }
  modal.value.openModal();
  currentPreManufacturedProductIndex = index;
}

function resetForms() {
  preManufacturedProducts.value = [];
  preManufacturedProductForm.value?.resetForm();
  modal.value.closeModal();
}

__expose({ resetForms });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-1" }, [
        _createElementVNode("hr")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("b", _hoisted_3, _toDisplayString(_unref(t)("technicalSheets.entity.preManufacturedProduct")), 1)
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col pl-1" }, [
        _createElementVNode("hr")
      ], -1))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(preManufacturedProducts.value, (preManufacturedProduct, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "row mt-3",
        key: `preManufacturedProduct${index}`
      }, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(preManufacturedProduct.preManufacturedProduct.label), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(ButtonField, {
            "button-field": 
          _unref(editButton)(() => {
            handleAddOrEditPreManufacturedProduct(index);
          })
        
          }, null, 8, ["button-field"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(ButtonField, {
            "button-field": 
          _unref(deleteButton)(() => {
            preManufacturedProducts.value.splice(index, 1);
          })
        
          }, null, 8, ["button-field"])
        ])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(ButtonField, { buttonField: addPreManufacturedProductButtonTemplate.value }, null, 8, ["buttonField"])
    ]),
    _createVNode(GeneralModal, {
      ref_key: "modal",
      ref: modal,
      modalId: "translateModal",
      modalName: modalTitle.value,
      "data-bs-backdrop": "false",
      class: "p-4"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "preManufacturedProductForm",
          ref: preManufacturedProductForm,
          form: preManufacturedProductFormTemplateRef.value,
          "form-name": "translateForm",
          key: modalTitle.value
        }, null, 8, ["form"]))
      ]),
      _: 1
    }, 8, ["modalName"])
  ], 64))
}
}

})