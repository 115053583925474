import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { DeleteProfileTemplate } from "./templates/forms/DeleteProfile.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteProfile',
  props: {
  handleSubmit: {
    type: Object as PropType<
      (values: { newId: { id: number; label: string } }) => void
    >,
    required: true,
  },
  currentRow: {
    type: Number,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {

const props = __props;

const deletionFormTemplate = ref();
const form = ref();
const currentRowRef: Ref<number> = toRef(props, "currentRow");

watch(
  [currentRowRef],
  async () => {
    deletionFormTemplate.value = DeleteProfileTemplate(async () => {
      await props.handleSubmit(form.value.values);
    }, props.currentRow);
    await nextTick();
    form.value?.setValues({ newId: "" });
  },
  { deep: true, immediate: true }
);

__expose({ ...form });

return (_ctx: any,_cache: any) => {
  return (deletionFormTemplate.value)
    ? (_openBlock(), _createBlock(GeneralForm, {
        key: 0,
        form: deletionFormTemplate.value,
        formName: "deleteForm",
        ref_key: "form",
        ref: form
      }, null, 8, ["form"]))
    : _createCommentVNode("", true)
}
}

})