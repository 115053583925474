<template>
  <GeneralCard>
    <template v-slot:content>
      <div class="m-0 p-0 row h-100 w-100">
        <div class="p-0 m-0 containter-fluid mx-auto w-100 bar">
          <Bar :data="chartData" :options="chartOptions" />
        </div>
      </div>
    </template>
  </GeneralCard>
</template>

<script lang="ts" setup>
import GeneralCard from "../globals/cards/GeneralCard.vue";
import { Bar } from "vue-chartjs"; // https://www.chartjs.org/docs/latest/charts/bar.html
import { useI18n } from "vue-i18n";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

const { t } = useI18n();

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const chartData = {
  labels: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  datasets: [
    {
      label: "Direct",
      data: [58, 55, 43, 85, 42, 54, 58, 20, 35, 45, 58, 73],
      backgroundColor: "#42A5F5",
      barThickness: 20,
      borderRadius: 10,
      borderWidth: 2,
    },
    {
      label: "Organic",
      data: [42, 52, 64, 29, 54, 53, 54, 43, 56, 64, 62, 45],
      backgroundColor: "#FFA726",
      barThickness: 20,
      borderRadius: 10,
      borderWidth: 2,
    },
  ],
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      display: true,
      stacked: false,
    },
    y: {
      stacked: false,
    },
  },
  plugins: {
    title: {
      display: true,
      text: t("dashboard.sales"),
      font: {
        size: 20,
      },
    },
  },
};
</script>

<styles lang="scss">
.bar {
  height: 400px;
}
</styles>
