import { Module } from "vuex";
import { Product } from "./models/Product.model";

interface State {
  products?: Partial<Product>[];
}

export const productStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    products: [],
  },
  getters: {},
  mutations: {
    setProducts(state, products: Partial<Product>[]) {
      state.products = products;
    },
    addProduct(state, product: Partial<Product>) {
      state.products = [...state.products, product];
    },
    updateProduct(state, product: Partial<Product>) {
      const index = state.products.findIndex((p) => p.id === product.id);
      if (index !== -1) {
        state.products[index] = { ...state.products[index], ...product };
      } else {
        state.products.push({ ...product });
      }
    },
    deleteProduct(state, product: Partial<Product>) {
      state.products = state.products.filter((p) => p.id !== product.id);
    },
  },
  actions: {},
  modules: {},
};
