<template>
  <div class="container-fluid">
    <div class="row" v-if="formTemplateRef">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="technicalSheetForm"
        >
          <CreateOrUpdateRawMaterialQuantity :rawMaterials="rawMaterials" />
          <CreateOrUpdatePreManufacturedProductQuantity
            :pre-manufactured-products="preManufacturedProducts"
          />
        </GeneralForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useTechnicalSheetsServices } from "@/composables/useTechnicalSheetsService";
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { TechnicalSheet } from "@/store/technical-sheets/models/TechnicalSheet.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { merge } from "lodash";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import CreateOrUpdatePreManufacturedProductQuantity from "./CreateOrUpdatePreManufacturedProductQuantity.vue";
import CreateOrUpdateRawMaterialQuantity from "./CreateOrUpdateRawMaterialQuantity.vue";
import { CreateOrUpdateTechnicalSheetTemplate } from "./templates/forms/CreateOrUpdateTechnicalSheet.template";

type RawMaterialQuantity = {
  rawMaterial: { id: number; label?: string };
  quantity: number;
};

type PreManufacturedProduct = {
  preManufacturedProduct: { id: number; label?: string };
  quantity: number;
};

const { customFields } = useCustomFieldsServices();
const { createTechnicalSheet, updateTechnicalSheet } =
  useTechnicalSheetsServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const props = defineProps({
  initialValues: { type: Object as PropType<TechnicalSheet>, required: false },
});

const initialValuesRef: Ref<TechnicalSheet> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const rawMaterials: Ref<RawMaterialQuantity[]> = ref([]);
const preManufacturedProducts: Ref<PreManufacturedProduct[]> = ref([]);

const emit = defineEmits(["handleSubmit"]);

const currentTechnicalSheet: Ref<Partial<TechnicalSheet> | null> = computed(
  () => {
    if (initialValuesRef.value) {
      const technicalSheet = formatValues(initialValuesRef.value);

      return technicalSheet ?? null;
    }
    return null;
  }
);

function callBackCreate(technicalSheet: TechnicalSheet) {
  emit("handleSubmit", { technicalSheet, isCreating: true });
}

function callBackEdit(technicalSheet: TechnicalSheet) {
  emit("handleSubmit", {
    technicalSheet,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { values } = form.value;
  const { technicalSheet } = merge({}, values, rawMaterials.value);
  const technicalSheetFormatted = {
    ...technicalSheet,
  };
  technicalSheetFormatted.rawMaterials = rawMaterials.value.map(
    (rawMaterial) => ({
      id: rawMaterial.rawMaterial.id,
      quantity: rawMaterial.quantity,
    })
  );
  if (!initialValuesRef.value) {
    await createTechnicalSheet(technicalSheetFormatted, callBackCreate);
  } else {
    await updateTechnicalSheet(technicalSheetFormatted, callBackEdit);
  }
}

function formatValues(values: TechnicalSheet) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    rawMaterial: values.rawMaterials,
    preManufactured: values.preManufacturedProducts,
    customFields: customFieldsFiltered,
  };
}

function resetForms() {
  form.value?.resetForm();
}

onMounted(async () => {
  await Promise.all([getAllCatalogs()]);
  watch(
    [currentTechnicalSheet, catalogs, customFields],
    async () => {
      if (customFields.value && catalogs.value) {
        formTemplateRef.value = CreateOrUpdateTechnicalSheetTemplate(
          customFields.value,
          handleSubmit,
          !currentTechnicalSheet.value
        );
        if (currentTechnicalSheet.value) {
          await nextTick();
          form.value?.setValues({
            technicalSheet: currentTechnicalSheet.value,
          });
          rawMaterials.value =
            initialValuesRef.value?.rawMaterials?.map((rawMaterial) => {
              return {
                rawMaterial: {
                  id: rawMaterial.rawMaterial.id,
                  label: translateFromKey(rawMaterial.rawMaterial, "name"),
                },
                quantity: rawMaterial.quantity,
              };
            }) ?? [];
        }
      }
    },
    { deep: true, immediate: true }
  );
});

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
