<template>
  <div class="row mt-3">
    <div class="col-1"><hr /></div>
    <div class="col-auto p-0 dividerText d-flex align-items-center">
      <b class="m-0 fs-5">
        {{ t("technicalSheets.entity.rawMaterial") }}
      </b>
    </div>
    <div class="col pl-1"><hr /></div>
  </div>
  <div
    class="row mt-3"
    v-for="(rawMaterial, index) in rawMaterials"
    :key="`rawMaterial${index}`"
  >
    <div class="col">
      {{ rawMaterial.rawMaterial.label }}
    </div>
    <div class="col-auto p-0">
      <ButtonField
        :button-field="
          editButton(() => {
            handleAddOrEditRawMaterial(index);
          })
        "
      ></ButtonField>
    </div>
    <div class="col-auto pl-0">
      <ButtonField
        :button-field="
          deleteButton(() => {
            rawMaterials.splice(index, 1);
          })
        "
      ></ButtonField>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center">
    <ButtonField :buttonField="addRawMaterialButtonTemplate"></ButtonField>
  </div>
  <GeneralModal
    ref="modal"
    modalId="translateModal"
    :modalName="modalTitle"
    data-bs-backdrop="false"
    class="p-4"
  >
    <GeneralForm
      ref="rawMaterialForm"
      :form="rawMaterialFormTemplateRef"
      form-name="translateForm"
      :key="modalTitle"
    ></GeneralForm>
  </GeneralModal>
</template>

<script lang="ts" setup>
import { merge } from "lodash";
import {
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { CreateOrUpdateRawMaterialQuantityTemplate } from "./templates/forms/CreateOrUpdateRawMaterialQuantity.template";
const { t } = useI18n();

type RawMaterialQuantity = {
  rawMaterial: { id: number; label?: string };
  quantity: number;
};

const props = defineProps({
  rawMaterials: {
    type: Object as PropType<RawMaterialQuantity[]>,
    required: false,
  },
});
const rawMaterials: Ref<RawMaterialQuantity[]> = toRef(props, "rawMaterials");
let currentRawMaterialIndex = -1;
const modal = ref();
const modalTitle = ref();
const rawMaterialForm = ref();
const rawMaterialFormTemplateRef = ref();
const addRawMaterialButtonTemplate = ref(
  addButton(async () => {
    currentRawMaterialIndex = rawMaterials.value.length;
    await handleAddOrEditRawMaterial(currentRawMaterialIndex);
  })
);

async function handleSubmitRawMaterial() {
  const { values } = rawMaterialForm.value;
  rawMaterials.value[currentRawMaterialIndex] = merge(
    {},
    rawMaterials.value[currentRawMaterialIndex] ?? {},
    values
  );
  modal.value.closeModal();
}

async function handleAddOrEditRawMaterial(index: number) {
  const isCreating = rawMaterials.value.length <= index;
  rawMaterialForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("technicalSheets.form.rawMaterial.add")
    : t("technicalSheets.form.rawMaterial.update");
  rawMaterialFormTemplateRef.value = CreateOrUpdateRawMaterialQuantityTemplate(
    handleSubmitRawMaterial,
    isCreating
  );
  if (!isCreating) {
    await nextTick();
    rawMaterialForm.value.setValues(rawMaterials.value[index]);
  }
  modal.value.openModal();
  currentRawMaterialIndex = index;
}

function resetForms() {
  rawMaterials.value = [];
  rawMaterialForm.value?.resetForm();
  modal.value.closeModal();
}

defineExpose({ resetForms });
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
