import {
  isEmailYup,
  isGreaterYup,
  isLessYup,
  isPositiveNumberYup,
  minCharsYup,
  minLowerCaseYup,
  minNumbersYup,
  minSpecialCharsYup,
  minUpperCaseYup,
  numberYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";

type RuleDocumentation = {
  name: string;
  params: string[];
};

export function useYupRules() {
  const rulesDocumentation: { [key in string]: RuleDocumentation[] } = {
    text: [
      { name: "isEmailYup", params: [] },
      {
        name: "minCharsYup",
        params: ["quantity"],
      },
      {
        name: "minSpecialCharsYup",
        params: ["quantity"],
      },
      {
        name: "minNumbersYup",
        params: ["quantity"],
      },
      {
        name: "minUpperCaseYup",
        params: ["quantity"],
      },
      {
        name: "minLowerCaseYup",
        params: ["quantity"],
      },
    ],
    number: [
      { name: "isPositiveNumberYup", params: [] },
      { name: "isGreaterYup", params: ["num"] },
      { name: "isLessYup", params: ["num"] },
    ],
    date: [],
  };
  const rules = {
    isPositiveNumberYup,
    isGreaterYup,
    isLessYup,
    stringYup,
    requiredYup,
    numberYup,
    minCharsYup,
    isEmailYup,
    minSpecialCharsYup,
    minNumbersYup,
    minUpperCaseYup,
    minLowerCaseYup,
  };

  return { ...rules, rulesDocumentation };
}
