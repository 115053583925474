import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-0 dropdownContainer h-100 d-flex align-self-start"
}
const _hoisted_2 = { class: "dropdown-menu" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  class: "dropdown-item",
  href: "#"
}

import { CardOption } from "@/shared/globals/cards/types/CardOption.type";
import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'OptionCard',
  props: {
  options: { type: Array as PropType<CardOption[]>, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (props.options)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("button", {
          class: "btn dropdown-toggle textPale",
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false"
        }, [
          _createElementVNode("i", { class: "bi bi-three-dots" })
        ], -1)),
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (option) => {
            return (_openBlock(), _createElementBlock("li", {
              key: option.name,
              onClick: option.callBack
            }, [
              _createElementVNode("a", _hoisted_4, _toDisplayString(option.name), 1)
            ], 8, _hoisted_3))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})