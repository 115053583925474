<template>
  <div class="row">
    <div class="col-auto pl-0 pr-1 my-auto">
      <img :src="image" alt="logoPic" v-if="image" :width="props.width" />
    </div>
    <div class="col logo p-0 my-auto">
      <h2 class="m-0">{{ logoName }}</h2>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from "vue";
const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  image: {
    type: String,
    required: false,
  },
  width: {
    type: String,
    required: false,
  },
});

//QUICK PALLET

const logoName = computed(() => {
  return props.name || "";
});
</script>

<style lang="scss">
.logo {
  font-size: 24px;
  font-weight: 700;
}
</style>
