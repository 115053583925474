<template>
  <div class="nav-item dropdown" :ref="dropdownName">
    <a href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <profile-picture
        :name="props.userInfo.fullName"
        :image="props.userInfo.image"
        :width="props.userInfo.width"
      ></profile-picture>
    </a>
    <ul
      class="dropdown-menu dropdown-menu-end"
      aria-labelledby="dropdownMenuLink"
    >
      <div class="d-flex mt-3 border-bottom">
        <div class="px-2 fs-5">👋</div>
        <div class="flex-grow-1 text-nowrap">
          <h5 class="name mb-0">{{ props.userInfo.fullName }}</h5>
          <p class="profile mb-2">{{ props.userInfo.profile }}</p>
        </div>
      </div>
      <a
        v-for="(element, index) of props.links"
        :key="element.name + index"
        @click="navigateTo(element)"
        class="m-0 text-start d-block item"
        href="#"
      >
        <i v-if="element.icon" :class="`${element.icon.icon!} pr-1`"></i>
        <span>{{ $t(element.name) }}</span>
      </a>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, PropType, useTemplateRef } from "vue";
import { Dropdown } from "bootstrap";
import { DropDownElementSubmenu } from "@/shared/globals/tooltips/types/DropDownElementsSubmenu";
import ProfilePicture from "../globals/profile/ProfilePicture.vue";

type UserInfo = {
  fullName: string;
  profile: string;
  image?: string;
  width?: string;
};

const props = defineProps({
  userInfo: {
    type: Object as PropType<UserInfo>,
    required: true,
  },
  links: { type: Object as PropType<DropDownElementSubmenu[]>, required: true },
});

const dropdownName = crypto.randomUUID();
const dropDown = useTemplateRef<Element>(dropdownName as never);

function navigateTo(element: DropDownElementSubmenu): void {
  if (element.callBack) {
    element.callBack();
  }
  const dropDownTemp = new Dropdown(dropDown.value);
  dropDownTemp.hide();
}
</script>

<style lang="scss" scoped>
$colorSelected: #6b82ef;

a {
  text-decoration: none;
  color: inherit;
}

.name {
  font-size: 15px;
  font-weight: 600;
}

.profile {
  color: $colorSelected;
  font-size: 14px;
  font-weight: 500;
}

.item {
  padding: 6px 14px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

.item:hover {
  background-color: #ebedf2;
  color: $colorSelected;
}

.dropdown-menu {
  padding: 5px 10px;
  left: auto;
  right: 0;
}

i {
  height: 24px;
  width: 24px;
  -webkit-text-stroke: 0.3px;
  font-size: 1.2rem;
}
</style>
