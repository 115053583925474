<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="customerForm"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomersServices } from "@/composables/useCustomersService";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { Customer } from "@/store/customers/models/Customer.model";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateCustomerTemplate } from "./templates/forms/CreateOrUpdateCustomerTemplate.template";

const { customFields } = useCustomFieldsServices();
const { createCustomer, updateCustomer } = useCustomersServices();

const props = defineProps({
  initialValues: { type: Object as PropType<Customer>, required: false },
});

const initialValuesRef: Ref<Customer> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();

const emit = defineEmits(["handleSubmit"]);

const currentCustomer = computed(() => {
  if (initialValuesRef.value) {
    const customer = formatValues(initialValuesRef.value);
    return customer ?? null;
  }
  return null;
});

function callBackCreate(customer: Customer) {
  emit("handleSubmit", { customer, isCreating: true });
}

function callBackEdit(customer: Customer) {
  emit("handleSubmit", {
    customer,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { customer } = form.value.values;
  if (!initialValuesRef.value) {
    await createCustomer(customer, callBackCreate);
  } else {
    await updateCustomer(customer, callBackEdit);
  }
}

function formatValues(values: Customer) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    name: values.name,
    customerType: values.customerType,
    businessType: values.businessType,
    state: values.state,
    city: values.city,
    customFields: customFieldsFiltered,
    metadata: values.metadata,
  };
}

watch(
  currentCustomer,
  async () => {
    formTemplateRef.value = CreateOrUpdateCustomerTemplate(
      customFields.value,
      handleSubmit,
      !currentCustomer.value
    );
    if (currentCustomer.value) {
      await nextTick();
      form.value?.setValues({ customer: currentCustomer.value });
    }
  },
  { deep: true, immediate: true }
);

defineExpose({
  resetForms: () => form.value?.resetAllFields(),
});
</script>

<style lang="scss"></style>
