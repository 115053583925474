import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { CustomFieldInterface } from "../interfaces/CustomField.interface";

// Limpiar los custom fields ya sea de los custom fields borrados u options de un custom fields borrados
export function filterCustomField(
  oldCustomFields: CustomFieldValues | null,
  customFields: CustomFieldInterface[]
): CustomFieldValues {
  let customFieldsFiltered: CustomFieldValues = {};
  if (oldCustomFields) {
    customFieldsFiltered = Object.fromEntries(
      Object.entries(oldCustomFields)
        .filter(([id]) => {
          return (
            customFields.findIndex((customField) => customField.id === id) > -1
          );
        })
        .map((customField) => {
          const [id, values] = customField;
          // Es una opcion del multi-select
          if (Array.isArray(values)) {
            const newValues = (
              values as unknown as { id: any; label: any }[]
            ).filter(({ id: id2, label }) => {
              const customField = customFields.find(
                (customField) => customField.id === id
              );
              return (
                Object.keys(customField.metadata.options).findIndex((key) => {
                  return key === id2;
                }) > -1
              );
            });
            return [id, newValues];
          }
          return [id, values];
        })
    );
  }
  return customFieldsFiltered;
}
