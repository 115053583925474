import { toast } from "vue3-toastify";

export function useToast(position = toast.POSITION.TOP_RIGHT) {
  const errorToast = (message: string) => {
    toast.error(message, {
      transition: toast.TRANSITIONS.SLIDE,
      position: position,
      theme: "colored",
    });
  };

  const successToast = (message: string) => {
    toast.success(message, {
      transition: toast.TRANSITIONS.SLIDE,
      position: position,
      theme: "colored",
    });
  };

  const infoToast = (message: string) => {
    toast.info(message, {
      transition: toast.TRANSITIONS.SLIDE,
      position: position,
      theme: "colored",
    });
  };

  return { errorToast, successToast, infoToast };
}
