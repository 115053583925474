import axiosInstance from "@/middlewares/Routing.middleware";
import { WorkplacesFlow } from "../models/WorkplacesFlow.model";

export async function CreateWorkplacesFlow(
  payload: WorkplacesFlow
): Promise<WorkplacesFlow> {
  return (await axiosInstance.post(`workplacesFlows`, payload)).data;
}

export async function UpdateWorkplacesFlow(
  payload: WorkplacesFlow
): Promise<WorkplacesFlow> {
  const { id, ...workplacesFlowProps } = payload;
  return (await axiosInstance.put(`workplacesFlows/${id}`, workplacesFlowProps))
    .data;
}
