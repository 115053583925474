import { Permission } from "@/store/permissions/models/Permission.model";
import { GetPermissions } from "@/store/permissions/services/Permission.service";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export function usePermissionsServices() {
  const store = useStore();
  const { t } = useI18n();

  const permissions = computed(() => {
    return store.state.permissions.permissions;
  });

  async function getPermissions() {
    if (permissions.value.length === 0) {
      await GetPermissions()
        .then((permissions: Permission[]) => {
          store.commit("permissions/setPermissions", permissions);
        })
        .catch(({ response }) => {
          return;
        });
    }
  }

  return { permissions, getPermissions };
}
