import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subSeconds,
  subWeeks,
  subYears,
} from "date-fns";
import { AddOrSubtractOperatorMap } from "../types/GeneralTypes.type";

export const ADD_OPERATORS: AddOrSubtractOperatorMap = {
  year: addYears,
  month: addMonths,
  week: addWeeks,
  day: addDays,
  hour: addHours,
  minute: addMinutes,
  second: addSeconds,
};

export const SUB_OPERATORS: AddOrSubtractOperatorMap = {
  year: subYears,
  month: subMonths,
  week: subWeeks,
  day: subDays,
  hour: subHours,
  minute: subMinutes,
  second: subSeconds,
};
