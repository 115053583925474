import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid mx-3 mt-2" }
const _hoisted_2 = { class: "row mt-3" }
const _hoisted_3 = { class: "col text-start" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "row mt-3" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col" }

import TransactionItem from "@/components/dashboard/TransactionItem.vue";
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionsCard',
  setup(__props) {

const transactions = [
  { name: "Nuevo cliente: [CLIENTE 01]", date: new Date() },
  { name: "Mail sent to HR and Admin", date: new Date() },
  { name: "Server Logs Updated", date: new Date() },
  { name: "Task Completed : [Backup Files EOD]", date: new Date() },
  { name: "Documents Submitted from Sara", date: new Date() },
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GeneralCard, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.$t("dashboard.lastTransactions")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(transactions, (transaction) => {
              return _createElementVNode("div", {
                key: transaction.name,
                class: "row mt-3"
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(TransactionItem, {
                    name: transaction.name,
                    date: new Date()
                  }, null, 8, ["name", "date"])
                ])
              ])
            }), 64))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})