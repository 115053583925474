<template>
  <InputMaster
    v-if="columnHeader.filterType"
    @confirmValue="emitValue"
    v-bind="inputData"
    :reachableValue="true"
    :ref="inputData.inputName"
  />
</template>

<script lang="ts" setup>
import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { generateFilterField } from "@/shared/globals/tables/helpers/tables.helper";
import { FieldContext } from "vee-validate";
import {
  PropType,
  computed,
  defineEmits,
  defineProps,
  onMounted,
  useTemplateRef,
} from "vue";
import { TableHeader } from "../../../shared/globals/tables/interfaces/TableHeader.interface";

const props = defineProps({
  columnHeader: { type: Object as PropType<TableHeader>, required: true },
});

const emit = defineEmits(["confirmValue"]);

const inputData = computed(() => {
  return {
    ...generateFilterField(props.columnHeader),
    disabledDefaultOption: false,
  };
});

const inputRef = useTemplateRef<FieldContext>(inputData.value.inputName);

function emitValue(value) {
  let newValue = value;
  if (["select", "multiple-select"].includes(props.columnHeader.filterType)) {
    newValue = value.id;
  }
  emit("confirmValue", {
    value: newValue,
    path: props.columnHeader.isCustomField
      ? `customFields.${props.columnHeader.mappedKey}`
      : props.columnHeader.key,
  });
}

onMounted(() => {
  inputRef.value?.setValue("");
});
</script>
