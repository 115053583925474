import { WorkplacesFlow } from "@/store/workplaces-flow/models/WorkplacesFlow.model";
import {
  CreateWorkplacesFlow,
  UpdateWorkplacesFlow,
} from "@/store/workplaces-flow/services/WorkplacesFlow.service";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastService";

export function useWorkplacesFlowsServices() {
  const store = useStore();
  const { t } = useI18n();

  const createWorkplacesFlow = async (
    payload: WorkplacesFlow,
    callBackCreate: (workplacesFlow: WorkplacesFlow) => void
  ): Promise<void> => {
    await CreateWorkplacesFlow(payload)
      .then((workplacesFlow: WorkplacesFlow) => {
        store.commit("workplacesFlows/addWorkplacesFlow", workplacesFlow);
        callBackCreate(workplacesFlow);
        useToast().successToast(t("workplacesFlows.sucess.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  const updateWorkplacesFlow = async (
    payload: WorkplacesFlow,
    callBackUpdate: (workplacesFlow: WorkplacesFlow) => void
  ): Promise<void> => {
    await UpdateWorkplacesFlow(payload)
      .then((workplacesFlow: WorkplacesFlow) => {
        store.commit("workplacesFlows/updateWorkplacesFlow", payload);
        callBackUpdate(workplacesFlow);
        useToast().successToast(t("workplacesFlows.sucess.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.unknownError"));
      });
  };

  return {
    createWorkplacesFlow,
    updateWorkplacesFlow,
  };
}
