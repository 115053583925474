import {
  startOfDay,
  startOfHour,
  startOfISOWeek,
  startOfMinute,
  startOfMonth,
  startOfSecond,
  startOfYear,
} from "date-fns";
import { DateUnit } from "../types/DateUnits.type";

type StartOfOperatorMap = {
  [key in DateUnit]: (date: Date | number) => Date;
};

export const START_OF_OPERATORS: StartOfOperatorMap = {
  year: startOfYear,
  month: startOfMonth,
  week: startOfISOWeek,
  day: startOfDay,
  hour: startOfHour,
  minute: startOfMinute,
  second: startOfSecond,
};
