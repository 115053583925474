import { DeletionProductFamilyInterface } from "@/components/product-families/interfaces/deletion-product-family.interface";
import axiosInstance from "@/middlewares/Routing.middleware";
import { ProductFamily } from "../models/ProductFamily.model";

export async function GetAllProductFamilies(): Promise<ProductFamily[]> {
  return (await axiosInstance.get(`product-families/all`)).data;
}

export async function CreateProductFamily(
  payload: ProductFamily
): Promise<ProductFamily> {
  return (await axiosInstance.post(`product-families`, payload)).data;
}

export async function UpdateProductFamily(
  payload: ProductFamily
): Promise<ProductFamily> {
  const { id, ...productFamilyProps } = payload;
  return (await axiosInstance.put(`product-families/${id}`, productFamilyProps))
    .data;
}

export async function DeleteProductFamily(
  payload: DeletionProductFamilyInterface
): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`product-families?newId=${newId}&oldId=${oldId}`);
}
