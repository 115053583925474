<template>
  <AuthCard>
    <template v-slot:content>
      <div class="row justify-content-center">
        <div class="col-12 d-flex align-items-center">
          <h2 class="textBlack">
            {{ $t("auth.carouselTabs.forgotPassword.primaryTitle") }}
          </h2>
        </div>
      </div>
      <div class="row justify-content-between mt-2 mb-1">
        <div class="col-auto">
          <i class="bi bi-arrow-left-circle actionableLabel mr-1"></i>
          <span class="actionableLabel" @click="goToLogin">{{
            $t("auth.carouselTabs.forgotPassword.hrefs.signUp")
          }}</span>
        </div>
        <div class="col-auto">
          <span
            v-if="isFirstStep"
            class="actionableLabel"
            @click="isFirstStep = false"
            >{{
              $t("auth.carouselTabs.forgotPassword.hrefs.haveACodeYet")
            }}</span
          >
          <span v-else class="actionableLabel" @click="isFirstStep = true">
            {{ $t("auth.carouselTabs.forgotPassword.hrefs.notHaveACodeYet") }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col" v-if="isFirstStep">
          <GeneralForm
            ref="formRef"
            :form="formForgotPasswordStep1"
            formName="forgotPassword"
          ></GeneralForm>
        </div>
        <div class="col" v-else>
          <GeneralForm
            ref="formRef"
            :form="formForgotPasswordStep2"
            formName="forgotPassword"
          ></GeneralForm>
        </div>
      </div>
    </template>
  </AuthCard>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { useValidations } from "@/composables/useValidations";
import { defineEmits, ref } from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import AuthCard from "./AuthCard.vue";
import { forgotPasswordStep1 } from "./templates/forms/ForgotPasswordStep1.template";
import { forgotPasswordStep2 } from "./templates/forms/ForgotPasswordStep2.template";

const { forgotPassword, confirmForgotPassword } = useAuthServices();
const validations = useValidations();

const emit = defineEmits(["goToSlide"]);

const isFirstStep = ref<boolean>(true);
const formRef = ref();
const formForgotPasswordStep1 = ref(
  forgotPasswordStep1(submitForgotPassword, validations)
);
const formForgotPasswordStep2 = ref(
  forgotPasswordStep2(submitForgotPassword, validations)
);

function goToLogin(): void {
  isFirstStep.value = true;
  formRef.value.resetForm({});
  emit("goToSlide", 1);
}

async function submitForgotPassword(): Promise<void> {
  const { values } = formRef.value;
  const { email, newPassword, confirmationCode } = values;
  if (isFirstStep.value) {
    isFirstStep.value = !(await forgotPassword({ email }));
  } else {
    await confirmForgotPassword(
      { email, newPassword, confirmationCode },
      goToLogin
    );
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#btnForgotPassword {
  background-color: $ThirdColor;
  border-color: $ThirdColor;
  color: white;
  &:hover {
    color: $ThirdColor;
    background-color: white;
  }
}
</style>
