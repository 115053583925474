<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("customers.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => openOffcanvasForm('create')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("customers.form.add") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn ml-1 btn-gear"
                @click="() => openOffcanvasForm('customField')"
              >
                <i class="bi bi-gear"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="
      formName === 'customField'
        ? $t('customFields.title')
        : formName === 'create'
        ? $t('customers.form.createTitle')
        : $t('customers.form.editTitle')
    "
    @closed="customFieldsClosed"
    ><template v-slot:content>
      <CustomFields
        v-if="formName === 'customField'"
        ref="form"
        @custom-field-submitted="() => offCanvasRef.closeOffCanvas()"
      ></CustomFields>
      <CreateOrUpdateCustomer
        v-if="formName === 'create' || formName === 'edit'"
        ref="form"
        :initial-values="tempRow"
        @handle-submit="handleSubmit"
      ></CreateOrUpdateCustomer>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal
    :title="confirmModalTitleTranslated"
    ref="confirmationModalRef"
    :message="confirmationQuestion"
    :confirmAction="handleConfirm"
  ></ConfirmModal>
  <GeneralTable
    v-if="headerTable"
    dataSource="/customers/"
    :filters="tableFilters"
    :headers="headerTable"
    name="customersTable"
    ref="tableRef"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import CreateOrUpdateCustomer from "@/components/customers/CreateOrUpdateCustomer.vue";
import { CustomersHeader } from "@/components/customers/templates/headers/CustomersHeader.headers";
import CustomFields from "@/components/customFields/CustomFields.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import { useCustomersServices } from "@/composables/useCustomersService";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { Customer } from "@/store/customers/models/Customer.model";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

type formsName = "create" | "edit" | "customField";

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { deleteCustomer } = useCustomersServices();
const { t } = useI18n();

const form = ref();
const tableRef = ref();
const offCanvasRef = ref();
const formName: Ref<formsName> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "delete"> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<Customer | null> = ref();

const confirmModalTitleTranslated = computed(() => {
  if (action.value === "delete") {
    return t("global.warnings.delete");
  }
  return "";
});

const question = computed(() => {
  if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

const confirmationQuestion = computed(() => {
  return {
    value: `users.${question.value}`,
    params: {
      name: tempRow.value?.name || "",
    },
    needsTranslate: true,
  };
});

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

function handleSubmit(data) {
  const { customer, isCreating } = data;
  if (isCreating) {
    tableRef.value.currentData = [customer, ...tableRef.value.currentData];
  } else {
    tableRef.value.updateRow(lastRowIndex.value, customer);
  }
  offCanvasRef.value.closeOffCanvas();
}

function runAction(
  actionType: "edit" | "delete",
  row: Customer,
  index: number
) {
  if (actionType === "edit") {
    openOffcanvasForm("edit");
  } else if (actionType === "delete") {
    confirmationModalRef.value?.openConfirmationModal();
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
}

async function handleConfirm() {
  if (action.value === "delete") {
    await deleteCustomer(tempRow.value, () => {
      tableRef.value.deleteRow(lastRowIndex.value);
    });
  }
}

function openOffcanvasForm(newFormName: formsName) {
  tempRow.value = null;
  formName.value = newFormName;
  offCanvasRef.value.openOffCanvas();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [customFields],
    () => {
      headerTable.value = CustomersHeader(runAction, customFields.value);
    },
    { immediate: true, deep: true }
  );
});
</script>

<style scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}
</style>
