import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "containter-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "text-start fs-4 fw-normal my-0" }
const _hoisted_5 = { class: "row mt-3 g-3" }
const _hoisted_6 = { class: "col-lg-3" }
const _hoisted_7 = { class: "col-lg-5" }
const _hoisted_8 = { class: "col-lg-4" }
const _hoisted_9 = { class: "row mt-3 g-3" }
const _hoisted_10 = { class: "col-lg-9 pe-2" }
const _hoisted_11 = { class: "col-lg-3" }

import BalanceCard from "@/components/dashboard/BalanceCard.vue";
import SalesDetailsCard from "@/components/dashboard/SalesDetailsCard.vue";
import SoldsCard from "@/components/dashboard/SoldsCard.vue";
import TransactionsCard from "@/components/dashboard/TransactionsCard.vue";
import CardLabel from "@/components/globals/cards/CardLabel.vue";
import { CardValue } from "@/shared/globals/cards/types/CardValue.type";

//const { user } = useUsersServices();

const totalBalance = "4001741.42";
const growth = "+13.6";

export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardView',
  setup(__props) {

const statisticsValues: CardValue[] = [
  {
    topLabel: "dashboard.customers",
    icon: { icon: "bi bi-bar-chart-line" },
    value: 423,
    type: "number",
    primaryColor: "#c99c60",
    secondaryColor: "#fbf2e4",
  },
  {
    topLabel: "dashboard.sales",
    icon: { icon: "bi bi-currency-dollar" },
    value: 700929,
    type: "number",
    primaryColor: "#159a53",
    secondaryColor: "#d9f2ec",
  },
];


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("dashboard.title")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(CardLabel, {
          title: "dashboard.statistics",
          name: "userLabelCard",
          values: statisticsValues
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(SalesDetailsCard)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(BalanceCard, {
          totalBalance: totalBalance,
          growth: growth
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(SoldsCard)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(TransactionsCard)
      ])
    ])
  ]))
}
}

})