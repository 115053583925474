import { useLanguageServices } from "@/composables/useLanguageServices";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { CustomFieldInterface } from "../interfaces/CustomField.interface";

function getTypeFilter(type: string) {
  if (type === "date") {
    return "date";
  } else if (["select", "multiple-select"].includes(type)) {
    return "select";
  }
  return "text";
}

export function customFieldToHeader(
  customFields: CustomFieldInterface[]
): TableHeader[] {
  const { lang } = useLanguageServices();
  return customFields.map((customField: CustomFieldInterface) => {
    const values = {
      sortable: false,
      value: {
        value:
          customField?.metadata?.language?.name?.[lang.value] ??
          customField.name,
        needsTranslate: false,
      },
      key: customField.id,
      mappedKey: customField.id,
      columnType: customField.type,
      width: "100px",
      ...(customField?.metadata?.options
        ? {
            selectOptions: {
              idKey: "id",
              labelKey: "label",
              isMultiple: false,
              initialOptions: Object.keys(customField.metadata.options).map(
                (optionKey) => {
                  return {
                    id: optionKey,
                    label:
                      customField?.metadata?.language?.options?.[lang.value]?.[
                        optionKey
                      ] ?? customField.metadata.options[optionKey],
                  };
                }
              ),
            },
          }
        : {}),
      isCustomField: true,
      filterType: getTypeFilter(customField.type),
    };
    return values as unknown as TableHeader;
  });
}
