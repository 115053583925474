<template>
  <div class="cardContainer borderRadiusDefault p-0 py-2 px-3 h-100">
    <slot name="content"></slot>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped lang="scss">
.cardContainer {
  background-color: white;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.darkMode {
  .cardContainer {
    background-color: $GrayLightDark;
  }
}
</style>
