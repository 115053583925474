<template>
  <div class="accordion" id="accordionWorkplacesFlows">
    <div class="accordion-item">
      <div
        class="accordion-header createWorkplacesFlow"
        data-bs-toggle="collapse"
        data-bs-target="#createWorkplacesFlowsAcordion"
        aria-expanded="true"
        aria-controls="createWorkplacesFlowsAcordion"
      >
        <span class="w-100 d-flex justify-content-center">
          {{ $t("workplacesFlows.form.add") }}
        </span>
      </div>
      <div
        id="createWorkplacesFlowsAcordion"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionWorkplacesFlows"
        ref="createAcordion"
      >
        <div class="accordion-body">
          <CreateOrUpdateWorkplacesFlow
            ref="createForm"
            :isCreating="true"
            @custom-field-submitted="handleWorkplacesFlowSubmit"
          ></CreateOrUpdateWorkplacesFlow>
        </div>
      </div>
    </div>
    <div
      class="accordion-item pl-2"
      v-for="(workplacesFlow, index) in workplacesFlows"
      :key="workplacesFlow.id"
      ref="acordions"
    >
      <div
        class="accordion-header"
        :id="`showWorkplacesFlowsAcordion${workplacesFlow.id}`"
      >
        <div class="m-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div>
              <span>
                {{ workplacesFlow.workplace.label }}
              </span>
            </div>
            <div>
              <ButtonField
                :button-field="
                  editButton(() => {
                    editCounter++;
                    return;
                  })
                "
                data-bs-toggle="collapse"
                :data-bs-target="`#showWorkplacesFlows${workplacesFlow.id}`"
                aria-expanded="true"
                :aria-controls="`showWorkplacesFlows${workplacesFlow.id}`"
              ></ButtonField>
              <ButtonField
                :button-field="
                  deleteButton(() => openDeleteWorkplacesFlowModal(index))
                "
              ></ButtonField>
            </div>
          </div>
        </div>
      </div>
      <div
        :id="`showWorkplacesFlows${workplacesFlow.id}`"
        class="accordion-collapse collapse"
        ref="editCollapses"
        data-bs-parent="#accordionWorkplacesFlows"
      >
        <div class="accordion-body pt-0">
          <CreateOrUpdateWorkplacesFlow
            :initial-values="workplacesFlow"
            :isCreating="false"
            :key="editCounter"
            @custom-field-submitted="handleWorkplacesFlowSubmit"
          ></CreateOrUpdateWorkplacesFlow>
        </div>
      </div>
      <div>
        <ConfirmModal
          :title="$t('global.warnings.delete')"
          ref="modals"
          :message="messageDeletion(workplacesFlow)"
          :confirmAction="
            async () => {
              await handleDeleteWorkplacesFlow(workplacesFlow.id);
            }
          "
        ></ConfirmModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { WorkplacesFlow } from "@/store/workplaces-flow/models/WorkplacesFlow.model";
import { defineEmits, defineExpose, Ref, ref } from "vue";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import ConfirmModal from "../globals/modals/ConfirmModal.vue";
import CreateOrUpdateWorkplacesFlow from "./CreateOrUpdateWorkplacesFlow.vue";

const emit = defineEmits(["workplacesFlowSubmitted"]);

const createForm = ref();
const editCollapses = ref();
const modals = ref([]);
const isDeleting = ref(false);
const editCounter = ref(0);
const createAcordion = ref();
const workplacesFlows: Ref<WorkplacesFlow[]> = ref([]);
const messageDeletion = (workplacesFlow): LabelType => {
  return {
    value: "workplacesFlows.questionDelete",
    params: {
      name: workplacesFlow.name,
    },
    needsTranslate: true,
  };
};

function resetForms() {
  createForm.value.resetForm();
}

function openDeleteWorkplacesFlowModal(modalIndex) {
  modals.value[modalIndex].openConfirmationModal();
}

async function handleDeleteWorkplacesFlow(workplacesFlowId) {
  isDeleting.value = true;
  isDeleting.value = false;
}

function resetView() {
  resetForms();
}

function handleWorkplacesFlowSubmit() {
  resetView();
  emit("workplacesFlowSubmitted");
}

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}

.createWorkplacesFlow {
  background-color: #7451c2;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}

.accordion-item:has(.collapse.show) {
  span {
    display: none;
  }
}
</style>
