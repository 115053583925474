import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { Measure } from "@/store/measures/models/Measure.model";

export const DeleteMeasureTemplate = (
  action: () => void,
  currentMeasure: number
): Form => {
  const { lang } = useLanguageServices();
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "newId",
            inputName: "newId",
            label: {
              value: "measures.form.selectNewMeasure",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "left",
            },
            selectOptions: {
              dataSource: "/measures/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      text: {
        value: "measures.form.deleteAndReplace",
        needsTranslate: true,
      },
      action,
    },
  };
  return form;
};
