import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "row g-2" }
const _hoisted_8 = { class: "col-12 d-flex justify-content-start" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "row g-2" }
const _hoisted_11 = { class: "col-12 d-flex justify-content-end" }

import CreateOrUpdateCustomer from "@/components/customers/CreateOrUpdateCustomer.vue";
import { CustomersHeader } from "@/components/customers/templates/headers/CustomersHeader.headers";
import CustomFields from "@/components/customFields/CustomFields.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import { useCustomersServices } from "@/composables/useCustomersService";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { Customer } from "@/store/customers/models/Customer.model";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

type formsName = "create" | "edit" | "customField";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomersView',
  setup(__props) {

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { deleteCustomer } = useCustomersServices();
const { t } = useI18n();

const form = ref();
const tableRef = ref();
const offCanvasRef = ref();
const formName: Ref<formsName> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "delete"> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<Customer | null> = ref();

const confirmModalTitleTranslated = computed(() => {
  if (action.value === "delete") {
    return t("global.warnings.delete");
  }
  return "";
});

const question = computed(() => {
  if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

const confirmationQuestion = computed(() => {
  return {
    value: `users.${question.value}`,
    params: {
      name: tempRow.value?.name || "",
    },
    needsTranslate: true,
  };
});

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

function handleSubmit(data) {
  const { customer, isCreating } = data;
  if (isCreating) {
    tableRef.value.currentData = [customer, ...tableRef.value.currentData];
  } else {
    tableRef.value.updateRow(lastRowIndex.value, customer);
  }
  offCanvasRef.value.closeOffCanvas();
}

function runAction(
  actionType: "edit" | "delete",
  row: Customer,
  index: number
) {
  if (actionType === "edit") {
    openOffcanvasForm("edit");
  } else if (actionType === "delete") {
    confirmationModalRef.value?.openConfirmationModal();
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
}

async function handleConfirm() {
  if (action.value === "delete") {
    await deleteCustomer(tempRow.value, () => {
      tableRef.value.deleteRow(lastRowIndex.value);
    });
  }
}

function openOffcanvasForm(newFormName: formsName) {
  tempRow.value = null;
  formName.value = newFormName;
  offCanvasRef.value.openOffCanvas();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [customFields],
    () => {
      headerTable.value = CustomersHeader(runAction, customFields.value);
    },
    { immediate: true, deep: true }
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("customers.title")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = () => openOffcanvasForm('create'))
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("i", { class: "bi bi-person-plus me-2" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("customers.form.add")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn ml-1 btn-gear",
                  onClick: _cache[1] || (_cache[1] = () => openOffcanvasForm('customField'))
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("i", { class: "bi bi-gear" }, null, -1)
                ]))
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(GeneralOffcanvas, {
      canvasId: "customFieldCanvas",
      ref_key: "offCanvasRef",
      ref: offCanvasRef,
      canvasName: 
      formName.value === 'customField'
        ? _ctx.$t('customFields.title')
        : formName.value === 'create'
        ? _ctx.$t('customers.form.createTitle')
        : _ctx.$t('customers.form.editTitle')
    ,
      onClosed: customFieldsClosed
    }, {
      content: _withCtx(() => [
        (formName.value === 'customField')
          ? (_openBlock(), _createBlock(CustomFields, {
              key: 0,
              ref_key: "form",
              ref: form,
              onCustomFieldSubmitted: _cache[2] || (_cache[2] = () => offCanvasRef.value.closeOffCanvas())
            }, null, 512))
          : _createCommentVNode("", true),
        (formName.value === 'create' || formName.value === 'edit')
          ? (_openBlock(), _createBlock(CreateOrUpdateCustomer, {
              key: 1,
              ref_key: "form",
              ref: form,
              "initial-values": tempRow.value,
              onHandleSubmit: handleSubmit
            }, null, 8, ["initial-values"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["canvasName"]),
    _createVNode(ConfirmModal, {
      title: confirmModalTitleTranslated.value,
      ref_key: "confirmationModalRef",
      ref: confirmationModalRef,
      message: confirmationQuestion.value,
      confirmAction: handleConfirm
    }, null, 8, ["title", "message"]),
    (headerTable.value)
      ? (_openBlock(), _createBlock(GeneralTable, {
          key: 0,
          dataSource: "/customers/",
          filters: tableFilters.value,
          headers: headerTable.value,
          name: "customersTable",
          ref_key: "tableRef",
          ref: tableRef
        }, null, 8, ["filters", "headers"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})