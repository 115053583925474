import {
  addMinutes,
  endOfMonth,
  format,
  isAfter,
  isEqual,
  isValid,
  parse,
  parseISO,
  startOfMonth,
} from "date-fns";

import { formatInTimeZone, getTimezoneOffset, toZonedTime } from "date-fns-tz";
import { ISO_DATE_FORMAT } from "../consts/IsoDateFormat.const";
import { ADD_OPERATORS } from "../maps/AddOrSubstractOperators.map";
import { DIFFERENCE_OPERATORS } from "../maps/DifferenceOperators.map";
import { IS_SAME_OPERATORS } from "../maps/IsSameOperators.map";
import { START_OF_OPERATORS } from "../maps/StartOfOperators.map";
import { DateUnit } from "../types/DateUnits.type";

export type DateInput = Date | string | number;

export class DateHelper {
  private static instance: DateHelper;

  static of(): DateHelper {
    if (!this.instance) {
      this.instance = new DateHelper();
    }
    return this.instance;
  }

  private parseDate(date: DateInput, format?: string): Date {
    if (typeof date === "string" && format)
      return parse(date, format, new Date());
    if (typeof date === "string") return parseISO(date);
    if (typeof date === "number") return new Date(date);
    return date;
  }

  /**
   * Checks if a given date is valid.
   *
   * @param {DateInput} date - The date to check for validity.
   * @param {string} [format] - The format of the date if provided as a string.
   * @returns {boolean} Returns `true` if the date is valid, otherwise `false`.
   *
   * @example
   *
   * const helper = DateHelper.of();
   *
   * const validDate = '2023-08-31';
   * const invalidDate = 'invalid-date';
   *
   * const isValid1 = helper.isValid(validDate, 'yyyy-MM-dd');
   * // isValid1 = true (validDate is a valid date)
   *
   * const isValid2 = helper.isValid(invalidDate);
   * // isValid2 = false (invalidDate is not a valid date)
   */
  isValid(date: DateInput, format?: string): boolean {
    return isValid(this.parseDate(date, format));
  }

  /**
   * Converts a string date representation to a Date object.
   *
   * @param {string} date - The string date to be converted.
   * @param {string} [format] - Optional date format.
   * @returns {Date} The parsed Date object.
   *
   * @example
   * const dateString = '2023-08-31';
   * const parsedDate = stringToDate(dateString);
   * // parsedDate is a Date object representing '2023-08-31'.
   */
  stringToDate(date: string, format?: string): Date {
    return parse(date, format, new Date());
  }

  /**
   * Returns the first and last dates of a given month.
   *
   * @param {string} monthInYear - The month and year in 'MM-yyyy' format.
   * @returns {{ first: Date, last: Date }} Object containing the first and last dates.
   *
   * @example
   * const month = '08-2023';
   * const { first, last } = firstAndLastInMonth(month);
   * // first is the start of August 2023, last is the end of August 2023.
   */
  firstAndLastInMonth(monthInYear: string): { first: Date; last: Date } {
    const date = parse(monthInYear, "MM-yyyy", new Date());
    return {
      first: startOfMonth(date),
      last: endOfMonth(date),
    };
  }

  /**
   * Formats a given date according to the specified format.
   *
   * @param {DateInput} date - The date to be formatted.
   * @param {string} stringFormat - The desired output format.
   * @param {string} [dateInputFormat] - Optional input date format if `date` is a string.
   * @returns {string} The formatted date string.
   *
   * @example
   * const currentDate = new Date();
   * const formattedDate = format(currentDate, 'yyyy-MM-dd HH:mm:ss');
   * // formattedDate is a string representing the current date in the specified format.
   */
  format(
    date: DateInput,
    stringFormat: string,
    dateInputFormat?: string
  ): string {
    return format(this.parseDate(date, dateInputFormat), stringFormat);
  }

  /**
   * Formats a given date according to the specified format and timezone.
   *
   * @param {DateInput} date - The date to be formatted.
   * @param {string} stringFormat - The desired output format.
   * @param {string} timezone - The timezone to apply to the date.
   * @returns {string} The formatted date string in the specified timezone.
   *
   * @example
   * const currentDate = new Date();
   * const formattedDate = formatTz(currentDate, 'yyyy-MM-dd HH:mm:ss', 'America/New_York');
   * // formattedDate is a string representing the current date in the specified format and timezone.
   */
  formatTz(date: DateInput, stringFormat: string, timezone: string): string {
    return formatInTimeZone(date, timezone, stringFormat);
  }

  /**
   * Converts a date from one timezone to UTC.
   *
   * @param {string} date - The date string to be converted.
   * @param {string} fromTimezone - The source timezone of the date.
   * @param {string} [format] - Optional date format.
   * @returns {Date} The converted date in UTC.
   *
   * @example
   * const dateInTimezone = '2023-08-31 12:00:00';
   * const utcDate = convertToUTC(dateInTimezone, 'America/New_York');
   * // utcDate is a Date object representing the equivalent UTC time.
   */
  convertToUTC(
    date: string,
    fromTimezone: string,
    format: string = ISO_DATE_FORMAT
  ): Date {
    const parsedDate = parse(date, format, new Date());
    const offset = getTimezoneOffset(fromTimezone, parsedDate) / 60000;
    return addMinutes(parsedDate, offset * -1);
  }

  /**
   * Converts a date to a specific timezone.
   *
   * @param {Date} date - The date to be converted.
   * @param {string} timezone - The target timezone.
   * @returns {Date} The date converted to the specified timezone.
   *
   * @example
   * const currentDate = new Date();
   * const zonedDate = toZonedTime(currentDate, 'America/Los_Angeles');
   * // zonedDate is a Date object representing the equivalent time in the specified timezone.
   */
  toZonedTime(date: Date, timezone: string): Date {
    return toZonedTime(date, timezone);
  }

  /**
   * Adds a specified amount to a date.
   *
   * @param {DateInput} date - The date to which the amount will be added.
   * @param {number} amount - The amount to add.
   * @param {DateUnit} unit - The unit of time to add (e.g., 'year', 'month').
   * @returns {Date} The resulting date after addition.
   *
   * @example
   * const currentDate = new Date();
   * const oneYearLater = add(currentDate, 1, 'year');
   * // oneYearLater is a Date object representing the current date plus one year.
   */
  add(date: DateInput, amount: number, unit: DateUnit): Date {
    const parsedDate = this.parseDate(date);

    const operator = ADD_OPERATORS[unit];
    if (!operator) {
      throw new Error("Unsupported unit of time for starting");
    }

    return operator(parsedDate, amount);
  }

  /**
   * Checks if two dates are the same with respect to a specific unit of time.
   *
   * @param {DateInput} from - The first date for comparison.
   * @param {DateInput} to - The second date for comparison.
   * @param {DateUnit} unit - The unit of time for comparison (e.g., 'year', 'month').
   * @returns {boolean} `true` if the dates are the same in the given unit; otherwise, `false`.
   *
   * @example
   * const date1 = new Date('2023-08-31');
   * const date2 = new Date('2023-08-15');
   * const sameMonth = isSame(date1, date2, 'month');
   * // sameMonth is true because both dates are in the same month.
   */
  isSame(from: DateInput, to: DateInput, unit: DateUnit): boolean {
    const fromDate = this.parseDate(from);
    const toDate = this.parseDate(to);

    const operator = IS_SAME_OPERATORS[unit];
    if (!operator) {
      throw new Error("Unsupported unit of time for starting");
    }

    return operator(fromDate, toDate);
  }

  /**
   * Returns the start of a date with respect to a specific unit of time.
   *
   * @param {DateInput} date - The date for which to find the start.
   * @param {DateUnit} unitOfTime - The unit of time for starting (e.g., 'year', 'month').
   * @returns {Date} The date at the start of the specified unit.
   *
   * @example
   * const currentDate = new Date();
   * const startOfDayDate = startOf(currentDate, 'day');
   * // startOfDayDate is a Date object representing the start of the current day.
   */
  startOf(date: DateInput, unitOfTime: DateUnit): Date {
    const parsedDate = this.parseDate(date);

    const operator = START_OF_OPERATORS[unitOfTime];
    if (!operator) {
      throw new Error("Unsupported unit of time for starting");
    }

    return operator(parsedDate);
  }

  /**
   * Calculates the difference between two dates in terms of a specific unit of time.
   *
   * @param {DateInput} date - The target date for comparison.
   * @param {DateUnit} unitOfTime - The unit of time for calculating the difference (e.g., 'year', 'month').
   * @param {DateInput} [currentDate] - The current date for comparison (default is the current date).
   * @returns {number} The difference between the dates in terms of the specified unit.
   *
   * @example
   * const date1 = new Date('2023-06-31');
   * const date2 = new Date('2023-08-15');
   * const differenceInMonths = getDifference(date1, 'month', date2);
   * // differenceInMonths is 2 because there are 2 months between the dates.
   */
  getDifference(
    date: Date,
    unitOfTime: DateUnit,
    currentDate: DateInput = new Date()
  ): number {
    const parsedDate = this.parseDate(date);
    const currentParsedDate = this.parseDate(currentDate);

    const operator = DIFFERENCE_OPERATORS[unitOfTime];
    if (!operator) {
      throw new Error("Unsupported unit of time for starting");
    }

    return operator(currentParsedDate, parsedDate);
  }

  /**
   * Checks if a string follows the ISO date format (yyyy-MM-ddTHH:mm:ss.SSSZ).
   *
   * @param {string} date - The string to be checked.
   * @returns {boolean} `true` if the string follows the ISO date format; otherwise, `false`.
   *
   * @example
   * const isoDateString = '2023-08-31T15:30:00.000Z';
   * const isISO = isISODate(isoDateString);
   * // isISO is true because the string follows the ISO date format.
   */
  isISODate(date: string): boolean {
    if (typeof date !== "string") return false;
    return (
      date.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3,}Z/g)?.length > 0
    );
  }

  /**
   * Checks if a string represents a valid time in 'HH:mm' format.
   *
   * @param {string} time - The string to be checked.
   * @returns {boolean} `true` if the string represents a valid time; otherwise, `false`.
   *
   * @example
   * const validTime = '12:30';
   * const isValidTime = isTime(validTime);
   * // isValidTime is true because the string represents a valid time.
   */
  isTime(time: string): boolean {
    if (typeof time !== "string") return false;
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return regex.test(time);
  }

  /**
   * Calculates the age in years based on a birth year.
   *
   * @param {number} year - The year to calculate the age from.
   * @returns {number | null} The calculated age, or `null` if birth year is missing or invalid.
   *
   * @example
   * const birthYear = 1990;
   * const age = getYearsOld(birthYear);
   * // age is the calculated age based on the birth year.
   */
  getYearsOld(year: number): number | null {
    const currentYear = new Date().getFullYear();

    if (!year) return null;
    if (isNaN(Number(year))) return 0;

    return currentYear - Number(year);
  }

  /**
   * Checks if the initial date is the same as or after the target date.
   *
   * @param {DateInput} initial - The initial date to compare.
   * @param {DateInput} target - The target date to compare.
   * @returns {boolean} Returns `true` if the initial date is the same as or after the target date, otherwise `false`.
   *
   * @example
   *
   * const helper = DateHelper.of();
   *
   * const initialDate = '2023-08-31';
   * const targetDate = '2023-08-30';
   *
   * const result = helper.isSameOrAfter(initialDate, targetDate);
   * // result = true (initialDate is after or the same as targetDate)
   */
  isSameOrAfter(initial: DateInput, target: DateInput): boolean {
    const initialDate = this.parseDate(initial);
    const targetDate = this.parseDate(target);
    return isEqual(initialDate, targetDate) || isAfter(initialDate, targetDate);
  }

  /**
   * Formats multiple date or datetime fields in a given object using a specified format and timezone.
   *
   * @param {Record<string, Date | string>} fields - An object containing date or datetime fields to be formatted.
   * @param {string} format - The desired output format for the formatted dates.
   * @param {string} timezone - The timezone to apply to the dates.
   * @returns {Record<string, string>} An object with the same keys as the input object, where each value is the formatted date string.
   *
   * @example
   * const dateFields = {
   *   startDate: new Date("2023-08-31T12:00:00Z"),
   *   endDate: '2023-09-15T14:30:00Z',
   * };
   *
   * const helper = DateHelper.of();
   * const formattedDates = helper.formatDatesInObject(dateFields, 'yyyy-MM-dd HH:mm:ss', 'America/New_York');
   *
   * // formattedDates is an object with formatted date strings:
   * // {
   * //   startDate: '2023-08-31 08:00:00',
   * //   endDate: '2023-09-15 10:30:00'
   * // }
   */
  formatDatesInObject(
    fields: Record<string, Date | string>,
    format: string,
    timezone: string
  ): Record<string, string> {
    const formattedFields: Record<string, string> = {};
    Object.entries(fields)
      .filter(([, fieldValue]) => this.isValid(fieldValue))
      .forEach(([fieldName, fieldValue]) => {
        formattedFields[fieldName] =
          fieldValue && DateHelper.of().formatTz(fieldValue, format, timezone);
      });

    return formattedFields;
  }

  // /**
  //  * Transforms the dates.
  //  *
  //  * @param {string} dateValue - any date that is in the range of dates established to be able to transform.
  //  * @param {string} desiredDateFormat - Client date settings. 'yyyy/MM/dd hh:mm:ss a'
  //  * @returns {string | null} The calculated age, or `null` if birth year is missing or invalid.
  //  *
  //  * @example
  //  * dataValue = 'Format 1: "01/09/2023 06:12:59 AM"
  //  *   Format 2: "2023/01/09 06:12:59 AM"
  //  *   Format 3: "01-09-2023 06:12:59 AM"
  //  *   Format 4: "2023-01-09 06:12:59 AM"
  //  *   Format 5: "2023.01.09 06:12:59 AM"
  //  *   Format 6: "2023.01.09"
  //  *   Format 7: "2023/01/09"
  //  *   Format 8: "2023-01-09"
  //  *   Format 9: "09-01-2023"
  //  *   Format 10: "09/01/2023"
  //  *   Format 11: "09.01.2023";
  //  *
  //  * const desiredDateFormat = 'yyyy/MM/dd hh:mm:ss a';
  //  * Transforms the dates to the date configured by the client.
  //  */

  // convertToAcceptedFormats(
  //   dateValue: string,
  //   desiredDateFormat: string
  // ): string | null {
  //   for (const formatToCheck of ACCEPTED_FORMATS) {
  //     const parsedDate = parse(dateValue, formatToCheck, new Date(), {
  //       useAdditionalDayOfYearTokens: true,
  //     });
  //     if (isValid(parsedDate)) {
  //       return format(parsedDate, desiredDateFormat);
  //     }
  //   }
  //   return null;
  // }
}
