import { createI18n } from "vue-i18n";
import { en } from "./messages/en";
import { es } from "./messages/es";

export default createI18n({
  locales: ["en", "es"],
  fallbackLocale: "es",
  messages: {
    en,
    es,
  },
  legacy: false,
});
